
































































































import 'vue-upload-component/dist/vue-upload-component.part.css';

import {
  computed,
  ComputedRef,
  defineComponent,
  onMounted,
  PropType,
  Ref,
  ref, watch,
  WritableComputedRef
} from "@vue/composition-api";
import { ValidationProvider } from "vee-validate";
import Helpers from "@/utils/helpers";
import VUC from 'vue-upload-component/index';
// @ts-ignore
import VueUploadComponent from 'vue-upload-component/dist/vue-upload-component.part.js';
import {FileFullInfo, FileMetatype, FileType} from "@/@models";
import fileSharedApi from "@/api/shared/file-shared-api";
import useNotifications from "@/components/useNotifications";
import useControlUtils from "@/utils/useControlUtils";
import FileUtils from "@/utils/file-utils";
import FilePreviewsView from "@/views/_components/FilePreviewsView.vue";

interface ImagePreviewStyles {
  maxWidth: string
  maxHeight: string
}

export default defineComponent({
  inheritAttrs: false,
  components: {
    FilePreviewsView,
    ValidationProvider,
    VueUploadComponent,
  },
  props: {
    if: { type: Boolean, default: true },
    inputId: String,
    inputClass: String,
    disabled: Boolean,
    uploadButtonHidden: Boolean,
    uploadButtonName: String,
    uploadButtonVariant: { type: String, default: 'primary' },
    displayName: { type: String, required: true },
    rules: [String, Object],
    withoutLabel: { type: Boolean, default: false },
    value: [String, Array],
    description: String,
    maxFileSizeBytes: {
      type: Number,
      default: 100000000, // ~100 mb
    },
    type: Number as PropType<FileType>,
    metatype: Number as PropType<FileMetatype>,
    removeEnabled: Boolean,
    contextualId: String,
    multiple: Boolean,
    previewType: { type: String as PropType<"image" | "audio" | "file" | null>, default: "file" },
    imagePreviewOptions: Object as PropType<ImagePreviewStyles>,
    idWithNameFormat: Boolean,
  },
  emits: ['file-uploaded', 'file-info-changed'],
  setup(props, context) {
    const { getDefaultFormGroupClass } = useControlUtils()
    const {showSuccessNotification, showInfoNotification, showWarningNotification, showErrorNotification} = useNotifications(context);

    // validation
    if (!Helpers.isNotEmpty(props.inputId)) {
      console.error(`inputId is empty or undefined - DisplayName: ${props.displayName}`);
    }
    if (!Helpers.isNumeric(props.type) && !Helpers.isNumeric(props.metatype)) {
      console.error(`type or metatype is required - DisplayName: ${props.displayName}`);
    }
    if (Helpers.isNotEmpty(props.rules)) {
      if (props.rules !== 'required') {
        console.error(`Some rules is not supported - Rules: ${props.rules}`);
      }
    }

    const componentRef = `BFileFormGroup_${props.inputId}`;
    const uploadComponentRef = `BFileFormGroup_${props.inputId}_VUC`;

    const additionalFormGroupClass = computed(() => getDefaultFormGroupClass(props.rules));

    const innerValue: WritableComputedRef<string | string[] | null> = computed({
      get() {
        return props.value as string | string[] | null;
      },
      set(val) {
        context.emit('input', val);
      }
    });
    const fileInfo = ref<FileFullInfo | FileFullInfo[] | null>(null);

    const getInputState = (attrs: any, dirty: boolean, validated: boolean, valid: boolean): boolean | null => {
      if (Helpers.isExists(attrs.state)) {
        return attrs.state;
      }

      return dirty || validated ? valid : null;
    }

    const isLoading = ref(true);
    const isUploading = ref(false);
    const uploadFiles = ref<VUFile[]>([]);

    const getValidationProvider = () => {
      return context.refs[componentRef] as InstanceType<typeof ValidationProvider>;
    }
    const getUploadComponent = () => {
      return context.refs[uploadComponentRef] as VueUploadComponent;
    }

    const autoUploadFileInput = (newFile: VUFile, oldFile: VUFile) => {
      if (isUploading.value) return;

      // Automatic upload
      if (Boolean(newFile) !== Boolean(oldFile) || oldFile.error !== newFile.error) {
        let fileUploadComponent = getUploadComponent();
        if (!fileUploadComponent.active) {
          fileUploadComponent.active = true
        }
      }
    }

    const afterFileChanged = (newValue: string | string[] | null, setDirty = true) => {
      const vp = getValidationProvider();

      if (!Helpers.isExists(vp)) return;

      vp.syncValue(newValue);

      if (setDirty) {
        vp.setFlags({ dirty: true, pristine: false });
        vp.validate(newValue);
      }
    }

    const tryUpdateFileInfos = async (cb?: () => void) => {
      let fileIds: string[] = []

      if (props.multiple) {
        fileInfo.value = []

        if (Helpers.isNotEmptyArray(innerValue.value)) {
          const ids = innerValue.value as string[]
          const infos = fileInfo.value as FileFullInfo[] | null

          for (let i = 0; i < ids.length; i++) {
            const id = ids[i]

            if (!Helpers.isNotEmptyArray(infos) || !Helpers.anyInArray(infos!, x => x.id === id)) {
              fileIds.push(id)
            }
          }
        }
      } else {
        if (Helpers.isNotEmpty(innerValue.value)) {
          const id = innerValue.value as string
          const info = fileInfo.value as FileFullInfo | null

          if (!Helpers.isExists(fileInfo.value) || info!.id !== id) {
            fileIds.push(id)
          }
        }
      }

      if (!Helpers.isNotEmptyArray(fileIds)) {
        if (Helpers.isFunction(cb))
          cb!()

        return
      }

      await fileSharedApi.infos(fileIds, result => {
        if (Helpers.isNotEmptyArray(result)) {
          if (props.multiple) {
            fileInfo.value = result
          } else {
            fileInfo.value = result[0];
          }
        }

        if (Helpers.isFunction(cb))
          cb()
      }, errorMessage => {
        showErrorNotification(errorMessage);

        if (Helpers.isFunction(cb))
          cb()
      })
    }

    const uploadFileProgress = ref<number | null>(null);
    const uploadFile = async (file: VUFile, component: VueUploadComponent) => {
      if (isUploading.value || !Helpers.isExists(file) || !Helpers.isExists(file.file)) return;

      isUploading.value = true;

      await fileSharedApi.upload(props.type ?? null, props.metatype ?? null, null, file.file, (progress) => {
        uploadFileProgress.value = progress
      }, result => {
        const info = result!
        const fileIdValue = info.id

        if (props.multiple) {
          if (!Helpers.isArray(innerValue.value)) {
            innerValue.value = [fileIdValue]
            fileInfo.value = [info]
          } else {
            (<string[]>innerValue.value)!.push(fileIdValue);
            (<FileFullInfo[]>fileInfo.value)!.push(info);
          }
        } else {
          innerValue.value = fileIdValue
          fileInfo.value = info
        }

        context.emit('file-uploaded', fileIdValue, info)

        afterFileChanged(fileIdValue)

        uploadFileProgress.value = null;
        isUploading.value = false;
      }, errorMessage => {
        showErrorNotification(errorMessage);

        uploadFileProgress.value = null;
        isUploading.value = false;
      })
    }

    const removeFile = (fileId: string) => {
      if (Helpers.isArray(innerValue.value)) {
        let array = (<string[]>innerValue.value)!
        Helpers.removeInArray(array, x => x === fileId);

        // todo check
        afterFileChanged(array)
      } else if (innerValue.value === fileId) {
        innerValue.value = null

        afterFileChanged(null)
      }
    }

    watch(fileInfo, () => {
      if (!Helpers.isExists(fileInfo.value)) return

      context.emit('file-info-changed', fileInfo.value);
    })
    watch(() => props.value, () => {
      afterFileChanged(props.value as string | string[] | null)
      tryUpdateFileInfos()
    })

    onMounted(() => {
      // set initial value
      afterFileChanged(innerValue.value, false)
    })

    // init
    //
    if (Helpers.isNotEmptyArray(innerValue.value) || Helpers.isNotEmpty(innerValue.value)) {
      tryUpdateFileInfos(() => isLoading.value = false)
    } else {
      isLoading.value = false;
    }

    return {
      helpers: Helpers,
      fileUtils: FileUtils,
      componentRef,
      uploadComponentRef,
      additionalFormGroupClass,
      getInputState,

      isUploading,
      uploadFileProgress,
      uploadFiles,
      fileInfo,

      autoUploadFileInput,
      uploadFile,
      removeFile,
    }
  },
})
