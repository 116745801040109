













































import {computed, defineComponent, ref} from "@vue/composition-api";
import { ValidationProvider } from "vee-validate";
import helpers from "@/utils/helpers";
import useControlUtils from "@/utils/useControlUtils";
import AppTooltip from "@/views/_components/AppTooltip.vue";

export default defineComponent({
  inheritAttrs: false,
  components: {
    ValidationProvider, AppTooltip
  },
  props: {
    if: { type: Boolean, default: true },
    inputId: String,
    inputClass: String,
    displayName: { type: String, required: true },
    placeholderOverride: String,
    description: String,
    helpText: String,
    rules: [String, Object],
    withoutLabel: { type: Boolean, default: false },
    value: String
  },
  setup(props, { emit }) {
    const { getDefaultFormGroupClass, checkIfContainRule } = useControlUtils()

    // validation
    if (!helpers.isNotEmpty(props.inputId)) {
      console.error(`inputId is empty or undefined - DisplayName: ${props.displayName}`);
    }

    const additionalFormGroupClass = computed(() => getDefaultFormGroupClass(props.rules));
    const innerValue = computed<string | null | undefined>({
      get() {
        return props.value;
      },
      set(val) {
        emit('input', val);
      }
    });

    const getInputState = (attrs: any, dirty: boolean, validated: boolean, valid: boolean): boolean | null => {
      if (helpers.isExists(attrs.state)) {
        return attrs.state;
      }
      return dirty || validated ? valid : null;
    }

    return {
      helpers: helpers,

      additionalFormGroupClass,
      innerValue,
      getInputState,
    }
  },
})
