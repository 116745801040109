






import {computed, defineComponent, nextTick, watch} from "@vue/composition-api";
import useRouter from "@/router";
import {UiMutationTypes} from "@/store/modules/ui/types";
import useStore from "@/store";
import helpers from "@/utils/helpers";
import paymentApi from "@/api/payment-api";
import appUtils from "@/utils/app-utils";
import useNotifications from "@/components/useNotifications";
import useSharedUtils from "@/utils/useSharedUtils";

export enum CallbackAction {
  Close = "close",
  Redirect = "redirect",
}

export default defineComponent({
  setup(props, context) {
    const store = useStore()
    const router = useRouter()
    const {closePage} = useSharedUtils()

    const action = router.currentRoute.params.action

    // init
    //
    store.commit(UiMutationTypes.UPDATE_GLOBAL_LOADING, true);

    switch (action) {
      case CallbackAction.Close:
        closePage();
        break
      case CallbackAction.Redirect:
        if (helpers.isNotEmpty(router.currentRoute.query.url)) {
          appUtils.redirectToAbsoluteUrl(router.currentRoute.query.url)
        } else {
          closePage()
        }
        break
    }

    return {
    }
  }
})
