
import {
  ApiResponse, FileFullInfo, FileMetatype, FileType
} from '@/@models'
import FileUtils from "@/utils/file-utils";
import _ApiBase, {ApiErrorCallback, ApiSuccessCallback} from "@/api/api-base";

export default new class extends _ApiBase {
  protected override getFullUrl(apiUrl: string) {
    return `files${apiUrl}`
  }

  upload(type: FileType | null, metatype: FileMetatype | null, overrideFileName: string | null, file: File | Blob,
         uploadProgress: ((progress: number | null, loaded: number) => void) | null,
         successCb: ApiSuccessCallback<FileFullInfo>,
         errorCb: ApiErrorCallback<FileFullInfo>) {
    let formData = new FormData();
    formData.append("file", file);

    return this._defaultPostResponse('/upload', formData, {
      params: { type, metatype, overrideFileName },
      headers: {
        'Content-Type': 'multipart/form-data'
      },
      onUploadProgress: FileUtils.generateFileUploadCallback(uploadProgress)
    }, successCb, errorCb);
  }

  infos(ids: string[],
        successCb: ApiSuccessCallback<FileFullInfo[]>,
        errorCb: ApiErrorCallback<FileFullInfo[]>) {
    return this._defaultGetResponse('/infos', {
      params: {
        ids
      }
    }, successCb, errorCb);
  }
}
