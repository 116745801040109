

































































import {computed, defineComponent, nextTick, PropType, ref, watch} from "@vue/composition-api";
import useRouter from "@/router";
import useStore from "@/store";
import helpers from "@/utils/helpers";
import appUtils from "@/utils/app-utils";
import useNotifications from "@/components/useNotifications";
import {AiAgent, AiAgentLanguage, AiAgentStyle, AiAgentRole, ChannelActivityAiAgentItem, Guid} from "@/@models";
import PageLoadingIndicator from "@/views/_components/PageLoadingIndicator.vue";
import AiAgentActivityItemView from "@/views/ai-agent/_partials/AiAgentActivityItemView.vue";
import {UiMutationTypes} from "@/store/modules/ui/types";

export default defineComponent({
  components: {PageLoadingIndicator, AiAgentActivityItemView},
  props: {
    items: Array as PropType<ChannelActivityAiAgentItem[] | null>,
    isItemsLoading: Boolean,
  },
  setup(props, context) {
    const store = useStore()
    const {showSuccessNotification, showErrorNotification} = useNotifications(context)

    const isFullscreenMode = ref(false)

    const toggleFullscreenMode = () => {
      isFullscreenMode.value = !isFullscreenMode.value

      store.commit(UiMutationTypes.UPDATE_PAGE_FULLSCREEN_MODE, isFullscreenMode.value)
    }

    return {
      helpers,

      isFullscreenMode,
      toggleFullscreenMode
    }
  }
})
