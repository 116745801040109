


























import {computed, defineComponent, nextTick, PropType, ref, watch} from "@vue/composition-api";
import useRouter from "@/router";
import useStore from "@/store";
import helpers from "@/utils/helpers";
import appUtils from "@/utils/app-utils";
import useNotifications from "@/components/useNotifications";
import {
  ChannelActivityAiAgentItemTypes,
  ChannelActivityAiAgentItem,
  Guid
} from "@/@models";
import dayjs from "dayjs";

export default defineComponent({
  props: {
    item: { type: Object as PropType<ChannelActivityAiAgentItem>, required: true },
  },
  setup(props, context) {
    const createdAtFormated = computed(() => dayjs(props.item.createdAt).format('L LT'))

    const sourceText = computed(() => {
      let contentHtml = ''
      switch (props.item.type) {
        case ChannelActivityAiAgentItemTypes.ResultFromSourceCreated:
          contentHtml = props.item.sourceText ?? ''
          break;
        default:
          break;
      }

      // todo sanitize html
      return contentHtml ?? ''
    })

    const generateParameterChangedString = (parameterName: string, name: string | null, prevName: string | null, nullFallbackName = "(empty)") => {
      const currentName = helpers.isNotEmpty(name) ? "\"" + name + "\"" : nullFallbackName

      if (helpers.isNotEmpty(prevName)) {
        return `${parameterName}: \"${prevName}\" -> ${currentName}`
      } else {
        return `${parameterName}: ${currentName}`
      }
    }

    const resultText = computed(() => {
      let contentHtml = ''
      switch (props.item.type) {
        case ChannelActivityAiAgentItemTypes.ResultFromSourceCreated:
          contentHtml = props.item.text ?? ''
          break;
        case ChannelActivityAiAgentItemTypes.AgentParametersChanged:
          let parameters: string[] = []

          if (props.item.isAiAgentChanged)
            parameters.push(generateParameterChangedString("AI Agent", props.item.aiAgentName, props.item.otherAiAgentName))
          if (props.item.isAiAgentLanguageChanged)
            parameters.push(generateParameterChangedString("Language", props.item.aiAgentLanguageName, props.item.otherAiAgentLanguageName, "Default"))
          if (props.item.isAiAgentRoleChanged)
            parameters.push(generateParameterChangedString("Role", props.item.aiAgentRoleName, props.item.otherAiAgentRoleName, "Default"))
          if (props.item.isAiAgentStyleChanged)
            parameters.push(generateParameterChangedString("Style", props.item.aiAgentStyleName, props.item.otherAiAgentStyleName, "Default"))

          contentHtml = `<div class="font-weight-bold">Agent Changed</div>${parameters.join('<br/>')}`
          break;
      }

      // todo sanitize html
      return contentHtml ?? ''
    })

    return {
      helpers,

      createdAtFormated,
      sourceText,
      resultText,
    }
  }
})
