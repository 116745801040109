import {Guid} from "@/@models";

export enum KnowledgeBaseArticleType {
  SinglePage = 0,
}

export interface KnowledgeBasePublicArticle {
  id: Guid
  publicId: string
  title: string
  contentHtml: string
}

export interface KnowledgeBaseArticleLocalizationEdit {
  localizationId: Guid
  title: string | null
  contentHtml: string | null
}

export interface KnowledgeBaseArticleEdit {
  id: Guid | null

  type: KnowledgeBaseArticleType | null

  isPublished: boolean
  localizations: KnowledgeBaseArticleLocalizationEdit[]
}

export interface KnowledgeBaseArticleCreateOrUpdateResult {
  isCreate: boolean
  id: Guid
  publicId: string
}