
export type State = {
  isTelegramApp: boolean | null
  isTelegramAppAuth: boolean | null
  isTelegramAppThemeEnabled: boolean | null
  isTelegramAppDarkTheme: boolean | null
  telegramAppBgColorHex: string | null
}

export const state: State = {
  isTelegramApp: null,
  isTelegramAppAuth: null,
  isTelegramAppThemeEnabled: null,
  isTelegramAppDarkTheme: null,
  telegramAppBgColorHex: null,
};
