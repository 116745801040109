import Vuex, {createLogger, ActionContext, StoreOptions} from 'vuex';
import Vue from 'vue';

import ui, { UiStore, State as UiState} from '@/store/modules/ui';
import context, { ContextStore, State as ContextState} from '@/store/modules/context';

export type RootState = {
  ui: UiState;
  context: ContextState;
};

export type _AugmentedActionContext<
  State,
  Actions extends {[key: string]: any},
  Mutations extends {[key: string]: any},
  Getters extends {[key: string]: any}> = {
  dispatch<K extends keyof Actions>(
    key: K,
    payload?: Parameters<Actions[K]>[1],
  ): ReturnType<Actions[K]>;
} & {
  commit<K extends keyof Mutations>(
    key: K,
    payload?: Parameters<Mutations[K]>[1],
  ): ReturnType<Mutations[K]>;
} & {
  getters: {
    [K in keyof Getters]: ReturnType<Getters[K]>
  }
} & Omit<ActionContext<State, RootState>, 'dispatch' | 'commit' | 'getters'>

export type Store = UiStore<Pick<RootState, 'ui'>> &
  ContextStore<Pick<RootState, 'context'>>;

// Plug in logger when in development environment
const debug = process.env.NODE_ENV !== 'production';
const plugins = debug ? [createLogger({})] : [];

const storeOptions: StoreOptions<RootState> = {
  plugins,
  modules: {
    ui,
    context,
  }
};

Vue.use(Vuex);
const store = new Vuex.Store<RootState>(storeOptions);

export default function useStore(): Store {
  return store as Store;
}
