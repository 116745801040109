














import {defineComponent} from "@vue/composition-api";
import {UiMutationTypes} from "@/store/modules/ui/types";
import useStore from "@/store";

export default defineComponent({
  setup() {
    const store = useStore()

    store.commit(UiMutationTypes.UPDATE_GLOBAL_LOADING, false);

    return {}
  }
})
