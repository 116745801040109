
import {
  Guid,
  Channels,
  ChannelInfo,
  ChannelAutomatedAssistantAiAgentInfo,
  ChannelActivityAiAgentItems
} from '@/@models'
import ClientApiBase from "@/api/client-api-base";
import appUtils from "@/utils/app-utils";
import {ApiEmptyErrorCallback, ApiEmptySuccessCallback, ApiErrorCallback, ApiSuccessCallback} from "@/api/api-base";
import helpers from "@/utils/helpers";

export default new class extends ClientApiBase {
  channels(successCb: ApiSuccessCallback<Channels>,
           errorCb: ApiErrorCallback<Channels>,
           onlyIds: Guid[] | null = null) {
    return this._defaultPostResponse('/channels/list', {
      onlyOwned: !helpers.isArray(onlyIds), onlyIds, telegramInitData: appUtils.getTelegramInitData()
    }, null, successCb, errorCb);
  }

  getInfo(id: Guid | null, publicId: string | null,
          successCb: ApiSuccessCallback<ChannelInfo>,
          errorCb: ApiErrorCallback<ChannelInfo>) {
    return this._defaultPostResponse('/channels/info', {
      id, publicId, telegramInitData: appUtils.getTelegramInitData()
    }, null, successCb, errorCb);
  }

  changeCauseSelection(id: Guid, causeId: Guid, isSelected: boolean,
                       successCb: ApiEmptySuccessCallback,
                       errorCb: ApiEmptyErrorCallback) {
    return this._defaultEmptyPostResponse('/channels/change-cause-selection', {
      id, causeId, isSelected, telegramInitData: appUtils.getTelegramInitData()
    }, null, successCb, errorCb);
  }

  updateCauses(id: Guid, causeIds: Guid[],
               successCb: ApiEmptySuccessCallback,
               errorCb: ApiEmptyErrorCallback) {
    return this._defaultEmptyPostResponse('/channels/update-causes', {
      id, causeIds, telegramInitData: appUtils.getTelegramInitData()
    }, null, successCb, errorCb);
  }

  changeCharity(channelId: Guid, charityId: Guid,
                successCb: ApiEmptySuccessCallback,
                errorCb: ApiEmptyErrorCallback) {
    return this._defaultEmptyPostResponse('/channels/change-charity', {
      channelId, charityId, telegramInitData: appUtils.getTelegramInitData()
    }, null, successCb, errorCb);
  }

  getAutomatedAssistantAiAgentInfo(id: Guid | null, publicId: string | null,
                                   successCb: ApiSuccessCallback<ChannelAutomatedAssistantAiAgentInfo>,
                                   errorCb: ApiErrorCallback<ChannelAutomatedAssistantAiAgentInfo>) {
    return this._defaultPostResponse('/channels/automated-assistant-ai-agent-info', {
      id, publicId, telegramInitData: appUtils.getTelegramInitData()
    }, null, successCb, errorCb);
  }
  updateAutomatedAssistantAiAgent(channelId: Guid, isEnabled: boolean, aiAgentId: Guid | null,
                                  aiAgentLanguageId: Guid | null, aiAgentRoleId: Guid | null, aiAgentStyleId: Guid | null,
                                  successCb: ApiEmptySuccessCallback,
                                  errorCb: ApiEmptyErrorCallback) {
    return this._defaultEmptyPostResponse('/channels/update-automated-assistant-ai-agent', {
      channelId, isEnabled, aiAgentId, aiAgentLanguageId, aiAgentRoleId, aiAgentStyleId,
      telegramInitData: appUtils.getTelegramInitData()
    }, null, successCb, errorCb);
  }
  createChannelAutomatedAssistantAiAgentPreview(channelId: Guid, channelSourceId: Guid,
                                                successCb: ApiEmptySuccessCallback,
                                                errorCb: ApiEmptyErrorCallback) {
    return this._defaultEmptyPostResponse('/channels/create-channel-automated-assistant-ai-agent-preview', {
      channelId, channelSourceId, telegramInitData: appUtils.getTelegramInitData()
    }, null, successCb, errorCb);
  }

  createChannelLiveAssistantAiAgentResult(channelId: Guid, aiAgentId: Guid, aiAgentLanguageId: Guid | null,
                                          aiAgentRoleId: Guid | null, aiAgentStyleId: Guid | null, text: string,
                                          successCb: ApiEmptySuccessCallback,
                                          errorCb: ApiEmptyErrorCallback) {
    return this._defaultEmptyPostResponse('/channels/create-channel-live-assistant-ai-agent-result', {
      channelId, aiAgentId, aiAgentLanguageId, aiAgentRoleId, aiAgentStyleId, text,
      telegramInitData: appUtils.getTelegramInitData()
    }, null, successCb, errorCb);
  }

  getChannelAutomatedAssistantAiAgentActivityItems(channelId: Guid,
                                                   successCb: ApiSuccessCallback<ChannelActivityAiAgentItems>,
                                                   errorCb: ApiErrorCallback<ChannelActivityAiAgentItems>) {
    return this._defaultPostResponse('/channels/channel-ai-agent-activity-items', {
      channelId, isAutomatedAssistant: true, telegramInitData: appUtils.getTelegramInitData()
    }, null, successCb, errorCb);
  }

  getChannelLiveAssistantAiAgentActivityItems(channelId: Guid, aiAgentId: Guid,
                                              successCb: ApiSuccessCallback<ChannelActivityAiAgentItems>,
                                              errorCb: ApiErrorCallback<ChannelActivityAiAgentItems>) {
    return this._defaultPostResponse('/channels/channel-ai-agent-activity-items', {
      channelId, liveAssistantAiAgentId: aiAgentId, telegramInitData: appUtils.getTelegramInitData()
    }, null, successCb, errorCb);
  }
}
