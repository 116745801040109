

































import {defineComponent, nextTick, onBeforeUnmount, onMounted, onUnmounted, ref, watch} from "@vue/composition-api";
import classNames from "classnames/dedupe";
import useStore from "@/store";
import Helpers from "@/utils/helpers";
import AdminAppTopBar from "@/views/_layout/AdminAppTopBar.vue";
import {UiMutationTypes} from "@/store/modules/ui/types";

export default defineComponent({
  components: {AdminAppTopBar},
  setup(props, context) {
    const store = useStore()

    store.commit(UiMutationTypes.UPDATE_WITH_FOOTER, false)

    return {
      helpers: Helpers,
    }
  }
})
