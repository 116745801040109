
import {
  Guid, Localizations, LocalizationStrings
} from '@/@models'
import PortalApiBase from "@/api/portal-api-base";
import appUtils from "@/utils/app-utils";
import {ApiEmptyErrorCallback, ApiEmptySuccessCallback, ApiErrorCallback, ApiSuccessCallback} from "@/api/api-base";

export default new class extends PortalApiBase {
  getLocalizations(successCb: ApiSuccessCallback<Localizations>,
                   errorCb: ApiErrorCallback<Localizations>) {
    return this._defaultPostResponse('/localization/localizations', {
      telegramInitData: appUtils.getTelegramInitData()
    }, null, successCb, errorCb);
  }

  getLocalizationStrings(localizationId: Guid,
                         successCb: ApiSuccessCallback<LocalizationStrings>,
                         errorCb: ApiErrorCallback<LocalizationStrings>) {
    return this._defaultPostResponse('/localization/localization-strings', {
      localizationId, telegramInitData: appUtils.getTelegramInitData()
    }, null, successCb, errorCb);
  }

  updateLocalizationStrings(model: LocalizationStrings,
                            successCb: ApiEmptySuccessCallback,
                            errorCb: ApiEmptyErrorCallback) {
    return this._defaultEmptyPostResponse('/localization/update-localization-strings', {
      model, telegramInitData: appUtils.getTelegramInitData()
    }, null, successCb, errorCb);
  }
}
