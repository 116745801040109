import {EnumSelectOption, Guid, ListWithPagination} from "@/@models/shared";

export enum CharitySizeType {
  Unknown = 0,
  Micro = 1,
  Small = 2,
  Medium = 3,
  Large = 4,
  Super = 5,
}
export const CharitySizeType_ALL: EnumSelectOption[] = [
  { label: 'Unknown', id: CharitySizeType.Unknown },
  { label: 'Small', id: CharitySizeType.Micro },
  { label: 'Medium', id: CharitySizeType.Small },
  { label: 'Large', id: CharitySizeType.Medium },
  { label: 'Large', id: CharitySizeType.Large },
  { label: 'SUPER', id: CharitySizeType.Super },
];

export enum CharityAddressType {
  Physical = 0,
  Mailing = 1,
}

export interface CharityInfoCause {
  id: Guid
  name: string
}

export interface CharityInfoAddress {
  type: CharityAddressType
  addressLine1: string | null
  addressLine2: string | null
  city: string | null
  zip: string | null
  state: string | null
  country: string | null
}

export interface CharityInfo {
  id: Guid
  ein: string | null
  logoFileUrl: string | null
  name: string
  sizeType: CharitySizeType
  locationStateName: string | null
  locationCountryName: string | null
  descriptionShort: string | null
  descriptionLong: string | null
  causes: CharityInfoCause[]
  addresses: CharityInfoAddress[]
  websiteUrl: string | null
  phone: string | null
}

export interface CharitiesSearchResult {
  models: ListWithPagination<CharityInfo>
}