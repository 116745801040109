import {CharityInfo, FileFullInfo, Guid} from "@/@models";

export enum PaymentLinkPageBehavior {
  RedirectToCheckoutUrl = 0,
  RecurringPaymentChangeFromOther = 1,
  RecurringPaymentActive = 2,
  RecurringPaymentPaused = 3,
}

export interface PaymentLinkPage {
  channelPublicId: string | null
  isManagementPortalAvailable: boolean
  behavior: PaymentLinkPageBehavior
  currentRecurringPaymentName: string | null
  currentRecurringPaymentAmount: number | null
  changeToRecurringPaymentName: string | null
  changeToRecurringPaymentAmount: number | null
  checkoutUrl: string | null
}

export interface PaymentAccountLink {
  url: string
  isRedirectUrl: boolean
}

export interface PaymentManagementPortalSession {
  redirectUrl: string | null
}

export interface ChannelSupportPageCause {
  id: Guid
  name: string
}

export interface ChannelSupportPageSubscriptionGroup {
  targetPercentage: number
  subscription: ChannelSupportPageSubscription
}

export interface ChannelSupportPageOneTimeGroup {
  targetPercentage: number
  oneTimes: ChannelSupportPageOneTime[]
}

export interface ChannelSupportPageSubscription {
  paymentLinkPublicId: string
  amount: number
  targetPercentage: number
  isSubscribed: boolean
  isSubscriptionPaused: boolean
}

export interface ChannelSupportPageOneTime {
  paymentLinkPublicId: string
  suggestedAmount: number | null
}

export interface ChannelSupportPage {
  channelName: string
  channelUrl: string
  channelCauses: ChannelSupportPageCause[]
  channelVideoFile: FileFullInfo | null
  channelMission: string
  charity: CharityInfo | null
  subscriptionGroups: ChannelSupportPageSubscriptionGroup[]
  mainOneTime: ChannelSupportPageOneTime | null
  oneTimeGroups: ChannelSupportPageOneTimeGroup[] | null
  archivedSubscriptions: ChannelSupportPageSubscription[]
  isManagementPortalAvailable: boolean
}