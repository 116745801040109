



































































































import {computed, defineComponent, ref} from "@vue/composition-api";
import {
  PortalSubscriptionAdminEdit,
  PortalSubscriptionLocalizationAdminEdit,
  Guid,
  Localizations,
} from "@/@models";
import Helpers from "@/utils/helpers";
import useRouter from "@/router";
import useNotifications from "@/components/useNotifications";
import {ValidationObserver} from "vee-validate";
import PageLoadingIndicator from "@/views/_components/PageLoadingIndicator.vue";
import BTextInputFormGroup from "@/views/_controls/BTextInputFormGroup.vue";
import portalPortalApi from "@/api/portal/portal-portal-api";
import helpers from "@/utils/helpers";
import localizationPortalApi from "@/api/portal/localization-portal-api";
import useStore from "@/store";
import useModals from "@/utils/useModals";
import AppLocalizationFields from "@/views/_components/AppLocalizationFields.vue";
import BTextareaFormGroup from "@/views/_controls/BTextareaFormGroup.vue";
import BCheckboxFormGroup from "@/views/_controls/BCheckboxFormGroup.vue";
import SimpleRichTextEditor from "@/views/_controls/SimpleRichTextEditor.vue";

export default defineComponent({
  components: {
    SimpleRichTextEditor,
    BCheckboxFormGroup,
    BTextareaFormGroup,
    ValidationObserver, PageLoadingIndicator, BTextInputFormGroup,
    AppLocalizationFields
  },
  setup: function (props, context) {
    const store = useStore()
    const router = useRouter()
    const {showSuccessNotification, showInfoNotification, showErrorNotification} = useNotifications(context)
    const {showDangerConfirmModal} = useModals(context)

    const isCreate = computed(() => context.root.$route.name === 'admin-create-portal-subscription')
    const idParam = context.root.$route.params.id

    const isLoading = ref(true);
    const isButtonsDisabled = ref(false);

    const localizations = ref<Localizations | null>(null)
    const selectedLocalization = computed(() => {
      if (!helpers.isExists(localizations.value)) return null

      return helpers.findFirstInArray(localizations.value.localizations,
        x => x.id === localizations.value!.selectedId)
    })

    const model = ref<PortalSubscriptionAdminEdit | null>()
    const modelSelectedLocalization = computed(() => {
      if (!helpers.isExists(model.value) || !helpers.isExists(selectedLocalization.value)) return null

      return helpers.findFirstInArray(model.value.localizations,
        x => x.localizationId === selectedLocalization.value!.id)
    })

    const getFormValidation = () => context.refs.formValidation as InstanceType<typeof ValidationObserver> | null;
    const saveChanges = async () => {
      if (!Helpers.isExists(model.value)) return

      isButtonsDisabled.value = true;

      let isValid = await getFormValidation()?.validate() ?? false;

      if (isValid) {
        portalPortalApi.updatePortalSubscription(model.value!, (result, r) => {
          if (isCreate.value || r.isAnyChanges) {
            showSuccessNotification("Changes successfully saved")
          } else {
            showInfoNotification("No update required, resources are up to date")
          }

          router.push({name: 'admin-portal-subscriptions-table'})

          isButtonsDisabled.value = false;
        }, errorMessage => {
          isButtonsDisabled.value = false;

          showErrorNotification(errorMessage)
        });
      } else {
        isButtonsDisabled.value = false;

        showErrorNotification("Error, please check the fields on the page")
      }
    }

    const updateEditModel = (id: Guid) => {
      portalPortalApi.getPortalSubscriptionForEdit(id, result => {
        model.value = result

        isLoading.value = false;
      }, errorMessage => {
        showErrorNotification(errorMessage);

        isLoading.value = false;
      });
    }

    const updateLocalizations = (cb?: (localizations: Localizations) => void) => {
      localizationPortalApi.getLocalizations(result => {
        localizations.value = result

        if (helpers.isFunction(cb))
          cb(localizations.value)
      }, errorMessage => {
        showErrorNotification(errorMessage);
      });
    }

    const initData = () => {
      if (isCreate.value) {
        updateLocalizations((localizations) => {
          model.value = {
            id: null,

            position: 0,

            generalName: "",

            trialPeriodDays: null,

            contentSourceLimit: null,
            aiTokenLimit: null,

            localizations: localizations.localizations.map(x => ({
              localizationId: x.id,
              name: null,
              description: null,
            })),

            // payment link
            isDisabled: true,
            amount: 0
          }

          isLoading.value = false;
        })
      } else {
        if (!helpers.isNotEmpty(idParam))
          throw new Error("id is required")

        updateLocalizations((localizations) => {
          updateEditModel(idParam)
        })
      }
    }

    // init
    //
    initData()

    return {
      helpers: Helpers,

      isLoading,
      isButtonsDisabled,

      isCreate,
      model: model as unknown as PortalSubscriptionAdminEdit,
      modelSelectedLocalization: modelSelectedLocalization as unknown as PortalSubscriptionLocalizationAdminEdit,

      selectedLocalization,

      saveChanges,
    }
  }
})
