import helpers from '@/utils/helpers'
import {nextTick} from "@vue/composition-api";
import useRouter from "@/router";
import {Location, RawLocation} from "vue-router/types/router";
import {FileType} from "@/@models";
import {FileStringObjectType} from "@/utils/useStringObjectUtils";

export class AppUtilsModel {
  reloadCurrentPage() {
    window.location.reload()
  }

  openInNewTabAbsoluteUrl(url: string) {
    //console.log("OPEN URL: " + url)
    window.open(url)
  }
  redirectToAbsoluteUrl(url: string, isParentRedirect?: boolean | null) {
    //console.log("REDIRECT TO URL: " + url)

    if (isParentRedirect) {
      if (helpers.isExists(window.top)) {
        window.top.location.href = url
      } else {
        window.parent.location.href = url
      }
    } else {
      window.location.href = url
    }
  }

  getRouterAbsoluteUrl(to: RawLocation) {
    const router = useRouter()
    const route = router.resolve(to);
    return new URL(route.href, window.location.origin).href;
  }

  getTelegramBotLink() {
    return TELEGRAM_BOT_LINK
  }

  getTelegramWebAppLink(startParam: string) {
    return TELEGRAM_WEBAPP_LINK_TEMPLATE.replaceAll("{START_PARAM}", startParam)
  }

  getTelegramInitData() {
    const telegramApp = window.Telegram.WebApp
    return telegramApp.initData
  }

  getPaymentLinkRoute(publicId: string, settings?: {
    returnToChannelPublicId?: string | null
    returnToPremium?: boolean | null
    useTrial?: boolean | null
  } | null) {
    let route: Location = {
      name: 'payment-link',
      params: {
        paymentLinkPublicId: publicId
      },
      query: {
        returnToChannelPublicId: settings?.returnToChannelPublicId,
        returnToPremium: settings?.returnToPremium ? 1 : <any>undefined,
        trial: settings?.useTrial ? 1 : <any>undefined
      }
    }

    return route
  }

  getCurrencyAmount(amount: number | null) {
    return helpers.isNumeric(amount) ? '$' + amount : ''
  }

  convertFileTypeToFileStringObjectType(fileType: FileType) {
    switch (fileType) {
      case FileType.Raw:
        return FileStringObjectType.Raw
      case FileType.Image:
        return FileStringObjectType.Image
      case FileType.Video:
        return FileStringObjectType.Video
      case FileType.Audio:
        return FileStringObjectType.Audio
    }
  }
  convertFileStringObjectTypeToFileType(type: FileStringObjectType) {
    switch (type) {
      case FileStringObjectType.Raw:
        return FileType.Raw
      case FileStringObjectType.Image:
        return FileType.Image
      case FileStringObjectType.Video:
        return FileType.Video
      case FileStringObjectType.Audio:
        return FileType.Audio
    }
  }
  formatEin(ein?: string | null) {
    if (!helpers.isNotEmpty(ein)) return null

    if (ein.length === 9) {
      return ein.substring(0, 2) + '-' + ein.substring(2)
    } else {
      return ein
    }
  }
  formatPhone(phone?: string | null) {
    if (!helpers.isNotEmpty(phone)) return null

    const cleaned = ('' + phone).replace(/\D/g, '')
    const match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/)

    if (helpers.isExists(match)) {
      const intlCode = (match[1] ? '+1 ' : '');
      return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('');
    }
    return null;
  }

  waitUntilVueComponentReady<T>(getComponent: () => T, ready: (component: T | undefined) => void,
                                maxIterations = 5) {
    let component = getComponent();
    let iteration = 0;

    const action = () => {
      nextTick(() => {
        component = getComponent()

        if (helpers.isExists(component)) {
          ready(component);
        } else {
          if (iteration <= maxIterations) {
            iteration++;
            action();
          } else {
            ready(component);
            console.error("Can't init component in waitUntilVueComponentReady")
          }
        }
      });
    }

    if (helpers.isExists(component)) {
      ready(component);
    } else {
      action();
    }
  }
}

const appUtils = new AppUtilsModel();
export default appUtils;