















import {computed, defineComponent} from "@vue/composition-api";
import useRouter from "@/router";
import paymentApi from "@/api/payment-api";
import helpers from "@/utils/helpers";
import appUtils from "@/utils/app-utils";
import useStore from "@/store";
import useSharedUtils from "@/utils/useSharedUtils";

enum ResultStatus {
  Undefined,
  Completed,
  Canceled
}

export default defineComponent({
  setup() {
    const store = useStore()
    const router = useRouter()
    const {closePage} = useSharedUtils()

    const paymentLinkPublicId = router.currentRoute.params.paymentLinkPublicId as string
    const checkoutSessionId = router.currentRoute.query.checkoutSessionId as string
    const returnToChannelPublicId = router.currentRoute.query.returnToChannelPublicId as string | null
    const returnToPremium = router.currentRoute.query.returnToPremium?.toString() === "1"
    const returnToUrl = router.currentRoute.query.returnToUrl as string | null

    let resultStatus: ResultStatus
    switch (router.currentRoute.name) {
      case 'payment-completed':
        resultStatus = ResultStatus.Completed
        break
      case 'payment-canceled':
        resultStatus = ResultStatus.Canceled
        break
      default:
        resultStatus = ResultStatus.Undefined
        break
    }

    const messageBgVariant = computed(() => {
      switch (resultStatus) {
        case ResultStatus.Completed: return "success";
        case ResultStatus.Canceled: return "warning";
        case ResultStatus.Undefined: return "";
      }
    })

    const messageHeader = computed(() => {
      switch (resultStatus) {
        case ResultStatus.Completed: return "Payment Completed";
        case ResultStatus.Canceled: return "Payment Canceled";
        case ResultStatus.Undefined: return "";
      }
    })

    const messageText = computed(() => {
      switch (resultStatus) {
        case ResultStatus.Completed: return "Payment successfully completed";
        case ResultStatus.Canceled: return "Payment was canceled";
        case ResultStatus.Undefined: return "";
      }
    })

    paymentApi.paymentLinkCheckoutResult(paymentLinkPublicId, resultStatus === ResultStatus.Completed,
      checkoutSessionId, r => {
        if (r.success) {
          console.log("Checkout result updated successfully")
        }

        setTimeout(() => {
          if (helpers.isNotEmpty(returnToUrl)) {
            appUtils.redirectToAbsoluteUrl(returnToUrl)
          } else if (helpers.isNotEmpty(returnToChannelPublicId)) {
            router.push({
              name: 'channel-support',
              params: {
                channelPublicId: returnToChannelPublicId
              }
            })
          } else if (returnToPremium) {
            router.push({
              name: 'premium'
            })
          } else {
            closePage();
          }
        }, 5000)
    }, errorMessage => {
        console.error(errorMessage)

        closePage();
    })

    return {
      resultStatus,

      messageBgVariant,
      messageHeader,
      messageText,
    }
  }
})
