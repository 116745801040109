































































































import {
  computed,
  defineComponent,
  nextTick,
  onBeforeUnmount,
  onMounted,
  onUnmounted, PropType,
  ref,
  watch, watchEffect
} from "@vue/composition-api";
import useRouter from "@/router";
import useStore from "@/store";
import helpers from "@/utils/helpers";
import appUtils from "@/utils/app-utils";
import {
  Cause,
  Causes,
  ChannelInfo,
  Guid,
  ListWithPagination,
  CharityInfo, SelectOption, TelegramBotRoute
} from "@/@models";
import PageLoadingIndicator from "@/views/_components/PageLoadingIndicator.vue";
import useNotifications from "@/components/useNotifications";
import debounce from "debounce";
import BTextInputFormGroup from "@/views/_controls/BTextInputFormGroup.vue";
import channelApi from "@/api/channel-api";
import causeApi from "@/api/cause-api";
import charityApi from "@/api/charity-api";
import BSelectFormGroup from "@/views/_controls/BSelectFormGroup.vue";
import CharityInfoView from "@/views/channel/_partials/CharityInfoView.vue";
import useModals from "@/utils/useModals";
import useSharedUtils from "@/utils/useSharedUtils";
import telegramApi from "@/api/telegram-api";

export default defineComponent({
  components: {
    BTextInputFormGroup,
    BSelectFormGroup,
    PageLoadingIndicator,
    CharityInfoView,
  },
  props: {
    channelInfo: { type: Object as PropType<ChannelInfo>, required: true },
    loading: Boolean,
    disabled: Boolean
  },
  setup(props, context) {
    const store = useStore()
    const router = useRouter()
    const {showErrorNotification} = useNotifications(context)
    const {showSuccessConfirmModal} = useModals(context)
    const {closePage} = useSharedUtils()

    const channelCausesOptionId = "_all_channel_causes"

    const telegramMessageLinkId = router.currentRoute.query.telegramMessageLinkId as string | null

    const isLoadingLocal = ref(true)
    const isLoadingFinal = computed(() => props.loading || isLoadingLocal.value)
    const isButtonsDisabledLocal = ref(false)
    const isButtonsDisabledFinal = computed(() => props.disabled || isButtonsDisabledLocal.value)

    const channelCharity = ref<CharityInfo | null>(null)

    const charitiesCurrentPage = ref(1)
    const charities = ref<ListWithPagination<CharityInfo> | null>(null)
    const charitiesResultFiltered = computed<CharityInfo[]>(() => {
      if (!helpers.isExists(charities.value)) return []

      if (helpers.isExists(channelCharity.value)) {
        return charities.value.results.filter(x => x.id !== channelCharity.value!.id)
      } else {
        return charities.value.results
      }
    })

    const charityCauses = ref<Cause[] | null>(null)
    const charityCauseOptions = computed<SelectOption[]>(() => {
      const options: SelectOption[] = []

      if (!helpers.isExists(props.channelInfo) ||
        !helpers.isNotEmptyArray(charityCauses.value)) return options

      if (helpers.isNotEmptyArray(props.channelInfo.causeIds)) {
        options.push({
          id: channelCausesOptionId,
          label: "My Causes",
          level: 0
        })

        for (const causeId of props.channelInfo.causeIds) {
          const charityCause = helpers.findFirstInArray(charityCauses.value, x => x.id === causeId)

          if (!helpers.isExists(charityCause)) throw new Error("Cause Id is not exists: " + causeId)

          options.push({
            id: charityCause.id,
            label: charityCause.name,
            level: 1
          })
        }
      }

      const otherCauses: SelectOption[] = []
      for (const charityCause of charityCauses.value) {
        if (helpers.isNotEmptyArray(props.channelInfo.causeIds) &&
          helpers.anyInArray(props.channelInfo.causeIds, x => x === charityCause.id)) continue

        otherCauses.push({
          id: charityCause.id,
          label: charityCause.name,
          level: helpers.isNotEmpty(charityCause.parentId) ? 1 : 0
        })
      }

      if (helpers.isNotEmptyArray(otherCauses)) {
        otherCauses.unshift({
          id: "",
          label: "",
          group: "Other Causes"
        })
      }

      options.push(...otherCauses)

      return options
    })

    const filterSearchTerm = ref<string | null>(null)
    const filterCauseIds = ref<Guid[]>([])
    const isChannelCausesMode = ref(false)

    const updateChannelCharity = () => {
      if (!helpers.isExists(props.channelInfo)) return

      if (!helpers.isNotEmpty(props.channelInfo.charityId)) {
        channelCharity.value = null
        return
      }

      isButtonsDisabledLocal.value = true

      charityApi.get(props.channelInfo.charityId, result => {
        channelCharity.value = result

        isButtonsDisabledLocal.value = false
      }, errorMessage => {
        showErrorNotification(errorMessage)

        isButtonsDisabledLocal.value = false
      })
    }

    const updateCharities = (cb?: (success: boolean) => void) => {
      if (!helpers.isExists(props.channelInfo)) return

      isButtonsDisabledLocal.value = true

      charityApi.search(filterSearchTerm.value, filterCauseIds.value, charitiesCurrentPage.value,
        props.channelInfo.charityId, result => {
          charities.value = result.models

          charitiesCurrentPage.value = charities.value.page

          isButtonsDisabledLocal.value = false

          if (helpers.isFunction(cb))
            cb(true)
        }, errorMessage => {
          showErrorNotification(errorMessage)

          isButtonsDisabledLocal.value = false

          if (helpers.isFunction(cb))
            cb(false)
        })
    }

    watch(() => [
      filterSearchTerm.value,
    ], () => updateCharitiesWithTimer())

    watch(() => [
      charitiesCurrentPage.value,
      filterCauseIds.value,
    ], () => updateCharities())

    const updateCharitiesWithTimer = debounce(() => {
      updateCharities();
    }, 750);

    const updateCauseSelection = (addCauseId: Guid | null, removeCauseId: Guid | null) => {
      if (!helpers.isExists(props.channelInfo)) return

      if (addCauseId === channelCausesOptionId) {
        filterCauseIds.value = props.channelInfo.causeIds.slice()

        isChannelCausesMode.value = true
      } else {
        if (helpers.isNotEmpty(addCauseId)) {
          if (!helpers.anyInArray(filterCauseIds.value, x => x === addCauseId)) {
            filterCauseIds.value.push(addCauseId)
          }
        }
        if (helpers.isNotEmpty(removeCauseId)) {
          helpers.removeInArray(filterCauseIds.value, x => x === removeCauseId)
        }

        isChannelCausesMode.value = false
      }
    }

    const tryUpdateTelegramBotViewRoute = () => {
      if (!helpers.isNotEmpty(telegramMessageLinkId) || !helpers.isExists(props.channelInfo)) return

      telegramApi.openTelegramBotRoute(telegramMessageLinkId, TelegramBotRoute.CauseAndEarn_Channel_Edit,
        props.channelInfo.id, r => {

        }, errorMessage => {
          console.error(errorMessage)
        })
    }

    const selectCharityWithConfirm = (e: Event, charity: CharityInfo) => {
      e.stopPropagation()

      const charityId = charity.id

      if (!helpers.isExists(props.channelInfo) || !helpers.isNotEmpty(charityId) ||
        props.channelInfo.charityId === charityId) return

      showSuccessConfirmModal(confirmed => {
        if (confirmed) {
          if (!helpers.isExists(props.channelInfo)) return

          isButtonsDisabledLocal.value = true

          channelApi.changeCharity(props.channelInfo.id, charityId, r => {
            if (r.success) {
              props.channelInfo.charityId = charityId

              if (!store.state.context.isTelegramApp) {
                channelCharity.value = null
                closePage()
              } else {
                updateChannelCharity()
                updateCharities()

                // update telegram bot view
                tryUpdateTelegramBotViewRoute()
              }
            }

            isButtonsDisabledLocal.value = false
          }, errorMessage => {
            showErrorNotification(errorMessage)

            isButtonsDisabledLocal.value = false
          })
        }
      }, `Do you really want to connect to \"${charity.name}\"?`, "Connect to Charity")
    }

    const scrollToResultsStart = () => {
      document.getElementById('charity-results-start')?.scrollIntoView({
        behavior: 'smooth',
      });
    }

    watch(charitiesCurrentPage, () => scrollToResultsStart())

    watch(() => props.channelInfo.causeIds, () => {
      if (!isChannelCausesMode.value) return

      filterCauseIds.value = props.channelInfo.causeIds.slice()
    })

    // init
    //
    // set channel causes as initial
    updateCauseSelection(channelCausesOptionId, null)

    updateChannelCharity()
    updateCharities((success) => {
      if (success) {
        isLoadingLocal.value = false
      }
    })

    // lookup
    causeApi.list(result => charityCauses.value = result.causes, errorMessage => showErrorNotification(errorMessage))

    return {
      helpers,
      appUtils,

      isLoadingFinal,
      isButtonsDisabledFinal,

      channelCharity: channelCharity as unknown as CharityInfo,
      charityCauses: charityCauses as unknown as Causes,
      charityCauseOptions,
      charitiesCurrentPage,
      charities: charities as unknown as ListWithPagination<CharityInfo>,
      charitiesResultFiltered,
      filterSearchTerm,
      filterCauseIds,

      updateCauseSelection,
      selectCharityWithConfirm,
    }
  }
})
