
import {
  KnowledgeBasePublicArticle
} from '@/@models'
import ClientApiBase from "@/api/client-api-base";
import {ApiErrorCallback, ApiSuccessCallback} from "@/api/api-base";

export default new class extends ClientApiBase {
  getPublicArticle(publicId: string,
                   successCb: ApiSuccessCallback<KnowledgeBasePublicArticle>,
                   errorCb: ApiErrorCallback<KnowledgeBasePublicArticle>) {
    return this._defaultGetResponse('/knowledge-base/article', {
      params: {
        publicId
      }
    }, successCb, errorCb);
  }
}
