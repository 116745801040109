import ClientApiBase from "@/api/client-api-base";
import appUtils from "@/utils/app-utils";
import {ApiErrorCallback, ApiSuccessCallback} from "@/api/api-base";
import {PremiumInfo} from "@/@models";

export default new class extends ClientApiBase {
  getPremiumInfo(successCb: ApiSuccessCallback<PremiumInfo>,
       errorCb: ApiErrorCallback<PremiumInfo>) {
    return this._defaultPostResponse('/misc/get-premium-info', {
      telegramInitData: appUtils.getTelegramInitData()
    }, null, successCb, errorCb);
  }
}