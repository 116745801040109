import {EnumSelectOption, Guid} from "@/@models";

export enum AiAgentType {
  Undefined = 0,
  ChannelAutomatedAssistant = 1,
  ChannelLiveAssistant = 2,
}
export const AiAgentType_ALL: EnumSelectOption[] = [
  { label: 'Automated Assistant', id: AiAgentType.ChannelAutomatedAssistant },
  { label: 'Live Assistant', id: AiAgentType.ChannelLiveAssistant },
]

export interface AiAgentAdminTable {
  id: Guid

  type: AiAgentType
  name: string
}

export interface AiAgentLocalizationAdminEdit {
  localizationId: Guid
  name: string | null
}

export interface AiAgentAdminEdit {
  // update only
  id: Guid | null

  type: AiAgentType | null

  localizations: AiAgentLocalizationAdminEdit[]

  promptTemplate: string | null
}

export interface AiAgentLanguageAdminTable {
  id: Guid

  name: string
}

export interface AiAgentLanguageLocalizationAdminEdit {
  localizationId: Guid
  name: string | null
}

export interface AiAgentLanguageAdminEdit {
  // update only
  id: Guid | null

  localizations: AiAgentLanguageLocalizationAdminEdit[]

  promptValue: string | null
}

export interface AiAgentRoleAdminTable {
  id: Guid

  name: string
}

export interface AiAgentRoleLocalizationAdminEdit {
  localizationId: Guid
  name: string | null
}

export interface AiAgentRoleAdminEdit {
  // update only
  id: Guid | null

  localizations: AiAgentRoleLocalizationAdminEdit[]

  promptValue: string | null
}

export interface AiAgentStyleAdminTable {
  id: Guid

  name: string
}

export interface AiAgentStyleLocalizationAdminEdit {
  localizationId: Guid
  name: string | null
}

export interface AiAgentStyleAdminEdit {
  // update only
  id: Guid | null

  localizations: AiAgentStyleLocalizationAdminEdit[]

  promptValue: string | null
}