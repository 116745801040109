













































import {computed, defineComponent, onMounted, ref, watch} from "@vue/composition-api";
import useStore from "@/store";
import {
  DateTime, Guid, SystemStatus
} from "@/@models";
import useConfig from "@/utils/useConfig";
import useNotifications from "@/components/useNotifications";
import helpers from "@/utils/helpers";
import dayjs from "dayjs";
// @ts-ignore
import JsonViewer from "vue-json-viewer";
import miscPortalApi from "@/api/portal/misc-portal-api";

export default defineComponent({
  components: {
    JsonViewer
  },
  setup(props, context) {
    const config = useConfig()
    const { showErrorNotification } = useNotifications(context)

    const isButtonsDisabled = ref(false)
    const systemStatus = ref<SystemStatus | null>(null)

    const isStatusLoading = ref(false)
    const updateSystemStatus = () => {
      isButtonsDisabled.value = true
      isStatusLoading.value = true

      miscPortalApi.getSystemStatus(result => {
        systemStatus.value = result

        isButtonsDisabled.value = false
        isStatusLoading.value = false
      }, errorMessage => {
        showErrorNotification(errorMessage)

        isButtonsDisabled.value = false
        isStatusLoading.value = false
      })
    }

    // init
    //
    updateSystemStatus()

    return {
      config,
      helpers,

      isButtonsDisabled,
      isStatusLoading,

      systemStatus,

      updateSystemStatus,
    }
  }
})
