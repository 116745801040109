
import {
  Guid, Localizations, LocalizationStrings, SystemStatus
} from '@/@models'
import PortalApiBase from "@/api/portal-api-base";
import appUtils from "@/utils/app-utils";
import {ApiEmptyErrorCallback, ApiEmptySuccessCallback, ApiErrorCallback, ApiSuccessCallback} from "@/api/api-base";

export default new class extends PortalApiBase {
  getSystemStatus(successCb: ApiSuccessCallback<SystemStatus>,
                  errorCb: ApiErrorCallback<SystemStatus>) {
    return this._defaultPostResponse('/misc/system-status', {
      telegramInitData: appUtils.getTelegramInitData()
    }, null, successCb, errorCb);
  }
}
