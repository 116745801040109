




















































import {computed, defineComponent, nextTick, Ref, ref, watch} from "@vue/composition-api";
import useRouter from "@/router";
import useStore from "@/store";
import helpers from "@/utils/helpers";
import appUtils from "@/utils/app-utils";
import PageLoadingIndicator from "@/views/_components/PageLoadingIndicator.vue";
import useNotifications from "@/components/useNotifications";
import useSharedUtils from "@/utils/useSharedUtils";
import {ChannelInfo} from "@/@models";
import channelApi from "@/api/channel-api";
import ChannelCausesView from "@/views/channel/ChannelCausesView.vue";
import ChannelCharityView from "@/views/channel/ChannelCharityView.vue";

enum ChannelCausesAndCharityTab {
  Causes = 0,
  Charity = 1
}

export default defineComponent({
  components: {
    PageLoadingIndicator, ChannelCausesView, ChannelCharityView
  },
  setup(props, context) {
    const store = useStore()
    const router = useRouter()
    const {showErrorNotification} = useNotifications(context)
    const {closePage} = useSharedUtils()

    const type = router.currentRoute.query.type
    const channelPublicId = router.currentRoute.params.channelPublicId

    const isLoading = ref(true)

    const activeTab = ref<ChannelCausesAndCharityTab | null>(null)
    const channelInfo = ref<ChannelInfo | null>(null)

    const isCharityEnabled = computed(() =>
      helpers.isExists(channelInfo.value) && helpers.isNotEmptyArray(channelInfo.value.causeIds))

    const updateChannelInfo = (cb?: (success: boolean) => void) => {
      if (!helpers.isNotEmpty(channelPublicId)) return

      channelApi.getInfo(null, channelPublicId, result => {
        channelInfo.value = result

        if (helpers.isFunction(cb))
          cb(true)
      }, errorMessage => {
        showErrorNotification(errorMessage)

        if (helpers.isFunction(cb))
          cb(false)
      })
    }

    // init
    //
    updateChannelInfo(success => {
      if (success) {
        if (isCharityEnabled.value) {
          switch (type) {
            case "charity":
              activeTab.value = ChannelCausesAndCharityTab.Charity
              break
            case "causes":
            default:
              activeTab.value = ChannelCausesAndCharityTab.Causes
              break
          }
        } else {
          activeTab.value = ChannelCausesAndCharityTab.Causes
        }

        isLoading.value = false
      } else {
        closePage()
      }
    })

    return {
      helpers,
      appUtils,

      isLoading,

      activeTab,
      channelInfo: channelInfo as unknown as ChannelInfo,

      isCharityEnabled,

      updateChannelInfo,
    }
  }
})
