






import {computed, defineComponent, nextTick, watch} from "@vue/composition-api";
import useRouter from "@/router";
import {UiMutationTypes} from "@/store/modules/ui/types";
import useStore from "@/store";
import helpers from "@/utils/helpers";
import paymentApi from "@/api/payment-api";
import appUtils from "@/utils/app-utils";
import useNotifications from "@/components/useNotifications";
import useSharedUtils from "@/utils/useSharedUtils";
import {CallbackAction} from "@/views/system/Callback.vue";

export default defineComponent({
  setup(props, context) {
    const store = useStore()
    const router = useRouter()
    const {showErrorNotification} = useNotifications(context)
    const {closePage} = useSharedUtils()

    const channelPublicId = router.currentRoute.params.channelPublicId

    // init
    //
    store.commit(UiMutationTypes.UPDATE_GLOBAL_LOADING, true);

    const redirectReturnUrl = store.state.ui.isIframeMode
      ? appUtils.getRouterAbsoluteUrl({
        name: 'callback',
        params: {
          action: CallbackAction.Redirect
        },
        query: {
          url: store.getters.iframeReturnUrl
        }
      })
      : (helpers.isNotEmpty(channelPublicId)
        ? appUtils.getRouterAbsoluteUrl({
          name: 'channel-support',
          params: {
            channelPublicId: channelPublicId
          }
        })
        : appUtils.getRouterAbsoluteUrl({
          name: 'premium'
        }))

    paymentApi.paymentManagementPortalSession(channelPublicId, redirectReturnUrl, result => {
      if (helpers.isExists(result)) {
        if (helpers.isNotEmpty(result.redirectUrl)) {
          appUtils.redirectToAbsoluteUrl(result.redirectUrl, store.state.ui.isIframeMode)
          return;
        }
      }

      closePage();
    }, errorMessage => {
      showErrorNotification(errorMessage)

      closePage();
    })

    return {
    }
  }
})
