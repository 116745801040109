
import {
  Guid, CharitiesSearchResult, CharityInfo
} from '@/@models'
import ClientApiBase from "@/api/client-api-base";
import appUtils from "@/utils/app-utils";
import {ApiErrorCallback, ApiSuccessCallback} from "@/api/api-base";

export default new class extends ClientApiBase {
  get(id: Guid,
      successCb: ApiSuccessCallback<CharityInfo | null>,
      errorCb: ApiErrorCallback<CharityInfo | null>) {
    return this._defaultPostResponse('/charities/get', {
      id, telegramInitData: appUtils.getTelegramInitData()
    }, null, successCb, errorCb);
  }

  search(searchTerm: string | null, onlyWithCauseIds: Guid[] | null, page: number, forceIncludeCharityId: Guid | null,
         successCb: ApiSuccessCallback<CharitiesSearchResult>,
         errorCb: ApiErrorCallback<CharitiesSearchResult>) {
    return this._defaultPostResponse('/charities/search', {
      searchTerm, onlyWithCauseIds, forceIncludeCharityId, page, telegramInitData: appUtils.getTelegramInitData()
    }, null, successCb, errorCb);
  }
}