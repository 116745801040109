



















































































































































































































































































































































import {
  computed,
  defineComponent,
  nextTick,
  onBeforeUnmount,
  onMounted,
  onUnmounted,
  ref,
  watch
} from "@vue/composition-api";
import useRouter from "@/router";
import useStore from "@/store";
import helpers from "@/utils/helpers";
import paymentApi from "@/api/payment-api";
import appUtils from "@/utils/app-utils";
import sanitizeHtml from 'sanitize-html';
import {ChannelSupportPage} from "@/@models";
import PageLoadingIndicator from "@/views/_components/PageLoadingIndicator.vue";
import VideoPlayer from "@/views/_components/VideoPlayer.vue";
import useNotifications from "@/components/useNotifications";
import VueShowMoreText from "@/views/_components/VueShowMoreText.vue";
import CharityInfoView from "@/views/channel/_partials/CharityInfoView.vue";
import useSharedUtils from "@/utils/useSharedUtils";
import ChannelAutomatedAssistantAiAgentView from "@/views/ai-agent/ChannelAutomatedAssistantAiAgentView.vue";
import ChannelLiveAssistantAiAgentView from "@/views/ai-agent/ChannelLiveAssistantAiAgentView.vue";

enum PaymentTypeTab {
  Monthly = 0,
  OneTime = 1
}

export default defineComponent({
  components: {
    ChannelLiveAssistantAiAgentView, ChannelAutomatedAssistantAiAgentView,
    CharityInfoView,
    VideoPlayer,
    VueShowMoreText,
    PageLoadingIndicator
  },
  setup(props, context) {
    const store = useStore()
    const router = useRouter()
    const {showErrorNotification} = useNotifications(context)
    const {closePage} = useSharedUtils()

    const channelPublicId = router.currentRoute.params.channelPublicId

    const activeTab = ref<PaymentTypeTab | null>(null)

    const model = ref<ChannelSupportPage | null>(null)
    const isShowFullChannelMission = ref(false)

    const isIframeModeEnabled = computed(() => store.state.ui.isIframeMode ?? false)

    const isMonthlySupportExists = computed(() => helpers.isExists(model.value) &&
      helpers.isNotEmptyArray(model.value.subscriptionGroups))
    const isOneTimeSupportExists = computed(() => {
      if (!helpers.isExists(model.value)) return false

      return helpers.isExists(model.value.mainOneTime) ||
        (helpers.isNotEmptyArray(model.value.oneTimeGroups) &&
          helpers.anyInArray(model.value.oneTimeGroups, x => helpers.isNotEmptyArray(x.oneTimes)))
    })

    const channelMissionSanitizedHtml = computed(() => helpers.isExists(model.value)
      ? sanitizeHtml(model.value!.channelMission ?? '', {
        allowedTags: sanitizeHtml.defaults.allowedTags.concat([ 'img' ]),
        allowedAttributes: Object.assign({}, sanitizeHtml.defaults.allowedAttributes, {
          img: [ '*', 'src' ]
        })
      })
      : '');

    const openChannelDetailsPopup = () => {
      context.root.$bvModal.show("channel-details-modal")
    }

    const openCharityDetailsPopup = () => {
      context.root.$bvModal.show("charity-details-modal")
    }

    // init
    //
    paymentApi.channelSupportPage(channelPublicId, result => {
      if (helpers.isExists(result)) {
        model.value = result

        activeTab.value = isMonthlySupportExists.value ? PaymentTypeTab.Monthly : PaymentTypeTab.OneTime
      } else {
        closePage()
      }
    }, errorMessage => {
      showErrorNotification(errorMessage)

      closePage()
    })

    return {
      helpers,
      appUtils,

      channelPublicId,

      activeTab,

      isIframeModeEnabled,
      model: model as unknown as ChannelSupportPage,
      isShowFullChannelMission,
      isMonthlySupportExists,
      isOneTimeSupportExists,
      channelMissionSanitizedHtml,

      openChannelDetailsPopup,
      openCharityDetailsPopup,
    }
  }
})
