
import HttpClient from '@/components/http-client'
import helpers from '@/utils/helpers'
import {AxiosError, AxiosRequestConfig, AxiosResponse} from "axios";
import {ApiResponse} from "@/@models";
import fileUtils from "@/utils/file-utils";

type Exact<TExpected, TActual extends TExpected> = TExpected extends TActual ? TExpected: never;

export type ApiEmptySuccessCallback = (apiResponse: ApiResponse) => void
export type ApiEmptyErrorCallback = (errorMessage: string | null, apiResponse: ApiResponse | null) => void

export type ApiSuccessCallback<TResult> = (result: TResult, apiResponse: ApiResponse<TResult>) => void
export type ApiErrorCallback<TResult> = (errorMessage: string | null, apiResponse: ApiResponse<TResult> | null) => void

export type ApiFileSuccessCallback = (() => void) | null
export type ApiFileErrorCallback = (errorMessage?: string) => void

const getErrorMessageFromApiResponse = (data?: ApiResponse | null, errorMessageFallback = 'Unknown error'): string => {
  if (!helpers.isExists(data) || !helpers.isExists(data.validationResult)) return errorMessageFallback

  let errorMessages: string[] = []
  for (const item of data.validationResult.items) {
    if (helpers.isNotEmpty(item.details)) {
      errorMessages.push(item.details)
    } else {
      errorMessages.push(`[${item.code}]`)
    }
  }

  return errorMessages.join("\n")
}

function processResponse<TResult>(url: string,
                                  response: AxiosResponse<ApiResponse<TResult>> | undefined,
                                  successCb: ApiSuccessCallback<TResult> | null,
                                  successEmptyCb: ApiEmptySuccessCallback | null,
                                  errorCb: ApiErrorCallback<TResult>) {
  // only works after axios.validateStatus changed
  if (helpers.isExists(response)) {
    switch (response.status) {
      case 200: // Ok
        if (helpers.isFunction(successCb) || helpers.isFunction(successEmptyCb)) {
          if (helpers.isObject(response.data) && helpers.isBoolean(response.data.success)) {
            if (response.data.success) {
              if (helpers.isFunction(successEmptyCb)) {
                successEmptyCb(response.data);
              } else if (helpers.isFunction(successCb)) {
                successCb(response.data.result ?? <any>null, response.data);
              } else {
                throw new Error("Success callback is not specified")
              }
            } else {
              if (helpers.isFunction(errorCb))
                errorCb(getErrorMessageFromApiResponse(response.data), response.data);
            }
          } else {
            if (helpers.isFunction(errorCb))
              errorCb(getErrorMessageFromApiResponse(response.data, 'Server\'s response data is invalid'), response.data);
          }
        }
        break;
      case 401: // Unauthorized
        if (helpers.isFunction(errorCb))
          errorCb(getErrorMessageFromApiResponse(response.data, 'Unauthorized access'), response.data);
        break;
      case 404: // NotFound
        console.error("Not Found Server Error", response);

        if (helpers.isFunction(errorCb))
          errorCb(getErrorMessageFromApiResponse(response.data, "Not Found"), response.data);
        break;
      default: // Unknown
        console.error("Response Server Error", response);

        if (helpers.isFunction(errorCb))
          errorCb(getErrorMessageFromApiResponse(response.data), response.data);
        break;
    }
  } else {
    if (helpers.isFunction(errorCb))
      errorCb('Server is unavailable, please try again later', null);
  }
}

function processErrorResponse<TResult>(url: string,
                                       errorCtx: AxiosError<ApiResponse<TResult>>,
                                       errorCb: ApiErrorCallback<TResult> | ApiEmptyErrorCallback) {
  if (process.env.NODE_ENV === 'development') {
    if (errorCtx.code === "ERR_NETWORK" && helpers.stringContains(errorCtx.config?.baseURL, ".loca.lt", true)) {
      alert("Tunnel Error, check Main API URL (set password etc.)")
    }
  }

  // handle all other cases
  if (helpers.isExists(errorCtx) && helpers.isExists(errorCtx.response)) {
    if (helpers.isFunction(errorCb)) {
      if (errorCtx.response.status === 503) {
        errorCb(getErrorMessageFromApiResponse(errorCtx.response.data, 'Server is unavailable, please try again later'), errorCtx.response.data);
      } else {
        errorCb(getErrorMessageFromApiResponse(errorCtx.response.data), errorCtx.response.data);
      }
    }
  } else {
    if (helpers.isFunction(errorCb))
      errorCb(getErrorMessageFromApiResponse(errorCtx.response?.data, 'Server is unavailable, please try again later'), errorCtx.response?.data ?? null);

    throw errorCtx;
  }
}

export default class _ApiBase {
  protected getFullUrl(apiUrl: string): string {
    throw new Error("Method getFullUrl is not implemented");
  }

  protected _defaultGetResponse<
    TSuccess extends ApiSuccessCallback<TResult>,
    TError extends ApiErrorCallback<TResult>,
    TResult>(apiUrl: string, getOptions: AxiosRequestConfig | null,
             successCb: Exact<ApiSuccessCallback<TResult>, TSuccess>,
             errorCb: Exact<ApiErrorCallback<TResult>, TError>) {
    return this._systemDefaultGetResponse(apiUrl, getOptions, successCb, errorCb, false)
  }
  protected _defaultEmptyGetResponse<
    TSuccess extends ApiEmptySuccessCallback,
    TError extends ApiEmptyErrorCallback>(apiUrl: string, getOptions: AxiosRequestConfig | null,
             successCb: Exact<ApiEmptySuccessCallback, TSuccess>,
             errorCb: Exact<ApiEmptyErrorCallback, TError>) {
    return this._systemDefaultGetResponse(apiUrl, getOptions, successCb, errorCb, true)
  }

  private _systemDefaultGetResponse<TResult>(apiUrl: string, getOptions: AxiosRequestConfig | null,
                                             successCb: ApiSuccessCallback<TResult> | ApiEmptySuccessCallback,
                                             errorCb: ApiErrorCallback<TResult> | ApiEmptyErrorCallback,
                                             isEmptyCallbacks: boolean) {
    const fullApiUrl = this.getFullUrl(apiUrl);

    return HttpClient
      .get<ApiResponse<TResult>>(fullApiUrl, getOptions as AxiosRequestConfig | undefined)
      .then(response => processResponse(fullApiUrl, response,
        isEmptyCallbacks ? null : successCb as ApiSuccessCallback<TResult>,
        isEmptyCallbacks ? successCb as ApiEmptySuccessCallback : null,
        errorCb))
      // If error notify
      .catch((errorCtx) => processErrorResponse(fullApiUrl, errorCtx, errorCb));
  }

  protected _defaultPostResponse<
    TSuccess extends ApiSuccessCallback<TResult>,
    TError extends ApiErrorCallback<TResult>,
    TResult>(apiUrl: string, data: any, postOptions: AxiosRequestConfig | null,
             successCb: Exact<ApiSuccessCallback<TResult>, TSuccess>,
             errorCb: Exact<ApiErrorCallback<TResult>, TError>) {
    return this._systemDefaultPostResponse(apiUrl, data, postOptions, successCb, errorCb, false)
  }
  protected _defaultEmptyPostResponse<
    TSuccess extends ApiEmptySuccessCallback,
    TError extends ApiEmptyErrorCallback>(apiUrl: string, data: any, postOptions: AxiosRequestConfig | null,
                                          successCb: Exact<ApiEmptySuccessCallback, TSuccess>,
                                          errorCb: Exact<ApiEmptyErrorCallback, TError>) {
    return this._systemDefaultPostResponse(apiUrl, data, postOptions, successCb, errorCb, true)
  }

  private _systemDefaultPostResponse<TResult>(apiUrl: string, data: any, postOptions: AxiosRequestConfig | null,
                                              successCb: ApiSuccessCallback<TResult> | ApiEmptySuccessCallback,
                                              errorCb: ApiErrorCallback<TResult> | ApiEmptyErrorCallback,
                                              isEmptyCallbacks: boolean) {
    const fullApiUrl = this.getFullUrl(apiUrl);

    return HttpClient
      .post<ApiResponse<TResult>>(fullApiUrl, data, postOptions as AxiosRequestConfig | undefined)
      .then(response => processResponse(fullApiUrl, response,
        isEmptyCallbacks ? null : successCb as ApiSuccessCallback<TResult>,
        isEmptyCallbacks ? successCb as ApiEmptySuccessCallback : null,
        errorCb))
      // If error notify
      .catch((errorCtx) => processErrorResponse(fullApiUrl, errorCtx, errorCb));
  }

  protected _defaultGetFileResponse(apiUrl: string, fileName: string, getOptions: AxiosRequestConfig | null,
                                    successCb: ApiFileSuccessCallback,
                                    errorCb: ApiFileErrorCallback) {
    const fullApiUrl = this.getFullUrl(apiUrl);

    // override response type to blob
    if (helpers.isExists(getOptions)) {
      getOptions!.responseType = "blob"
    } else {
      getOptions = {
        responseType: "blob"
      }
    }
    return HttpClient
      .get(fullApiUrl, getOptions as AxiosRequestConfig | undefined)
      .then(response => {
        if (!helpers.isExists(response)) throw Error("response is null")

        fileUtils.openDownloadFileRequest(fileName, response!.data)

        if (helpers.isFunction(successCb))
          successCb!()
      })
      // If error notify
      .catch((errorCtx) => {
        console.error(errorCtx)
        errorCb("Unknown error occurred")
      });
  }

  protected _defaultPostFileResponse(apiUrl: string, data: any, fileName: string, postOptions: AxiosRequestConfig | null,
                                     successCb: ApiFileSuccessCallback,
                                     errorCb: ApiFileErrorCallback) {
    const fullApiUrl = this.getFullUrl(apiUrl);

    // override response type to blob
    if (helpers.isExists(postOptions)) {
      postOptions!.responseType = "blob"
    } else {
      postOptions = {
        responseType: "blob"
      }
    }

    return HttpClient
      .post(fullApiUrl, data, postOptions as AxiosRequestConfig | undefined)
      .then(response => {
        if (!helpers.isExists(response)) throw Error("response is null")

        fileUtils.openDownloadFileRequest(fileName, response!.data)

        if (helpers.isFunction(successCb))
          successCb!()
      })
      // If error notify
      .catch((errorCtx) => {
        console.error(errorCtx)
        errorCb("Unknown error occurred")
      });
  }
}