
























import {computed, defineComponent, PropType} from "@vue/composition-api";
import { ValidationProvider } from "vee-validate";
import helpers from "@/utils/helpers";

// todo check mode="lazy"
export default defineComponent({
  inheritAttrs: false,
  components: {
    ValidationProvider
  },
  props: {
    if: { type: Boolean, default: true },
    inputId: String,
    inputClass: String,
    labelText: String,
    rules: [String, Object],
    inverted: Boolean,
    value: Boolean
  },
  setup(props, { emit }) {
    // validation
    if (!helpers.isNotEmpty(props.inputId)) {
      console.error(`inputId is empty or undefined - Checkbox`);
    }

    const innerValue = computed({
      get() {
        return props.value;
      },
      set(val) {
        emit('input', val);
      }
    });

    const getInputState = (attrs: any, dirty: boolean, validated: boolean, valid: boolean): boolean | null => {
      if (!helpers.isExists(props.rules)) return null

      if (helpers.isExists(attrs.state)) {
        return attrs.state;
      }
      return dirty || validated ? valid : null;
    }

    return {
      helpers: helpers,

      innerValue,
      getInputState
    }
  },
})
