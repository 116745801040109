import mitt, {Emitter} from 'mitt';

export enum EmitterEvent {
  Logout = "Logout",
  AfterWindowResizeCompleted = "AfterWindowResizeCompleted"
}

export const emitter = mitt<Record<string, any>>();

export default function useEmitter() {
  return emitter;
}
