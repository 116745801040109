import { ActionTree, ActionContext } from 'vuex';

import {_AugmentedActionContext, RootState} from '@/store';

import { State } from './state';
import { Mutations } from './mutations';
import {Getters} from "./getters";

type AugmentedActionContext = _AugmentedActionContext<State, Actions, Mutations, Getters>;

export interface Actions {
}

export const actions: ActionTree<State, RootState> & Actions = {
};