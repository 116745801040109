
import helpers from "@/utils/helpers";

export default function () {
  const getDefaultFormGroupClass = (rules: any) => {
    let additionalClasses = "";
    let isRequiredRuleExists = checkIfContainRule(rules, "required").success;

    if (isRequiredRuleExists) {
      additionalClasses += ' label-required-asterisk';
    }

    return additionalClasses;
  }

  const checkIfContainRule = (rules: any, rule: string): {
    success: boolean, params?: string[] | null
  } => {
    if (helpers.isString(rules) && helpers.isNotEmpty(rules)) {
      const parts = rules.split('|')
      const ruleString = helpers.findFirstInArray(parts, x => x.split(':')[0] === rule);

      if (helpers.isNotEmpty(ruleString)) {
        const paramParts = ruleString.split(':')
        return {
          success: true,
          params: paramParts.length > 1 ? paramParts[1].split(',') : []
        }
      }
    }

    return {
      success: false
    }
  }

  return {
    getDefaultFormGroupClass,
    checkIfContainRule,
  }
}
