import { GetterTree } from 'vuex';

import { RootState } from '@/store';

import { State } from './state';

export type Getters = {
  iframeReturnUrl(state: State): string | null
}

export const getters: GetterTree<State, RootState> & Getters = {
  iframeReturnUrl(state) {
    return state.isIframeMode ? state.iframeSettings?.returnUrl ?? document.referrer : null
  }
};
