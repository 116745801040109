import {nextTick, SetupContext} from "@vue/composition-api";
import Helpers from "@/utils/helpers";
import Vue, {VueConstructor} from "vue";
import {Guid, KnowledgeBaseArticleType} from "@/@models";

export default function useModals(context: SetupContext<any>) {
  const getBvModal = () => context.root.$bvModal;
  const h = context.root.$createElement

  const showOkModal = async (okVariant: string, htmlMessage: string, title?: string | null, okTitle?: string | null) => {
    const vNodeMessage = h(
      'div',
      {
        domProps: { innerHTML: htmlMessage }
      }
    )

    getBvModal().msgBoxOk([vNodeMessage], {
      title: title ?? undefined,
      size: 'sm',
      okVariant: okVariant ?? undefined,
      okTitle: okTitle ?? "OK",
      footerClass: 'p-2',
      hideHeaderClose: false,
      centered: true
    })
      .catch(err => {
        // An error occurred
      })
  }

  const showConfirmModal = async (okVariant: string, cb: (confirmed: boolean) => void, htmlMessage?: string,
                                  title?: string | null, okTitle?: string | null, cancelTitle?: string | null,
                                  popupSize?: string | null) => {
    const vNodeMessage = h(
      'div',
      {
        domProps: { innerHTML: htmlMessage ?? "Do you really want to do this?" }
      }
    )

    getBvModal().msgBoxConfirm([vNodeMessage], {
      title: title ?? undefined,
      size: popupSize ?? 'sm',
      okVariant: okVariant ?? undefined,
      okTitle: okTitle ?? "Yes",
      cancelTitle: cancelTitle ?? "Cancel",
      footerClass: 'p-2 d-flex justify-content-between align-items-center',
      hideHeaderClose: false,
      centered: true
    })
      .then(value => {
        cb(value);
      })
      .catch(err => {
        // An error occurred
      })
  }

  const showWarningOkModal = (htmlMessage: string, title?: string, okTitle?: string) => {
    return showOkModal('warning', htmlMessage, title, okTitle);
  };

  const showSuccessConfirmModal = (cb: (confirmed: boolean) => void, htmlMessage?: string, title?: string | null,
                                   okTitle?: string | null, cancelTitle?: string | null) => {
    return showConfirmModal('success', cb, htmlMessage, title, okTitle, cancelTitle);
  };

  const showWarningConfirmModal = (cb: (confirmed: boolean) => void, htmlMessage?: string, title?: string | null,
                                  okTitle?: string | null, cancelTitle?: string | null, popupSize?: string | null) => {
    return showConfirmModal('warning', cb, htmlMessage, title, okTitle, cancelTitle, popupSize);
  };

  const showDangerConfirmModal = (cb: (confirmed: boolean) => void, htmlMessage?: string, title?: string | null,
                                  okTitle?: string | null, cancelTitle?: string | null, popupSize?: string | null) => {
    return showConfirmModal('danger', cb, htmlMessage, title, okTitle, cancelTitle, popupSize);
  };

  const showPopup = (rootInst: InstanceType<VueConstructor>, popupKey: string, popupClass: any, ...popupArguments: any[]) => {
    if (!Helpers.isExists(rootInst)) throw new Error("rootInst is required");
    if (!Helpers.isExists(popupClass)) throw new Error("popupClass is required");

    // for typescript
    let rootInstObj: any = rootInst;

    if (!Helpers.isExists(rootInstObj[popupKey])) {
      const componentClass = Vue.extend(popupClass);
      const instance = new componentClass();
      instance.$mount(); // pass nothing
      document.body.appendChild(instance.$el);
      rootInstObj[popupKey] = instance;
    }

    nextTick(() => {
      if (Helpers.isExists(popupArguments)) {
        rootInstObj[popupKey].openPopup(...popupArguments!);
      } else {
        rootInstObj[popupKey].openPopup();
      }
    })
  }

  const showEditKnowledgeBaseArticlePopup = (id: Guid | null, publicId: string | null, isCreate: boolean,
                                             createType: KnowledgeBaseArticleType | null, createInitialContent: string | null,
                                             createOrUpdatedCb?: (isCreate: boolean, id: Guid, publicId: string) => void) => {
    const EditKnowledgeBaseArticlePopup = require("@/views/admin/_popups/EditKnowledgeBaseArticlePopup.vue")

    // @ts-ignore
    return showPopup(window.Vue, "_EditKnowledgeBaseArticlePopup", EditKnowledgeBaseArticlePopup.default, id,
      publicId, isCreate, createType, createInitialContent, createOrUpdatedCb);
  }

  return {
    showWarningOkModal,

    showSuccessConfirmModal,
    showWarningConfirmModal,
    showDangerConfirmModal,

    showPopup,
    showEditKnowledgeBaseArticlePopup,
  };
}
