export interface IframeSettings {
  returnUrl: string | null
  isDarkColorSchemeForced: boolean | null
  isLightColorSchemeForced: boolean | null
  textColor: string | null
  backgroundColor: string | null
  injectStyleUrl: string | null
}

export type State = {
  isGlobalLoading: boolean

  isDarkColorScheme: boolean | null
  windowWidth: number | null
  windowScrollWidth: number | null
  windowHeight: number | null
  windowScrollHeight: number | null

  withFooter: boolean | null

  isIframeMode: boolean | null
  iframeSettings: IframeSettings | null

  isPageFullscreenMode: boolean | null
}

export const state: State = {
  isGlobalLoading: true,

  isDarkColorScheme: null,
  windowWidth: null,
  windowScrollWidth: null,
  windowHeight: null,
  windowScrollHeight: null,

  withFooter: null,

  isIframeMode: null,
  iframeSettings: null,

  isPageFullscreenMode: null,
};
