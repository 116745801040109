import { MutationTree } from 'vuex';

import { State } from './state';
import { ContextMutationTypes } from './types';
import Helpers from "@/utils/helpers";

export interface TelegramAppContext {
  isTelegramApp: boolean
  isTelegramAppAuth: boolean
  isTelegramAppThemeEnabled: boolean
  isTelegramAppDarkTheme: boolean
  telegramAppBgColorHex: string | null
}

export type Mutations<S = State> = {
  [ContextMutationTypes.SET_TELEGRAM_APP_CONTEXT](state: S, payload: TelegramAppContext | null | undefined): void;
}

export const mutations: MutationTree<State> & Mutations = {
  [ContextMutationTypes.SET_TELEGRAM_APP_CONTEXT](state, context) {
    if (Helpers.isExists(context)) {
      state.isTelegramApp = context!.isTelegramApp
      state.isTelegramAppAuth = context!.isTelegramAppAuth
      state.isTelegramAppThemeEnabled = context!.isTelegramAppThemeEnabled
      state.isTelegramAppDarkTheme = context!.isTelegramAppDarkTheme
      state.telegramAppBgColorHex = context!.telegramAppBgColorHex
    } else {
      state.isTelegramApp = false
      state.isTelegramAppAuth = false
      state.isTelegramAppThemeEnabled = false
      state.isTelegramAppDarkTheme = null
      state.telegramAppBgColorHex = null
    }
  }
};
