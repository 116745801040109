








































import {computed, defineComponent, nextTick, onMounted, PropType, watch} from "@vue/composition-api";
import {ValidationProvider} from "vee-validate";
import helpers from "@/utils/helpers";
import useControlUtils from "@/utils/useControlUtils";
import {FileMetatype, FileType} from "@/@models";

// quill
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "@/assets/scss/components/quill/quill-better-table.scss";
import Quill from 'quill';
//@ts-ignore
import ImageResize from 'quill-image-resize';
import htmlEditButton from "quill-html-edit-button";
//@ts-ignore
import QuillBetterTable from 'quill-better-table'
import useNotifications from "@/components/useNotifications";
import fileUtils from "@/utils/file-utils";
import fileSharedApi from "@/api/shared/file-shared-api";

let BRichTextFormGroup_ComponentId = 1

export default defineComponent({
  inheritAttrs: false,
  components: {
    ValidationProvider
  },
  props: {
    if: { type: Boolean, default: true },
    inputId: String,
    inputClass: String,
    disabled: Boolean,
    displayName: { type: String, required: true },
    placeholderOverride: String,
    description: String,
    rules: [String, Object],
    withoutLabel: { type: Boolean, default: false },
    value: String,
    fileUploadEnabled: Boolean,
    fileMetatype: Number as PropType<FileMetatype>,
  },
  setup(props, context) {
    const { getDefaultFormGroupClass } = useControlUtils()
    const {showSuccessNotification, showErrorNotification} = useNotifications(context);

    // validation
    if (!helpers.isNotEmpty(props.inputId)) {
      console.error(`inputId is empty or undefined - DisplayName: ${props.displayName}`);
    }
    if (props.fileUploadEnabled && !helpers.isNumeric(props.fileMetatype)) {
      console.error(`If file upload enabled then fileMetatype prop is required`);
    }

    // before create
    const componentId = BRichTextFormGroup_ComponentId;
    BRichTextFormGroup_ComponentId += 1;

    const componentRef = computed(() => 'BRichTextFormGroup_' + componentId);

    const additionalFormGroupClass = computed(() => getDefaultFormGroupClass(props.rules));

    const getInputStateClass = (attrs: any, dirty: boolean, validated: boolean, valid: boolean): string => {
      let isValid: boolean | null = null;

      if (helpers.isExists(attrs.state)) {
        isValid = attrs.state;
      }
      else {
        isValid = dirty || validated ? valid : null;
      }

      if (isValid === null) return '';

      return isValid ? 'is-valid' : 'is-invalid';
    }

    const getValidationProvider = () => {
      return context.refs[componentRef.value] as InstanceType<typeof ValidationProvider>;
    }

    let editor: Quill | null = null;

    const initEditor = () => {
      initQuillImageResizeModule();

      Quill.register("modules/htmlEditButton", htmlEditButton, true);
      Quill.register({
        'modules/better-table': QuillBetterTable
      }, true);

      const Size = Quill.import('attributors/style/size');
      Size.whitelist = ['12px', '14px', '16px', '18px'];
      Quill.register(Size, true);

      let icons = Quill.import("ui/icons");
      icons["undo"] = "<svg viewbox=\"0 0 18 18\"><polygon class=\"ql-fill ql-stroke\" points=\"6 10 4 12 2 10 6 10\"></polygon><path class=\"ql-stroke\" d=\"M8.09,13.91A4.6,4.6,0,0,0,9,14,5,5,0,1,0,4,9\"></path></svg>";
      icons["redo"] = "<svg viewbox=\"0 0 18 18\"><polygon class=\"ql-fill ql-stroke\" points=\"12 10 14 12 16 10 12 10\"></polygon><path class=\"ql-stroke\" d=\"M9.91,13.91A4.6,4.6,0,0,1,9,14a5,5,0,1,1,5-5\"></path></svg>";

      let additionalButtonsRow = ['link', 'code'];

      if (props.fileUploadEnabled) {
        additionalButtonsRow.push('image')
      }

      const editorOptions = {
        placeholder: helpers.isNotEmpty(props.placeholderOverride) ? props.placeholderOverride : props.displayName,
        readOnly: false,
        theme: "snow",
        modules: {
          toolbar: {
            container: [
              ['clean'],

              ['bold', 'italic', 'underline'],
              ['blockquote'],

              [{ 'list': 'ordered'}, { 'list': 'bullet' }],
              [{ 'script': 'sub'}, { 'script': 'super' }],
              [{ 'indent': '-1'}, { 'indent': '+1' }],
              [{ 'align': [] }],

              [{ 'size': ['12px', '14px', '16px', '18px'] }],
              [{ 'header': [1, 2, 3, 4, 5, 6, false] }],

              [{ 'color': [] }, { 'background': [] }],
              [{ 'font': [] }], //'Calibri', 'Times New Roman'

              additionalButtonsRow,
              ['table'],

              ['undo', 'redo']
            ],
            handlers: {
              image() {
                if (!props.fileUploadEnabled || !helpers.isNumeric(props.fileMetatype)) return

                const input = document.createElement('input');
                input.setAttribute('type', 'file');
                input.click();

                // Once file is selected.
                input.onchange = () => {
                  if (!helpers.isExists(editor) || !helpers.isExists(input.files) || input.files!.length === 0) return;

                  const file = input.files![0];

                  // Validate file type is an image.
                  if (/^image\//.test(file.type)) {
                    fileSharedApi.upload(FileType.Image, props.fileMetatype!, null, file, null, result => {
                      // Get the current cursor position.
                      const range = editor!.getSelection();

                      if (helpers.isExists(range)) {
                        // Insert the image via URL where the cursor is.
                        editor!.insertEmbed(range!.index, 'image', fileUtils.imageUrl(result!.id));

                        // Move the cursor past the image.
                        editor!.setSelection(range!.index + <any>1);
                      }
                    }, errorMessage => {
                      showErrorNotification(errorMessage);
                    })
                  } else {
                    console.warn('You could only upload images.');
                  }
                };
              },
              table() {
                if (!helpers.isExists(editor)) return;

                let tableModule = editor!.getModule('better-table');

                if (helpers.isExists(tableModule)) {
                  tableModule.insertTable(3, 3);
                }
              },
              redo() {
                if (!helpers.isExists(editor)) return;

                // @ts-ignore
                editor!.history.redo();
              },
              undo() {
                if (!helpers.isExists(editor)) return;

                // @ts-ignore
                editor!.history.undo();
              }
            }
          },
          history: {},
          clipboard: {},
          imageResize: {},
          htmlEditButton: {},

          table: false,  // disable table module
          'better-table': {
            operationMenu: {
              items: {}
            }
          },
          keyboard: {
            bindings: QuillBetterTable.keyboardBindings
          }
        }
      };

      nextTick(() => {
        // @ts-ignore
        editor = new Quill(context.refs.quillEditor, editorOptions);

        const initialValue = props.value ?? '';
        setEditorHtml(initialValue);

        editor.enable(!props.disabled)

        let prevRequiredCheck = helpers.isNotEmpty(initialValue)
        editor.on('text-change', (delta: any, oldDelta: any, source: any) => {
          const currentValue = getEditorHtml();

          if (prevRequiredCheck != helpers.isNotEmpty(currentValue)) {
            const vp = getValidationProvider();

            vp.syncValue(currentValue);
            vp.setFlags({ dirty: true, pristine: false });
            vp.validate(currentValue);
            getValidationProvider().setFlags({ touched: true, untouched: false });
          }

          context.emit('input', currentValue)
        })
      });
    }

    const initQuillImageResizeModule = () => {
      const ImageFormatAttributesList = [
        'alt',
        'height',
        'width',
        'style'
      ];

      let ImageFormat = Quill.import('formats/image');

      ImageFormat.formats = function formats(domNode: any): any {
        return ImageFormatAttributesList.reduce(function(formats, attribute) {
          if (domNode.hasAttribute(attribute)) {
            // @ts-ignore
            formats[attribute] = domNode.getAttribute(attribute);
          }
          return formats;
        }, {});
      };

      ImageFormat.prototype.format = function format(name: string, value: any): void {
        if (ImageFormatAttributesList.indexOf(name) > -1) {
          if (value) {
            this.domNode.setAttribute(name, value);
          } else {
            this.domNode.removeAttribute(name);
          }
        } else {
          this.super.format(name, value);
        }
      };

      Quill.register(ImageFormat, true);
      Quill.register('modules/imageResize', ImageResize, true);
    }

    const getEditorHtml = () => {
      if (!helpers.isExists(editor))
        throw new Error("editor is null");

      const tempCont = document.createElement('div');
      const tempEditor = new Quill(tempCont);
      tempEditor.setContents(editor!.getContents());
      return '' + tempEditor.root.innerHTML;
    }

    const setEditorHtml = (html: string) => {
      if (!helpers.isExists(editor))
        throw new Error("editor is null");

      if (helpers.isNotEmpty(html)) {
        editor!.clipboard!.dangerouslyPasteHTML(html);
      }
    }

    watch(() => props.disabled, (val) => editor?.enable(!val));

    onMounted(() => {
      initEditor()

      // set initial value
      const vp = getValidationProvider();
      vp.syncValue(props.value);
    })

    return {
      helpers: helpers,
      componentRef,

      additionalFormGroupClass,
      getInputStateClass,
    }
  },
})
