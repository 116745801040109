












import {computed, defineComponent, PropType} from "@vue/composition-api";
import helpers from "@/utils/helpers";
import {LocalizationInfo} from "@/@models";

export default defineComponent({
  props: {
    selectedLocalization: Object as PropType<LocalizationInfo>,
  },
  setup(props, context) {
    return {
      helpers,
    }
  },
})
