export enum UiActionTypes {
}

export enum UiMutationTypes {
  UPDATE_GLOBAL_LOADING = 'UPDATE_GLOBAL_LOADING',
  UPDATE_DARK_COLOR_SCHEME = 'UPDATE_DARK_COLOR_SCHEME',
  UPDATE_WINDOW_SIZE = 'UPDATE_WINDOW_SIZE',
  UPDATE_WITH_FOOTER = 'UPDATE_WITH_FOOTER',
  UPDATE_IFRAME_MODE = 'UPDATE_IFRAME_MODE',
  UPDATE_IFRAME_SETTINGS = 'UPDATE_IFRAME_SETTINGS',
  UPDATE_PAGE_FULLSCREEN_MODE = 'UPDATE_PAGE_FULLSCREEN_MODE',
}