
import {
  Guid,
  ContentSources
} from '@/@models'
import ClientApiBase from "@/api/client-api-base";
import appUtils from "@/utils/app-utils";
import {ApiEmptyErrorCallback, ApiEmptySuccessCallback, ApiErrorCallback, ApiSuccessCallback} from "@/api/api-base";
import helpers from "@/utils/helpers";

export default new class extends ClientApiBase {
  contentSources(channelId: Guid, successCb: ApiSuccessCallback<ContentSources>,
                 errorCb: ApiErrorCallback<ContentSources>,
                 onlyIds: Guid[] | null = null) {
    return this._defaultPostResponse('/content-sources/list', {
      channelId, onlyIds, telegramInitData: appUtils.getTelegramInitData()
    }, null, successCb, errorCb);
  }
}
