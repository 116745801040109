
import helpers from "@/utils/helpers";

const devTelegramIds = [
  // Max
  621876786
]

export const isCurrentTelegramUserDev = () => {
  const telegramApp = window.Telegram.WebApp

  return helpers.isExists(telegramApp?.initDataUnsafe?.user) &&
    devTelegramIds.indexOf(telegramApp.initDataUnsafe.user.id) >= 0
}

export default function () {
  let setTelegramUserId: number | null = null

  if (process.env.NODE_ENV === 'development') {
    // Max
    setTelegramUserId = 621876786
  }

  const tryApplyDevTelegramUserEmulation = (telegram: Telegram) => {
    if (helpers.isNotEmpty(telegram.WebApp.initData)) return
    if (!helpers.isNumeric(setTelegramUserId)) return

    if (IS_DEV_TUNNEL_ENABLED) {
      console.log("Skip dev telegram user")
      return
    }

    const devHash = "4445565f48415348"
    const authDate = Math.floor(Date.now() / 1000)
    const chatInstance = "1234567890123456789"
    const chatType = "sender"
    const startParam = ""
    const user: WebAppUser = {
      allows_write_to_pm: true,
      first_name: "Max",
      id: setTelegramUserId,
      language_code: "en",
      last_name: "",
      username: ""
    }

    telegram.WebApp = {
      ...telegram.WebApp,
      initData: `hash=${devHash}&auth_date=${authDate}&chat_instance=${chatInstance}&chat_type=${chatType}&start_param=${startParam}&user=${JSON.stringify(user)}`,
      initDataUnsafe: {
        auth_date: authDate,
        chat_instance: chatInstance,
        chat_type: chatType,
        hash: devHash,
        start_param: startParam,
        user: user
      },
      colorScheme: helpers.getCssVariable("--tg-theme-text-color") === "#ffffff" ? "dark" : "light"
    }
  }

  return {
    tryApplyDevTelegramUserEmulation,
  }
}
