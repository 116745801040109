<template>
  <component :is="tag" class="smt-container" v-bind:style="additionalContainerCss">
    <!-- eslint-disable -->
    <component :is="tag" ref="detail" v-bind:class="{'smt-default': !expanded, 'smt-expanded': expanded}"
               v-bind:style="`--lines: ${lines}`">
      <slot name="prepend" />

      <component :is="tag" v-bind:style="additionalCss"
                 v-html="text" />

      <slot name="append" />
    </component>
    <!-- eslint-enable -->

    <a v-if="hasMore && triggerShowMore"
       class="smt-anchor cursor-pointer"
       :style="additionalAnchorCss"
       @click="onClick">{{ moreText }}</a>
    <a v-if="hasLess && expanded"
       class="smt-anchor cursor-pointer"
       :style="additionalAnchorCss"
       @click="onClick">{{ lessText }}</a>
  </component>
</template>

<script>
// https://github.com/limlt/vue-show-more-text/blob/main/src/components/VueShowMoreText.vue
export default {
  name: 'VueShowMoreText',
  props: {
    lines: {
      type: Number,
      default: 3,
    },
    tag: {
      type: String,
      default: 'div',
    },
    text: {
      type: String,
      default: '',
    },
    additionalContainerCss:{
      type: String,
      default: '',
    },
    additionalContentCss: {
      type: String,
      default: '',
    },
    additionalContentExpandedCss: {
      type: String,
      default: '',
    },
    hasMore: {
      type: Boolean,
      default: true,
    },
    hasLess: {
      type: Boolean,
      default: true,
    },
    moreText: {
      type: String,
      default: 'Show more',
    },
    lessText: {
      type: String,
      default: 'Show less',
    },
    additionalAnchorCss: {
      type: String,
      default: '',
    },
    overrideClick: {
      type: Function,
    },
  },
  data() {
    return {
      expanded: false,
      triggerShowMore: false,
    }
  },
  computed: {
    additionalCss: function () {
      if(this.expanded) {
        return this.additionalContentCss
      }else{
        return this.additionalContentExpandedCss
      }
    }
  },
  mounted() {
    this.$nextTick(function () {
      this.determineShowMore()
    })
  },
  updated() {
    this.$nextTick(function () {
      this.determineShowMore()
    })
  },
  methods: {
    onClick() {
      if (this.overrideClick) {
        if (this.overrideClick()) {
          this.expanded = true
        } else {
          this.expanded = false
        }
      } else {
        this.expanded = !this.expanded
        this.$emit('click', this.expanded);
      }
    },
    determineShowMore() {
      if (
        this.$refs.detail &&
        this.$refs.detail.offsetHeight < this.$refs.detail.scrollHeight
      ) {
        this.triggerShowMore = true
      } else {
        this.triggerShowMore = false
      }
    },
  },
}
</script>

<style scoped>
.smt-default {
  --lines: 3;
  white-space: pre-line;
  display: -webkit-box;
  max-width: 100%;
  -webkit-line-clamp: var(--lines);
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.smt-expanded {
  display: block;
  display: -webkit-box;
  white-space: pre-line;
}

.smt-anchor {
  display: block;
  text-align: right;
  margin-bottom: 4px;
}
</style>