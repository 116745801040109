
import {
  Guid, KnowledgeBaseArticleEdit, KnowledgeBaseArticleCreateOrUpdateResult
} from '@/@models'
import PortalApiBase from "@/api/portal-api-base";
import appUtils from "@/utils/app-utils";
import {ApiErrorCallback, ApiSuccessCallback} from "@/api/api-base";

export default new class extends PortalApiBase {
  getArticle(id: Guid | null, publicId: string | null,
             successCb: ApiSuccessCallback<KnowledgeBaseArticleEdit>,
             errorCb: ApiErrorCallback<KnowledgeBaseArticleEdit>) {
    return this._defaultPostResponse('/knowledge-base/article', {
      id, publicId, telegramInitData: appUtils.getTelegramInitData()
    }, null, successCb, errorCb);
  }

  createOrUpdateArticle(model: KnowledgeBaseArticleEdit,
                        successCb: ApiSuccessCallback<KnowledgeBaseArticleCreateOrUpdateResult>,
                        errorCb: ApiErrorCallback<KnowledgeBaseArticleCreateOrUpdateResult>) {
    return this._defaultPostResponse('/knowledge-base/create-or-update-article', {
      model, telegramInitData: appUtils.getTelegramInitData()
    }, null, successCb, errorCb);
  }
}
