






































































import {computed, defineComponent, nextTick, PropType} from "@vue/composition-api";
import helpers from "@/utils/helpers";
import {FileFullInfo, FileMetatype, FileType, KnowledgeBaseArticleType, LocalizationString} from "@/@models";
import useNotifications from "@/components/useNotifications";
import useModals from "@/utils/useModals";
import useStringObjectUtils, {
  FileStringObject,
  KnowledgeBaseArticleStringObject,
  StringObjectType
} from "@/utils/useStringObjectUtils";
import appUtils from "@/utils/app-utils";
import fileUtils from "@/utils/file-utils";
import BFileFormGroup from "@/views/_controls/BFileFormGroup.vue";
import SimpleRichTextEditor from "@/views/_controls/SimpleRichTextEditor.vue";

export interface LocalizationStringVm {
  model: LocalizationString
  initialModel: LocalizationString
  isOpen: boolean
  isChanged: () => boolean
}

export default defineComponent({
  components: {SimpleRichTextEditor, BFileFormGroup},
  props: {
    vm: { type: Object as PropType<LocalizationStringVm>, required: true },
    disabled: Boolean,
    index: { type: Number, required: true }
  },
  setup(props, context) {
    const {showSuccessNotification, showErrorNotification} = useNotifications(context);
    const {showEditKnowledgeBaseArticlePopup} = useModals(context);
    const {
      createFileStringObject,
      createKnowledgeBaseArticleStringObject,
      addStringObjectToHeader,
      parseStringObjects
    } = useStringObjectUtils();

    const stringObjectsContainer = computed(() => parseStringObjects(props.vm.model.text, (obj) => {
      if (obj.object === StringObjectType.KnowledgeBaseArticle)
        return `{Article Link "${(<KnowledgeBaseArticleStringObject>obj).title}"}`;
      else
        return "???"
    }))
    const fileStringObjects = computed(() => {
      return helpers.findInArray(stringObjectsContainer.value.headerObjects,
        x => x.object === StringObjectType.File) as FileStringObject[]
    })
    const articleStringObjects = computed(() => {
      return helpers.findInArray(stringObjectsContainer.value.contentObjects,
        x => x.object === StringObjectType.KnowledgeBaseArticle) as KnowledgeBaseArticleStringObject[]
    })

    const copyToClipboard = (tokenKey: string) => {
      navigator.clipboard.writeText(tokenKey);

      showSuccessNotification(`"${tokenKey}" is copied`)
    }

    const afterFileUploaded = (id: string, fileInfo: FileFullInfo) => {
      addFileStringObject(fileInfo.type, fileInfo.id)
      showSuccessNotification(`File successfully added, changes will be applied after save`)
    }

    const addFileStringObject = (fileType: FileType, fileId: string) => {
      let newFileSo = createFileStringObject(appUtils.convertFileTypeToFileStringObjectType(fileType), fileId)
      props.vm.model.text = addStringObjectToHeader(props.vm.model.text ?? "", newFileSo)
    }

    const addKnowledgeBaseArticleLink = () => {
      const initialText = helpers.isNotEmptyArray(articleStringObjects.value) ? null : props.vm.model.text

      showEditKnowledgeBaseArticlePopup(null, null, true, KnowledgeBaseArticleType.SinglePage,
        initialText, (isCreate, id, publicId) => {
          if (!isCreate) return

          let newArticleLinkPrependText = "\n"
          let newArticleLinkTitle = "Show More"
          if (helpers.isNotEmptyArray(articleStringObjects.value)) {
            newArticleLinkTitle += " " + (articleStringObjects.value.length + 1)
          } else {
            newArticleLinkPrependText += "\n"
          }

          let newArticleLinkSo = createKnowledgeBaseArticleStringObject(publicId, newArticleLinkTitle)

          props.vm.model.text = props.vm.model.text + newArticleLinkPrependText + newArticleLinkSo
        })
    }

    const editKnowledgeBaseArticleLink = (publicId: string) => {
      if (!helpers.isNotEmpty(publicId)) return

      showEditKnowledgeBaseArticlePopup(null, publicId, false, null, null)
    }

    return {
      helpers,
      fileUtils,
      FileMetatype,

      stringObjectsContainer,
      fileStringObjects,
      articleStringObjects,

      copyToClipboard,
      afterFileUploaded,
      addKnowledgeBaseArticleLink,
      editKnowledgeBaseArticleLink,
    }
  },
})
