



















import {computed, defineComponent, onMounted, ref, watch} from "@vue/composition-api";
import useStore from "@/store";
import helpers from "@/utils/helpers";
import useRouter from "@/router";

export default defineComponent({
  props: {
    bottomOffset: { type: [Number, String], default: 0 }
  },
  setup(props, context) {
    const store = useStore();
    const router = useRouter()

    const withFooter = computed(() => store.state.ui.withFooter)

    const currentHeight = ref("100dvh");

    const updateContainerHeight = () => {
      const windowHeight = store.state.ui.windowHeight
      let additionalElementsHeight = 0

      if (router.currentRoute.path.startsWith("/admin")) {
        additionalElementsHeight = 130
      }

      const parsedValue = helpers.toNumeric(props.bottomOffset) + (withFooter.value ? 45 : 0) + additionalElementsHeight

      if (helpers.isNumeric(parsedValue)) {
        if (helpers.isNumeric(windowHeight)) {
          currentHeight.value = (windowHeight - parsedValue) + 'px';
        } else {
          currentHeight.value = (window.innerHeight - parsedValue) + 'px';
        }
      }
    }

    onMounted(updateContainerHeight);
    watch(() => store.state.ui.windowHeight, updateContainerHeight);

    return {
      currentHeight
    }
  }
})
