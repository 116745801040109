
























































import {
  computed,
  defineComponent,
  nextTick,
  onMounted,
  ref,
  watch,
  watchEffect
} from "@vue/composition-api";
import useRouter from "@/router";
import {UiMutationTypes} from "@/store/modules/ui/types";
import useStore from "@/store";
import helpers from "@/utils/helpers";
import useNotifications from "@/components/useNotifications";
import localizationPortalApi from "@/api/portal/localization-portal-api";
import {Guid ,LocalizationInfo, Localizations, LocalizationString, LocalizationStrings} from "@/@models";
import PageLoadingIndicator from "@/views/_components/PageLoadingIndicator.vue";
import LocalizationStringView, {LocalizationStringVm} from "@/views/admin/localization/_partials/LocalizationStringView.vue";
import BTextInputFormGroup from "@/views/_controls/BTextInputFormGroup.vue";

export default defineComponent({
  components: {LocalizationStringView, BTextInputFormGroup, PageLoadingIndicator},
  setup(props, context) {
    const store = useStore()
    const router = useRouter()
    const {showSuccessNotification, showInfoNotification, showErrorNotification} = useNotifications(context)

    let keyHint = router.currentRoute.query.keyHint as string | null

    if (helpers.isNotEmpty(keyHint) && helpers.stringContains(keyHint, "_d_")) {
      keyHint = keyHint?.replaceAll("_d_", ".")
    }

    store.commit(UiMutationTypes.UPDATE_GLOBAL_LOADING, true);

    const isLoading = ref(true)
    const isButtonsDisabled = ref(false)
    const localizations = ref<LocalizationInfo[] | null>(null)
    const localizationStrings = ref<LocalizationStrings | null>(null)
    const selectedLocalizationStringVms = ref<LocalizationStringVm[] | null>(null)

    const searchTermFilter = ref<string>("")
    const selectedLocalizationStringVmsFiltered = computed(() =>
      helpers.isNotEmptyArray(selectedLocalizationStringVms.value) && helpers.isNotEmptyOrWhitespace(searchTermFilter.value)
        ? helpers.findInArray(selectedLocalizationStringVms.value,
          x => x.isChanged() || helpers.stringContains(x.model.key, searchTermFilter.value, true) ||
            helpers.stringContains(x.model.text, searchTermFilter.value, true))
        : selectedLocalizationStringVms.value)

    let scrollTriesNumber = 0
    const scrollAndOpenLocalizationString = (key: string) => {
      const element = document.getElementById(key)

      if (helpers.isExists(element)) {
        if (helpers.isNotEmptyArray(selectedLocalizationStringVms.value)) {
          const vm = helpers.findFirstInArray(selectedLocalizationStringVms.value, x => x.model.key === key)

          if (helpers.isExists(vm)) {
            vm.isOpen = true
          }
        }

        const y = element.getBoundingClientRect().top + window.scrollY

        window.scroll({
          top: y - 60,
          behavior: 'smooth'
        });
        scrollTriesNumber = 0
      } else {
        if (scrollTriesNumber < 5) {
          setTimeout(() => scrollAndOpenLocalizationString(key), 300)
        } else {
          scrollTriesNumber = 0
        }
      }
    }

    const updateSelectedLocalizationStringVms = () => {
      selectedLocalizationStringVms.value = localizationStrings.value?.localizationStrings.map(x => {
        const initial = helpers.deepCopy(x)
        return {
          model: x,
          initialModel: initial,
          isOpen: false,
          isChanged: () => !helpers.isDeepEquals(x, initial)
        }
      }) ?? null
    }

    const selectLocalization = (localizationId: Guid) => {
      if (!helpers.isNotEmpty(localizationId)) return

      isButtonsDisabled.value = true

      localizationPortalApi.getLocalizationStrings(localizationId, result => {
        localizationStrings.value = result

        updateSelectedLocalizationStringVms()

        isLoading.value = false

        if (helpers.isNotEmpty(keyHint)) {
          scrollAndOpenLocalizationString(keyHint)
        }

        isButtonsDisabled.value = false
      }, errorMessage => {
        showErrorNotification(errorMessage)

        isButtonsDisabled.value = false
      })
    }

    const saveChanges = () => {
      if (!helpers.isExists(localizationStrings.value)) return

      isButtonsDisabled.value = true

      localizationPortalApi.updateLocalizationStrings(localizationStrings.value, r => {
        if (r.success) {
          if (r.isAnyChanges) {
            showSuccessNotification("Changes successfully saved, after 1 min or less localization resources should be updated")
          } else {
            showInfoNotification("No update required, resources are up to date")
          }

          updateSelectedLocalizationStringVms()
        }

        isButtonsDisabled.value = false
      }, errorMessage => {
        showErrorNotification(errorMessage)

        isButtonsDisabled.value = false
      })
    }

    // init
    //
    localizationPortalApi.getLocalizations(result => {
      localizations.value = result.localizations

      if (helpers.isNotEmptyArray(localizations.value)) {
        selectLocalization(localizations.value[0].id)

        store.commit(UiMutationTypes.UPDATE_GLOBAL_LOADING, false);
      }
    }, errorMessage => {
      showErrorNotification(errorMessage)
    })

    return {
      helpers,

      keyHint,

      isLoading,
      isButtonsDisabled,
      localizations: localizations as unknown as LocalizationInfo[],
      selectedLocalizationStringVms: selectedLocalizationStringVms as unknown as LocalizationStringVm[],

      searchTermFilter,
      selectedLocalizationStringVmsFiltered,

      saveChanges,
    }
  }
})
