
import {
  Guid, TelegramBotRoute
} from '@/@models'
import ClientApiBase from "@/api/client-api-base";
import appUtils from "@/utils/app-utils";
import {ApiEmptyErrorCallback, ApiEmptySuccessCallback} from "@/api/api-base";

export default new class extends ClientApiBase {
  validateInitData(telegramInitData: string,
                   successCb: ApiEmptySuccessCallback,
                   errorCb: ApiEmptyErrorCallback) {
    return this._defaultEmptyPostResponse('/telegram/validate-init-data', {
      telegramInitData
    }, null, successCb, errorCb);
  }

  openTelegramBotRoute(messageLinkId: string, route: TelegramBotRoute, routeChannelId: Guid | null,
                       successCb: ApiEmptySuccessCallback,
                       errorCb: ApiEmptyErrorCallback) {
    return this._defaultEmptyPostResponse('/telegram/open-telegram-bot-route', {
      messageLinkId, route, routeChannelId, telegramInitData: appUtils.getTelegramInitData()
    }, null, successCb, errorCb);
  }
}
