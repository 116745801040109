import {
  Guid,
  AiAgentAdminEdit,
  AiAgentAdminTable,
  AiAgentLanguageAdminTable,
  AiAgentLanguageAdminEdit,
  AiAgentRoleAdminTable,
  AiAgentRoleAdminEdit,
  AiAgentStyleAdminTable,
  AiAgentStyleAdminEdit
} from '@/@models'
import PortalApiBase from "@/api/portal-api-base";
import appUtils from "@/utils/app-utils";
import {ApiEmptyErrorCallback, ApiEmptySuccessCallback, ApiErrorCallback, ApiSuccessCallback} from "@/api/api-base";

export default new class extends PortalApiBase {
  getAiAgentsForTable(successCb: ApiSuccessCallback<AiAgentAdminTable[]>,
                      errorCb: ApiErrorCallback<AiAgentAdminTable[]>) {
    return this._defaultPostResponse('/ai-agent/ai-agent-table', {
      telegramInitData: appUtils.getTelegramInitData()
    }, null, successCb, errorCb);
  }

  getAiAgentForEdit(id: Guid,
                    successCb: ApiSuccessCallback<AiAgentAdminEdit>,
                    errorCb: ApiErrorCallback<AiAgentAdminEdit>) {
    return this._defaultPostResponse('/ai-agent/get-edit-ai-agent', {
      id, telegramInitData: appUtils.getTelegramInitData()
    }, null, successCb, errorCb);
  }

  updateAiAgent(model: AiAgentAdminEdit,
                successCb: ApiSuccessCallback<Guid | null>,
                errorCb: ApiErrorCallback<Guid | null>) {
    return this._defaultPostResponse('/ai-agent/edit-ai-agent', {
      model, telegramInitData: appUtils.getTelegramInitData()
    }, null, successCb, errorCb);
  }

  deleteAiAgent(id: Guid,
                successCb: ApiEmptySuccessCallback,
                errorCb: ApiEmptyErrorCallback) {
    return this._defaultEmptyPostResponse('/ai-agent/delete-ai-agent', {
      id, telegramInitData: appUtils.getTelegramInitData()
    }, null, successCb, errorCb);
  }

  getAiAgentLanguagesForTable(successCb: ApiSuccessCallback<AiAgentLanguageAdminTable[]>,
                              errorCb: ApiErrorCallback<AiAgentLanguageAdminTable[]>) {
    return this._defaultPostResponse('/ai-agent/ai-agent-language-table', {
      telegramInitData: appUtils.getTelegramInitData()
    }, null, successCb, errorCb);
  }

  getAiAgentLanguageForEdit(id: Guid,
                            successCb: ApiSuccessCallback<AiAgentLanguageAdminEdit>,
                            errorCb: ApiErrorCallback<AiAgentLanguageAdminEdit>) {
    return this._defaultPostResponse('/ai-agent/get-edit-ai-agent-language', {
      id, telegramInitData: appUtils.getTelegramInitData()
    }, null, successCb, errorCb);
  }

  updateAiAgentLanguage(model: AiAgentLanguageAdminEdit,
                        successCb: ApiSuccessCallback<Guid | null>,
                        errorCb: ApiErrorCallback<Guid | null>) {
    return this._defaultPostResponse('/ai-agent/edit-ai-agent-language', {
      model, telegramInitData: appUtils.getTelegramInitData()
    }, null, successCb, errorCb);
  }

  deleteAiAgentLanguage(id: Guid,
                        successCb: ApiEmptySuccessCallback,
                        errorCb: ApiEmptyErrorCallback) {
    return this._defaultEmptyPostResponse('/ai-agent/delete-ai-agent-language', {
      id, telegramInitData: appUtils.getTelegramInitData()
    }, null, successCb, errorCb);
  }

  getAiAgentRolesForTable(successCb: ApiSuccessCallback<AiAgentRoleAdminTable[]>,
                          errorCb: ApiErrorCallback<AiAgentRoleAdminTable[]>) {
    return this._defaultPostResponse('/ai-agent/ai-agent-role-table', {
      telegramInitData: appUtils.getTelegramInitData()
    }, null, successCb, errorCb);
  }

  getAiAgentRoleForEdit(id: Guid,
                        successCb: ApiSuccessCallback<AiAgentRoleAdminEdit>,
                        errorCb: ApiErrorCallback<AiAgentRoleAdminEdit>) {
    return this._defaultPostResponse('/ai-agent/get-edit-ai-agent-role', {
      id, telegramInitData: appUtils.getTelegramInitData()
    }, null, successCb, errorCb);
  }

  updateAiAgentRole(model: AiAgentRoleAdminEdit,
                    successCb: ApiSuccessCallback<Guid | null>,
                    errorCb: ApiErrorCallback<Guid | null>) {
    return this._defaultPostResponse('/ai-agent/edit-ai-agent-role', {
      model, telegramInitData: appUtils.getTelegramInitData()
    }, null, successCb, errorCb);
  }

  deleteAiAgentRole(id: Guid,
                    successCb: ApiEmptySuccessCallback,
                    errorCb: ApiEmptyErrorCallback) {
    return this._defaultEmptyPostResponse('/ai-agent/delete-ai-agent-role', {
      id, telegramInitData: appUtils.getTelegramInitData()
    }, null, successCb, errorCb);
  }

  getAiAgentStylesForTable(successCb: ApiSuccessCallback<AiAgentStyleAdminTable[]>,
                           errorCb: ApiErrorCallback<AiAgentStyleAdminTable[]>) {
    return this._defaultPostResponse('/ai-agent/ai-agent-style-table', {
      telegramInitData: appUtils.getTelegramInitData()
    }, null, successCb, errorCb);
  }

  getAiAgentStyleForEdit(id: Guid,
                         successCb: ApiSuccessCallback<AiAgentStyleAdminEdit>,
                         errorCb: ApiErrorCallback<AiAgentStyleAdminEdit>) {
    return this._defaultPostResponse('/ai-agent/get-edit-ai-agent-style', {
      id, telegramInitData: appUtils.getTelegramInitData()
    }, null, successCb, errorCb);
  }

  updateAiAgentStyle(model: AiAgentStyleAdminEdit,
                     successCb: ApiSuccessCallback<Guid | null>,
                     errorCb: ApiErrorCallback<Guid | null>) {
    return this._defaultPostResponse('/ai-agent/edit-ai-agent-style', {
      model, telegramInitData: appUtils.getTelegramInitData()
    }, null, successCb, errorCb);
  }

  deleteAiAgentStyle(id: Guid,
                     successCb: ApiEmptySuccessCallback,
                     errorCb: ApiEmptyErrorCallback) {
    return this._defaultEmptyPostResponse('/ai-agent/delete-ai-agent-style', {
      id, telegramInitData: appUtils.getTelegramInitData()
    }, null, successCb, errorCb);
  }
}
