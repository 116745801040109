














































































import {computed, defineComponent, PropType} from "@vue/composition-api";
import { ValidationProvider } from "vee-validate";
import Helpers from "@/utils/helpers";
import VueSelect, {VueSelectInstance} from "vue-select";
import useControlUtils from "@/utils/useControlUtils";
import useConfig from "@/utils/useConfig";
import helpers from "@/utils/helpers";
import { createPopper } from '@popperjs/core'

export default defineComponent({
  inheritAttrs: false,
  components: {
    ValidationProvider, VueSelect
  },
  props: {
    if: { type: Boolean, default: true },
    loadingIfOptionsNull: { type: Boolean, default: true },
    inputId: String,
    //inputClass: String,
    displayName: { type: String, required: true },
    placeholderOverride: String,
    description: String,
    rules: [String, Object],
    withoutLabel: { type: Boolean, default: false },
    value: [String, Number, Object, Array],
    selectable: Function as PropType<(option: any) => boolean>,
    topPositionByDefault: Boolean,
    searchable: { type: Boolean, default: () => !helpers.isMobile() },
  },
  emits: ['changed', 'option-selecting', 'option-deselecting'],
  setup(props, context) {
    const config = useConfig()
    const { getDefaultFormGroupClass } = useControlUtils()

    // validation
    if (!Helpers.isNotEmpty(props.inputId)) {
      console.error(`inputId is empty or undefined - DisplayName: ${props.displayName}`);
    }

    const additionalFormGroupClass = computed(() => getDefaultFormGroupClass(props.rules));
    const innerValue = computed({
      get() {
        return props.value;
      },
      set(val) {
        context.emit('input', val);
      }
    });
    const isObjectOptionDataMode = computed(() => Helpers.isNotEmpty(context.attrs['track-by']))
    const selectComponentRef = computed(() => 'select-component--' + props.inputId);

    const getInputStateClass = (attrs: any, dirty: boolean, validated: boolean, valid: boolean): string => {
      let isValid: boolean | null = null;

      if (Helpers.isExists(attrs.state)) {
        isValid = attrs.state;
      }
      else {
        isValid = dirty || validated ? valid : null;
      }

      if (isValid === null) return '';

      return isValid ? 'is-valid' : 'is-invalid';
    }

    const getSelectComponent = () => context.refs[selectComponentRef.value] as VueSelectInstance
    const getOptionLabel = (option: any) => {
      const selectComponent = getSelectComponent()
      return Helpers.isExists(selectComponent) ? selectComponent.$props.getOptionLabel(option) : '';
    }

    const onOptionSelecting = (option: any) => {
      context.emit('option-selecting', option)
    }
    const onOptionDeselecting = (option: any) => {
      context.emit('option-deselecting', option)
    }

    const checkIsSelectable = (option: any) => {
      if (Helpers.isFunction(props.selectable)) {
        return props.selectable(option)
      } else {
        if (option.hasOwnProperty('group')) return true
        return !Helpers.isNotEmptyArray(innerValue.value) || innerValue.value.indexOf(option.id) < 0
      }
    }

    // https://vue-select.org/guide/positioning.html#popper-js-integration
    const calculatePosition = (dropdownList, component, { width }) => {
      dropdownList.style.width = width

      const popper = createPopper(component.$refs.toggle, dropdownList, {
        placement: props.topPositionByDefault ? 'top' : 'bottom',
        modifiers: [
          {
            name: 'offset',
            options: {
              offset: [0, -1],
            },
          },
          {
            name: 'toggleClass',
            enabled: true,
            phase: 'write',
            fn({ state }) {
              component.$el.classList.toggle(
                'drop-up',
                state.placement === 'top'
              )
            },
          },
        ],
      })

      return () => popper.destroy()
    }

    const onSelectOpenChanged = (isOpen: boolean) => {
      if (!helpers.isMobile()) return

      const selectComponent = getSelectComponent()

      const checkTouchOutside = (event: any) => {
        if (!isOpen) return

        let isTargetInside = selectComponent.$el.contains(event.target)

        if (!isTargetInside && selectComponent.appendToBody && helpers.isExists(selectComponent.$refs.dropdownMenu)) {
          isTargetInside = selectComponent.$refs.dropdownMenu.contains(event.target)
        }

        if (!isTargetInside) {
          // @ts-ignore
          selectComponent.$refs.search?.blur();
        }
      }

      if (isOpen) {
        return document.addEventListener('touchend', checkTouchOutside);
      } else {
        return document.removeEventListener('touchend', checkTouchOutside);
      }
    }

    return {
      helpers: Helpers,
      config: config,

      additionalFormGroupClass,
      innerValue,
      isObjectOptionDataMode,
      selectComponentRef,

      getInputStateClass,
      getOptionLabel,
      checkIsSelectable,
      onOptionSelecting,
      onOptionDeselecting,
      calculatePosition,
      onSelectOpenChanged
    }
  },
})
