















import {computed, defineComponent, onBeforeUnmount, onMounted, onUnmounted, ref, watch} from "@vue/composition-api";
import {UiMutationTypes} from "@/store/modules/ui/types";
import useStore from "@/store";
import useRouter from "@/router";

export default defineComponent({
  setup(props, context) {
    const store = useStore()
    const router = useRouter()

    const withFooter = computed(() => store.state.ui.withFooter)

    return {
      withFooter
    }
  }
})
