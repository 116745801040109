import helpers from '@/utils/helpers'
import Helpers from "@/utils/helpers";
import fileUtils from "@/utils/file-utils";
import appUtils from "@/utils/app-utils";

export enum StringObjectType {
  File = "file",
  KnowledgeBaseArticle = "knowledge_base_article"
}

export enum FileStringObjectType {
  Raw = "raw",
  Image = "image",
  Video = "video",
  Audio = "audio"
}

export interface StringObjectBase {
  object: StringObjectType
  title: string
  link: string
}

export interface FileStringObject extends StringObjectBase {
  type: FileStringObjectType
  fileId: string
}

export interface KnowledgeBaseArticleStringObject extends StringObjectBase {
  publicId: string
}

export interface StringObjectsContainer {
  headerObjects: StringObjectBase[]
  contentObjects: StringObjectBase[]
  contentText: string
}

const stringObjectLineRegex = /^<a\s+([^>]+)>((?:.(?!<\/a>))*.)<\/a>$/g
const stringObjectContentRegex = /<a\s+([^>]+)>((?:.(?!<\/a>))*.)<\/a>/g
const stringObjectAttributesRegex = /(\w.[\w\-.:]+)\s*=\s*("[^"]*"|'[^']*'|[\w\-.:]+)/g

// IMPORTANT: sync with C# implementation (StringObjectUtils.cs)
export default function () {
  const _convertToString = (object: StringObjectBase) => {
    const element = document.createElement("a")

    for (const objectKey in object) {
      // @ts-ignore
      const objectValue = object[objectKey]

      if (objectKey === "object") {
        element.dataset.object = objectValue
      } else if (objectKey === "title") {
        element.text = objectValue
      } else if (objectKey === "link") {
        element.href = objectValue
      } else {
        element.dataset[objectKey] = objectValue
      }
    }

    return element.outerHTML
  }

  const _parseStringObject = (match: RegExpMatchArray): StringObjectBase | null => {
    if (match.length < 3) return null

    let obj: StringObjectBase | any = {
      title: match[2]
    }

    const attributesString = match[1]
    const attributeMatches = attributesString.matchAll(stringObjectAttributesRegex)
    for (const attributeMatch of attributeMatches) {
      if (attributeMatch.length < 2) continue

      let attributeName = helpers.dashToCamelCaseString(attributeMatch[1].replace(/^data-/g, ""))
      let attributeValue = attributeMatch[2]

      if ((attributeValue.startsWith('\'') && attributeValue.endsWith('\'')) ||
        (attributeValue.startsWith('\"') && attributeValue.endsWith('\"'))) {
        attributeValue = attributeValue.substring(1, attributeValue.length - 1)
      }

      if (attributeName === 'href') {
        obj.link = attributeValue
      } else {
        if (attributeMatch.length > 2) {
          obj[attributeName] = attributeValue
        } else {
          obj[attributeName] = null
        }
      }
    }

    if (!helpers.isObject(obj) ||
      !helpers.isNotEmpty(obj.object) ||
      !helpers.isNotEmpty(obj.title) ||
      !helpers.isNotEmpty(obj.link) ||
      !Object.values(StringObjectType).includes(obj!.object)) return null

    return obj
  }

  const _parseStringObjectInLine = (line: string): StringObjectBase | null => {
    const matches = line.matchAll(stringObjectLineRegex)

    for (const match of matches) {
      return _parseStringObject(match)
    }

    return null
  }

  const _parseContentStringObjects = (contentText: string): StringObjectBase[] => {
    const objects: StringObjectBase[] = []

    if (!helpers.isNotEmpty(contentText)) return objects

    const matches = contentText.matchAll(stringObjectContentRegex)

    for (const match of matches) {
      const obj = _parseStringObject(match)

      if (helpers.isExists(obj)) {
        objects.push(obj)
      }
    }

    return objects
  }

  const _replaceContentStringObjectText = (contentText: string,
                                           replaceFunc: (stringObject: StringObjectBase) => string): string => {
    return contentText.replaceAll(stringObjectContentRegex, (capture, g1, g2) => {
      const obj = _parseStringObject([capture, g1, g2])
      return helpers.isExists(obj) ? replaceFunc(obj) : capture
    })
  }

  const _textProcessor = (text: string,
                          headerTextLineFunc: ((textLine: string, stringObject: StringObjectBase) => string | null) | null,
                          contentTextLineFunc: ((textLine: string, stringObjects: StringObjectBase[]) => string | null) | null,
                          contentTextLineReplaceFunc: ((stringObject: StringObjectBase) => string) | null,
                          postProcessHeaderTextLinesFunc: ((headerTextLines: string[]) => string[]) | null,
                          postProcessContentTextLinesFunc: ((contentTextLines: string[]) => string[]) | null): string => {
    let isHeaderEnded = false

    const textLines = text.split('\n');

    let headerTextLines: string[] = []
    let contentTextLines: string[] = []

    for (let i = 0; i < textLines.length; i++) {
      let textLine = textLines[i]

      let isHeaderProcessed = false
      if (!isHeaderEnded) {
        const lineTrimmed = textLine.trim()

        if (!helpers.isNotEmpty(lineTrimmed)) continue

        if (lineTrimmed.startsWith("<a ")) {
          const lineObj = _parseStringObjectInLine(lineTrimmed)

          if (helpers.isExists(lineObj)) {
            const replaceTextLine = helpers.isFunction(headerTextLineFunc)
              ? headerTextLineFunc(lineTrimmed, lineObj)
              : null

            textLine = replaceTextLine ?? lineTrimmed
            isHeaderProcessed = true
          }
        }
      }

      if (isHeaderProcessed) {
        headerTextLines.push(textLine)
      } else {
        if (!isHeaderEnded) {
          if (helpers.isFunction(postProcessHeaderTextLinesFunc)) {
            headerTextLines = postProcessHeaderTextLinesFunc(headerTextLines) ?? headerTextLines
          }

          isHeaderEnded = true
        }

        const replaceTextLine = helpers.isFunction(contentTextLineFunc)
          ? contentTextLineFunc(textLine, _parseContentStringObjects(textLine))
          : null

        if (Helpers.isExists(replaceTextLine)) {
          textLine = replaceTextLine
        }

        // replace in content text
        textLine = helpers.isFunction(contentTextLineReplaceFunc)
          ? _replaceContentStringObjectText(textLine, contentTextLineReplaceFunc)
          : textLine

        contentTextLines.push(textLine)
      }
    }

    if (helpers.isFunction(postProcessContentTextLinesFunc)) {
      contentTextLines = postProcessContentTextLinesFunc(contentTextLines)
    }

    let contentText = contentTextLines.join("\n")

    if (helpers.isNotEmptyArray(headerTextLines)) {
      contentText = headerTextLines.join('\n') + '\n\n' + contentText
    }

    return contentText
  }

  const parseStringObjects = (text: string | null,
                              replaceInContentFunc?: (stringObject: StringObjectBase) => string): StringObjectsContainer => {
    let headerObjects: StringObjectBase[] = []
    let contentObjects: StringObjectBase[] = []
    let contentText: string = text ?? ""

    if (helpers.isNotEmpty(text)) {
      _textProcessor(text, (textLine, stringObject) => {
        headerObjects.push(stringObject)
        return null
      }, (textLine, stringObjects) => {
        contentObjects.push(...stringObjects)
        return null
      }, replaceInContentFunc ?? null, null, (contentTextLines) => {
        contentText = contentTextLines.join('\n')

        return contentTextLines
      })
    }

    return {
      headerObjects: headerObjects,
      contentObjects: contentObjects,
      contentText: contentText
    }
  }

  const addStringObjectToHeader = (text: string, stringObject: string, prepend = false): string => {
    return _textProcessor(text, null, null, null,
      (headerTextLines) => {
        if (prepend) {
          headerTextLines = [stringObject, ...headerTextLines]
        } else {
          headerTextLines.push(stringObject)
        }

        return headerTextLines
    }, null)
  }

  const createFileStringObject = (type: FileStringObjectType, fileId: string) => {
    let title: string
    switch (type) {
      case FileStringObjectType.Raw:
        title = `File (${fileId})`
        break;
      case FileStringObjectType.Image:
        title = `Image (${fileId})`
        break;
      case FileStringObjectType.Video:
        title = `Video (${fileId})`
        break;
      case FileStringObjectType.Audio:
        title = `Audio (${fileId})`
        break;
    }

    const obj: FileStringObject = {
      object: StringObjectType.File,
      title: title,
      link: fileUtils.url(appUtils.convertFileStringObjectTypeToFileType(type), fileId),

      type: type,
      fileId: fileId
    }

    return _convertToString(obj)
  }
  const createKnowledgeBaseArticleStringObject = (publicId: string, linkTitle: string) => {
    const obj: KnowledgeBaseArticleStringObject = {
      object: StringObjectType.KnowledgeBaseArticle,
      title: linkTitle,
      link: appUtils.getRouterAbsoluteUrl({
        name: 'knowledge-base-article',
        params: {
          publicId: publicId
        }
      }),

      publicId: publicId,
    }

    return _convertToString(obj)
  }

  return {
    parseStringObjects,
    addStringObjectToHeader,
    createFileStringObject,
    createKnowledgeBaseArticleStringObject,
  }
}