






import {computed, defineComponent, nextTick, watch} from "@vue/composition-api";
import useRouter from "@/router";
import {UiMutationTypes} from "@/store/modules/ui/types";
import useStore from "@/store";
import helpers from "@/utils/helpers";
import paymentApi from "@/api/payment-api";
import appUtils from "@/utils/app-utils";
import useNotifications from "@/components/useNotifications";
import {CallbackAction} from "@/views/system/Callback.vue";
import useSharedUtils from "@/utils/useSharedUtils";

export default defineComponent({
  setup(props, context) {
    const store = useStore()
    const {showErrorNotification} = useNotifications(context)
    const {closePage} = useSharedUtils()

    // init
    //
    store.commit(UiMutationTypes.UPDATE_GLOBAL_LOADING, true);

    paymentApi.paymentAccountLink(appUtils.getRouterAbsoluteUrl({
      name: 'payment-account-link',
    }), appUtils.getRouterAbsoluteUrl({
      name: 'callback',
      params: {
        action: CallbackAction.Close
      }
    }), result => {
      if (helpers.isExists(result)) {
        if (helpers.isNotEmpty(result.url)) {
          if (result.isRedirectUrl) {
            appUtils.redirectToAbsoluteUrl(result.url, store.state.ui.isIframeMode)
            return
          } else {
            appUtils.openInNewTabAbsoluteUrl(result.url)
          }
        }
      }

      closePage();
    }, errorMessage => {
      showErrorNotification(errorMessage)

      closePage();
    })

    return {
    }
  }
})
