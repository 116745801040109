













































































import {computed, defineComponent, PropType, watch} from "@vue/composition-api";
import { ValidationProvider } from "vee-validate";
import helpers from "@/utils/helpers";
import useControlUtils from "@/utils/useControlUtils";

let BTextInputFormGroup_ComponentId = 1

export default defineComponent({
  inheritAttrs: false,
  components: {
    ValidationProvider
  },
  props: {
    if: { type: Boolean, default: true },
    inputId: String,
    inputClass: String,
    formGroupClass: String,
    displayName: { type: String, required: true },
    placeholderOverride: String,
    description: String,
    rules: [String, Object],
    withoutLabel: { type: Boolean, default: false },
    withInputGroupWrapper: { type: Boolean, default: false },
    keyupEnter: { type: Function, default: () => {} },
    value: [String, Number],
    defaultValue: [String, Number],
    valueForValidation: Function as PropType<() => string | number | null>,
    nullIfEmpty: Boolean
  },
  setup(props, context) {
    const { getDefaultFormGroupClass } = useControlUtils()

    // before create
    const componentId = BTextInputFormGroup_ComponentId;
    BTextInputFormGroup_ComponentId += 1;

    // validation
    if (!helpers.isNotEmpty(props.inputId)) {
      console.error(`inputId is empty or undefined - DisplayName: ${props.displayName}`);
    }

    const componentRef = computed(() =>
        helpers.isFunction(props.valueForValidation)
            ? 'BTextInputFormGroup_' + componentId
            : null);
    const getValidationProvider = () => {
      if (!helpers.isNotEmpty(componentRef.value)) return
      return context.refs[componentRef.value] as InstanceType<typeof ValidationProvider>;
    }

    const additionalFormGroupClass = computed(() => getDefaultFormGroupClass(props.rules));
    const innerValue = computed({
      get() {
        return helpers.isExists(props.value) ? props.value : props.defaultValue;
      },
      set(val) {
        context.emit('input', props.nullIfEmpty && val === "" ? null : val);
      }
    });

    const getInputState = (attrs: any, dirty: boolean, validated: boolean, valid: boolean): boolean | null => {
      if (helpers.isExists(attrs.state)) {
        return attrs.state;
      }
      return dirty || validated ? valid : null;
    }

    watch(innerValue, () => {
      if (!helpers.isFunction(props.valueForValidation)) return

      const valueForValidation = props.valueForValidation()

      const vp = getValidationProvider();

      vp?.syncValue(valueForValidation);
      vp?.validate(valueForValidation);
    })

    return {
      helpers: helpers,

      componentRef,

      additionalFormGroupClass,
      innerValue,
      getInputState
    }
  },
})
