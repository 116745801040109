import {watch, watchEffect} from "@vue/composition-api";
import helpers from "@/utils/helpers";
import useRouter from "@/router";
import useStore from "@/store";
import {UiMutationTypes} from "@/store/modules/ui/types";
import {IframeSettings} from "@/store/modules/ui/state";
import {
  IframeColorScheme,
  IframeCommandType,
  IframeEventType,
  IframeEventWindowResize,
  SetIframeSettingsCommand
} from "@/../public/static-pages/landing-page-model";

export default function () {
  const router = useRouter()
  const store = useStore()

  let isIframeModeChanged = false
  if (router.currentRoute.meta.iframeMode) {
    if (!store.state.ui.isIframeMode) {
      store.commit(UiMutationTypes.UPDATE_IFRAME_MODE, true)

      isIframeModeChanged = true
    }
  } else {
    store.commit(UiMutationTypes.UPDATE_IFRAME_MODE, false)
    return () => {
    }
  }

  const tryUpdateIframeSettings = (returnUrl: string | null | undefined, colorScheme: string | number | null | undefined,
                                   textColor: string | null | undefined, backgroundColor: string | null | undefined,
                                   injectStyleUrl: string | null | undefined) => {
    const updatedSettings: IframeSettings = store.state.ui.iframeSettings ?? <any>{}

    let isAnyParameterExists = false
    if (helpers.isExists(returnUrl)) {
      updatedSettings.returnUrl = returnUrl
      isAnyParameterExists = true
    }
    if (helpers.isExists(colorScheme)) {
      const colorSchemeParsed = helpers.toNumeric(colorScheme)
      updatedSettings.isDarkColorSchemeForced = colorSchemeParsed === IframeColorScheme.Dark
      updatedSettings.isLightColorSchemeForced = colorSchemeParsed === IframeColorScheme.Light
      isAnyParameterExists = true
    }
    if (helpers.isExists(textColor) && helpers.isHexColor(textColor)) {
      updatedSettings.textColor = textColor
      isAnyParameterExists = true
    }
    if (helpers.isExists(backgroundColor) && helpers.isHexColor(backgroundColor)) {
      updatedSettings.backgroundColor = backgroundColor
      isAnyParameterExists = true
    }
    if (helpers.isExists(injectStyleUrl)) {
      updatedSettings.injectStyleUrl = injectStyleUrl
      isAnyParameterExists = true
    }

    if (isAnyParameterExists) {
      console.log("[Iframe] Iframe Settings changed", updatedSettings)
      store.commit(UiMutationTypes.UPDATE_IFRAME_SETTINGS, updatedSettings)
    }
  }

  const sendWindowResizeEventToIframeParent = () => {
    if (!helpers.isNumeric(store.state.ui.windowScrollWidth) ||
      !helpers.isNumeric(store.state.ui.windowScrollHeight)) return

    const resizeEvent: IframeEventWindowResize = {
      isCidEvent: true,
      type: IframeEventType.WindowResize,
      data: {
        scrollWidth: store.state.ui.windowScrollWidth,
        scrollHeight: store.state.ui.windowScrollHeight
      }
    }

    window.parent.postMessage(JSON.stringify(resizeEvent), '*')
  }

  const commandsListener = (event: MessageEvent) => {
    const parsedCommand = event.data

    if (parsedCommand && parsedCommand.type) {
      switch (parsedCommand.type) {
        case IframeCommandType.SetIframeSettings:
          console.log(`[Iframe] New command "${parsedCommand.type}"`, parsedCommand)

          const command: SetIframeSettingsCommand = parsedCommand

          tryUpdateIframeSettings(command.returnUrl, command.colorScheme, command.textColor, command.backgroundColor,
            command.injectStyleUrl)
          break
      }
    }
  }

  // listen commands from parent
  window.addEventListener("message", commandsListener)

  watchEffect(() => {
    document.body.style.color = store.state.ui.iframeSettings?.textColor ?? ''
    document.body.style.backgroundColor = store.state.ui.iframeSettings?.backgroundColor ?? ''
  })

  watch(() => [store.state.ui.windowScrollWidth, store.state.ui.windowScrollHeight],
    sendWindowResizeEventToIframeParent)

  // set initial
  if (isIframeModeChanged) {
    const returnUrl = router.currentRoute.query.returnUrl as string | null | undefined
    const colorScheme = router.currentRoute.query.colorScheme as string | null | undefined
    const textColor = router.currentRoute.query.textColor as string | null | undefined
    const backgroundColor = router.currentRoute.query.backgroundColor as string | null | undefined
    const injectStyleUrl = router.currentRoute.query.injectStyleUrl as string | null | undefined

    if (helpers.isExists(returnUrl) || helpers.isExists(colorScheme) || helpers.isExists(textColor) ||
      helpers.isExists(backgroundColor) || helpers.isExists(injectStyleUrl)) {
      tryUpdateIframeSettings(returnUrl, colorScheme, textColor, backgroundColor, injectStyleUrl)
    }
  }

  return () => {
    window.removeEventListener("message", commandsListener)
  }
}
