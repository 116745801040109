








































































import {computed, defineComponent, nextTick, ref, watch} from "@vue/composition-api";
import useRouter from "@/router";
import {UiMutationTypes} from "@/store/modules/ui/types";
import useStore from "@/store";
import helpers from "@/utils/helpers";
import paymentApi from "@/api/payment-api";
import appUtils from "@/utils/app-utils";
import {PaymentLinkPage, PaymentLinkPageBehavior} from "@/@models";
import useNotifications from "@/components/useNotifications";
import useSharedUtils from "@/utils/useSharedUtils";

export default defineComponent({
  setup(props, context) {
    const store = useStore()
    const router = useRouter()
    const {showSuccessNotification, showErrorNotification} = useNotifications(context)
    const {closePage} = useSharedUtils()

    const paymentLinkPublicId = router.currentRoute.params.paymentLinkPublicId as string
    const returnToChannelPublicId = router.currentRoute.query.returnToChannelPublicId as string
    const returnToPremium = router.currentRoute.query.returnToPremium?.toString()
    const useTrial = router.currentRoute.query.trial?.toString() === "1"

    let telegramApp: WebApp | null = window.Telegram.WebApp

    const isButtonsDisabled = ref(false)
    const model = ref<PaymentLinkPage | null>(null)

    const getRecurringPaymentDisplayView = (name: string | null, amount: number | null) => {
      let result = ""

      if (helpers.isNotEmpty(name)) {
        result = `"${name!}"`
      }
      if (helpers.isNumeric(amount)) {
        if (helpers.isNotEmpty(result)) {
          result += " "
        }

        result += `($${amount!} / month)`
      }

      return result;
    }

    const changeRecurringPayment = () => {
      isButtonsDisabled.value = true

      paymentApi.changeRecurringPayment(paymentLinkPublicId, r => {
        if (r.success) {
          showSuccessNotification("Successfully changed")

          setTimeout(() => closePage(), 5000)
        } else {
          isButtonsDisabled.value = false
        }
      }, errorMessage => {
        showErrorNotification(errorMessage)

        isButtonsDisabled.value = false
      })
    }

    // init
    //
    store.commit(UiMutationTypes.UPDATE_GLOBAL_LOADING, true);

    paymentApi.paymentLinkPage(paymentLinkPublicId, appUtils.getRouterAbsoluteUrl({
      name: 'payment-completed',
      params: {
        paymentLinkPublicId: paymentLinkPublicId
      },
      query: {
        checkoutSessionId: 'CHECKOUT_SESSION_ID',
        returnToUrl: store.getters.iframeReturnUrl,
      }
    }), appUtils.getRouterAbsoluteUrl({
      name: 'payment-canceled',
      params: {
        paymentLinkPublicId: paymentLinkPublicId
      },
      query: {
        checkoutSessionId: 'CHECKOUT_SESSION_ID',
        returnToChannelPublicId: returnToChannelPublicId,
        returnToPremium: returnToPremium,
        returnToUrl: store.getters.iframeReturnUrl,
      }
    }), useTrial, result => {
      if (helpers.isExists(result)) {
        switch (result.behavior) {
          case PaymentLinkPageBehavior.RedirectToCheckoutUrl:
            // fix stripe dark mode issues in telegram WebApp
            telegramApp?.setBackgroundColor("#ffffff")

            appUtils.redirectToAbsoluteUrl(result.checkoutUrl!, store.state.ui.isIframeMode)
            break
          case PaymentLinkPageBehavior.RecurringPaymentChangeFromOther:
          case PaymentLinkPageBehavior.RecurringPaymentActive:
          case PaymentLinkPageBehavior.RecurringPaymentPaused:
            model.value = result

            store.commit(UiMutationTypes.UPDATE_GLOBAL_LOADING, false);
            break
        }
      } else {
        closePage()
      }
    }, errorMessage => {
      showErrorNotification(errorMessage)

      closePage()
    })

    return {
      helpers,
      PaymentLinkPageBehavior,

      paymentLinkPublicId,
      returnToChannelPublicId,

      isButtonsDisabled,
      model: model as unknown as PaymentLinkPage,

      getRecurringPaymentDisplayView,
      changeRecurringPayment,
    }
  }
})
