import { extend } from 'vee-validate';
import * as rules from 'vee-validate/dist/rules';
// @ts-ignore
import locale from 'vee-validate/dist/locale/en.json';
import Helpers from "@/utils/helpers";
import dayjs from "dayjs";

[
  "required", "min", "max", "confirmed", "email", "between", "min_value", "max_value", "digits", "length", "numeric",
  "alpha_num"
].forEach(rule => {
  extend(rule, {
    ...(<any>rules)[rule],
    message: locale.messages[rule]
  });
});

// custom validators
extend("decimal", {
  params: ['decimals', 'separator'],
  message: (field, data) => {
    const decimals = Helpers.isExists(data) && Helpers.isNotEmpty(data!.decimals) ? data!.decimals : '*';
    return `The ${field} field must be numeric and must contain ${decimals === '*' ? 'decimals' : 'up to ' + decimals + ' decimal places'}`;
  },
  // @ts-ignore
  validate: (value, { decimals = '*', separator = '.' } = {}) => {
    if (value === null || value === undefined || value === '') {
      return false;
    }

    // if is 0.
    if (Number(decimals) === 0) {
      return /^-?\d*$/.test(value);
    }

    const regexPart = decimals === '*' ? '+' : `{1,${decimals}}`;
    const regex = new RegExp(`^[-+]?\\d*(\\${separator}\\d${regexPart})?([eE]{1}[-]?\\d+)?$`);

    if (!regex.test(value)) {
      return false;
    }

    const parsedValue = parseFloat(value);

    // eslint-disable-next-line
    return parsedValue === parsedValue;
  },
});

extend("validDate", {
  params: [],
  message: 'The {_field_} field must contain a valid date',
  // @ts-ignore
  validate: (value) => {
    if (Helpers.isNotEmpty(value)) {
      return dayjs(value, "YYYY-MM-DDTHH:mm:ss.SSS[Z]", <any>true).isValid();
    }

    return false;
  },
});

extend("afterDate", {
  params: [{name: 'target', isTarget: true }],
  message: 'The {_field_} field must contain a date after {target}',
  // @ts-ignore
  validate: (value, { target } = {}) => {
    if (Helpers.isNotEmpty(value) && Helpers.isNotEmpty(target)) {
      let valueDate = dayjs(value);
      let beforeDate = dayjs(target);

      if (valueDate.isValid() && beforeDate.isValid()) {
        return beforeDate <= valueDate;
      }
    }

    return true;
  },
});

extend("futureDate", {
  params: [{name: 'target'}],
  message: 'The {_field_} field must be a date in the future',
  // @ts-ignore
  validate: (value) => {
    if (Helpers.isNotEmpty(value)) {
      let valueDate = dayjs(value);
      let currentDate = dayjs();

      if (valueDate.isValid()) {
        return valueDate > currentDate;
      }
    }

    return true;
  },
});
extend("pastDate", {
  params: [{name: 'target'}],
  message: 'The {_field_} field must be a date in the past',
  // @ts-ignore
  validate: (value) => {
    if (Helpers.isNotEmpty(value)) {
      let valueDate = dayjs(value);
      let currentDate = dayjs();

      if (valueDate.isValid()) {
        return valueDate <= currentDate;
      }
    }

    return true;
  },
});

export const urlPathValidation = (value: string | null | undefined) => {
  return Helpers.isNotEmpty(value) && /^http[s]?:\/\/(www\.)?(.*)?\/?(.)*/.test(value);
}

extend('urlPath', {
  validate: (value: string | null | undefined) => urlPathValidation(value),
  message: field => 'The ' + field + ' field must be a valid URL',
})

extend('appPassword', {
  message: field => 'The ' + field + ' field must contain 1 lowercase letter, 1 uppercase letter and 1 number',
  validate: (value) => {
    let hasUpperCaseLetter = false;
    let hasLowerCaseLetter = false;
    let hasDecimalDigit = false;

    for (let i = 0; i < value.length; i++) {
      const character = value.charAt(i);

      // (important) check isNumeric first
      if (Helpers.isNumeric(character)) hasDecimalDigit = true;
      else if (character === character.toUpperCase()) hasUpperCaseLetter = true;
      else if (character === character.toLowerCase()) hasLowerCaseLetter = true;
    }
    return hasUpperCaseLetter && hasLowerCaseLetter && hasDecimalDigit;
  }
});

extend('noStringSpaces', {
  message: field => 'The ' + field + ' field must not contain a space',
  validate: (value) => !/\s/g.test(value)
});

extend('appUsername', {
  message: field => 'The ' + field + ' field format is invalid',
  validate: (value) => {
    // https://stackoverflow.com/questions/12018245/regular-expression-to-validate-username/12019115
    return /^(?=[a-zA-Z0-9._]{6,20}$)(?!.*[_.]{2})[^_.].*[^_.]$/i.test(value);
  }
});

extend('mask', {
  params: ['mask'],
  message: field => 'The ' + field + ' field format is invalid',
  // @ts-ignore
  validate: (value, { mask } = {}) => {
    return Helpers.isNotEmpty(value) && value!.length === mask.length;
  }
});

extend('stringKey', {
  message: field => 'The ' + field + ' field format is invalid',
  validate: (value) => {
    return /^[a-z_][a-z0-9_]*$/.test(value);
  }
});