



















































import {computed, defineComponent, nextTick, PropType, ref, watch, WritableComputedRef} from "@vue/composition-api";
import useRouter from "@/router";
import useStore from "@/store";
import helpers from "@/utils/helpers";
import appUtils from "@/utils/app-utils";
import useNotifications from "@/components/useNotifications";
import {AiAgent, AiAgentLanguage, AiAgentStyle, AiAgentType, AiAgentRole, Guid} from "@/@models";
import PageLoadingIndicator from "@/views/_components/PageLoadingIndicator.vue";
import useSharedUtils from "@/utils/useSharedUtils";
import BSelectFormGroup from "@/views/_controls/BSelectFormGroup.vue";
import aiAgentApi from "@/api/ai-agent-api";
import BTextareaFormGroup from "@/views/_controls/BTextareaFormGroup.vue";

export default defineComponent({
  components: {BTextareaFormGroup, BSelectFormGroup, PageLoadingIndicator},
  props: {
    aiAgentLanguageId: String as PropType<Guid | null>,
    aiAgentRoleId: String as PropType<Guid | null>,
    aiAgentStyleId: String as PropType<Guid | null>,
    disabled: Boolean
  },
  setup(props, context) {
    const store = useStore()
    const {showSuccessNotification, showErrorNotification} = useNotifications(context)

    const isLoading = ref(true)

    const isButtonsDisabledLocal = ref(false)
    const isButtonsDisabledFinal = computed(() => props.disabled || isButtonsDisabledLocal.value)

    const aiAgentLanguageIdValue: WritableComputedRef<Guid | null> = computed({
      get() {
        return props.aiAgentLanguageId as Guid | null;
      },
      set(val) {
        context.emit('update:aiAgentLanguageId', val);
      }
    })

    const aiAgentRoleIdValue: WritableComputedRef<Guid | null> = computed({
      get() {
        return props.aiAgentRoleId as Guid | null;
      },
      set(val) {
        context.emit('update:aiAgentRoleId', val);
      }
    })

    const aiAgentStyleIdValue: WritableComputedRef<Guid | null> = computed({
      get() {
        return props.aiAgentStyleId as Guid | null;
      },
      set(val) {
        context.emit('update:aiAgentStyleId', val);
      }
    })

    const aiAgentLanguages = ref<AiAgentLanguage[] | null>(null)
    const aiAgentRoles = ref<AiAgentRole[] | null>(null)
    const aiAgentStyles = ref<AiAgentStyle[] | null>(null)

    // init
    //
    // lookup
    aiAgentApi.aiAgentLanguages(result => aiAgentLanguages.value = result.models,
      errorMessage => showErrorNotification(errorMessage))
    aiAgentApi.aiAgentRoles(result => aiAgentRoles.value = result.models,
      errorMessage => showErrorNotification(errorMessage))
    aiAgentApi.aiAgentStyles(result => aiAgentStyles.value = result.models,
      errorMessage => showErrorNotification(errorMessage))

    isLoading.value = false

    return {
      helpers,

      isLoading,
      isButtonsDisabledFinal,

      aiAgentLanguageIdValue,
      aiAgentRoleIdValue,
      aiAgentStyleIdValue,

      aiAgentLanguages,
      aiAgentRoles,
      aiAgentStyles,
    }
  }
})
