// VueCompositionAPI
import Vue from "vue";
import VueCompositionAPI from "@vue/composition-api";
Vue.use(VueCompositionAPI);

// App Styles
import '@/assets/scss/styles.scss'

import {createApp} from "@vue/composition-api";
import App from './App.vue'
import useRouter from "@/router";
import useStore from "@/store";

const app = createApp(App);

// Portal Vue
import PortalVue from 'portal-vue'
app.use(PortalVue);

// Bootstrap Vue
// main
import BootstrapVue from 'bootstrap-vue'
app.use(BootstrapVue);

// PerfectScrollbar
import PerfectScrollbar from 'vue2-perfect-scrollbar'
import 'vue2-perfect-scrollbar/dist/vue2-perfect-scrollbar.css'
Vue.use(PerfectScrollbar)

// DayJS
import dayjs from 'dayjs'
import 'dayjs/locale/en'
import localizedFormat from 'dayjs/plugin/localizedFormat';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import relativeTime from 'dayjs/plugin/relativeTime';
import utc from 'dayjs/plugin/utc'
dayjs.extend(localizedFormat);
dayjs.extend(customParseFormat)
dayjs.extend(relativeTime);
dayjs.extend(utc);
dayjs.locale('en');

// VeeValidate
import "@/components/vee-validate-setup"

Vue.config.productionTip = false

// @ts-ignore
window.Vue = new Vue({ router: useRouter(), store: useStore(), render: h => h(App) }).$mount('#app');
