












import {
  computed,
  defineComponent,
  nextTick,
  onBeforeUnmount,
  onMounted,
  watch,
  watchEffect
} from "@vue/composition-api";
import cssVars from "css-vars-ponyfill";
import useStore from "@/store";
import useEmitter, {EmitterEvent} from "@/components/emitter";
import useRouter from "@/router";
import helpers from "@/utils/helpers";
import classNames from "classnames/dedupe";
import {UiMutationTypes} from "@/store/modules/ui/types";
import {ContextMutationTypes} from "@/store/modules/context/types";
import devUtils from "@/utils/devUtils";
import debounce from "debounce";
import tryStartIframeMode from "@/components/tryStartIframeMode";
import appUtils from "@/utils/app-utils";

export default defineComponent({
  setup(props, context) {
    const store = useStore();
    const emitter = useEmitter();
    const router = useRouter();

    const hasCSSVarSupport = typeof window !== 'undefined' && window.CSS && window.CSS.supports && window.CSS.supports('(--a: 0)');

    const iframeInjectStyleUrl = computed(() =>
      store.state.ui.isIframeMode ? store.state.ui.iframeSettings?.injectStyleUrl : null)

    const logoutEvent = () => {
      // store.dispatch(AuthActionTypes.LOGOUT, {
      //   caslAbility: caslAbility
      // });

      // try {
      //   router.push('/login');
      // } catch {}
    }

    const tryUpdateWindowSize = (isInit: boolean) => {
      const newWidth = window.innerWidth
      const newHeight = window.innerHeight
      const newDocumentScrollWidth = document.documentElement.scrollWidth
      const newDocumentScrollHeight = document.documentElement.scrollHeight

      if (store.state.ui.windowWidth !== newWidth ||
        store.state.ui.windowHeight !== newHeight ||
        store.state.ui.windowScrollWidth !== newDocumentScrollWidth ||
        store.state.ui.windowScrollHeight !== newDocumentScrollHeight) {

        store.commit(UiMutationTypes.UPDATE_WINDOW_SIZE, {
          width: newWidth,
          height: newHeight,
          scrollWidth: newDocumentScrollWidth,
          scrollHeight: newDocumentScrollHeight,
        });

        if (isInit) {
          let vh = newHeight * 0.01;

          // required for mobile
          if (isInit) {
            document.documentElement.style.setProperty('--vh', `${vh}px`);
          }

          changeVhVariable(vh, () => {
            emitter.emit(EmitterEvent.AfterWindowResizeCompleted);
          });
        } else {
          emitter.emit(EmitterEvent.AfterWindowResizeCompleted);
        }
      }
    }

    const updateDarkColorScheme = (isDark: boolean) => {
      if (store.state.ui.isDarkColorScheme === isDark) return

      store.commit(UiMutationTypes.UPDATE_DARK_COLOR_SCHEME, isDark);
    }

    // https://github.com/jhildenbiddle/css-vars-ponyfill/issues/93
    const changeVhVariable = (vh: number, afterChangeCb: () => void) => {
      cssVars({
        variables: { '--vh': `${vh}px` },
        // Legacy browsers
        onComplete: (cssText: any, styleElms, cssVariables, benchmark) => {
          if (afterChangeCb) {
            afterChangeCb();
          }
        }
      });

      // Modern browsers
      if (hasCSSVarSupport) {
        if (afterChangeCb) {
          afterChangeCb();
        }
      }
    }

    const handleOrientationChange = () => {
      helpers.orientationChanged().then(() => {
        setBodyMobileClasses();
        tryUpdateWindowSize(false);
      });
    }

    const handleDarkColorSchemeChange = (event: MediaQueryListEvent) => {
      updateDarkColorScheme(event.matches)
    }

    const setBodyMobileClasses = () => {
      const isLandscape = window.innerWidth > window.innerHeight;
      document.body.className = classNames(document.body.className, {
        "orientation-portrait": !isLandscape,
      });
    }

    watchEffect(() => {
      store.commit(UiMutationTypes.UPDATE_WITH_FOOTER, router.currentRoute.matched.some(r => r.meta.showFooter))
    })

    watchEffect(() => {
      let isDarkColorScheme = store.state.ui.isDarkColorScheme

      if (store.state.ui.iframeSettings?.isDarkColorSchemeForced) {
        isDarkColorScheme = true
      } else if (store.state.ui.iframeSettings?.isLightColorSchemeForced) {
        isDarkColorScheme = false
      }

      document.body.className = classNames(document.body.className, {
        "loading": store.state.ui.isGlobalLoading,
        "iframe-mode": store.state.ui.isIframeMode,
        "dark-theme": !store.state.context.isTelegramAppThemeEnabled && isDarkColorScheme,
        "telegram-webapp-theme": store.state.context.isTelegramAppThemeEnabled,
        "telegram-webapp-light-theme": store.state.context.isTelegramAppThemeEnabled &&
          !store.state.context.isTelegramAppDarkTheme,
        "telegram-webapp-dark-theme": store.state.context.isTelegramAppThemeEnabled &&
          store.state.context.isTelegramAppDarkTheme
      });
    })
    watch(() => store.state.context.telegramAppBgColorHex, value => {
      if (helpers.isHexColor(store.state.context.telegramAppBgColorHex)) {
        const pageLoadingElement = document.getElementById('page-loading-bg')

        if (pageLoadingElement) {
          pageLoadingElement.style.backgroundColor = store.state.context.telegramAppBgColorHex!
        }
      }
    }, { immediate: true })

    const tryUpdateTelegramAppContext = () => {
      if (helpers.isExists(window.Telegram)) {
        devUtils().tryApplyDevTelegramUserEmulation(window.Telegram)

        const telegramApp = window.Telegram.WebApp;

        const isTelegramApp = helpers.isExists(telegramApp)
        const isTelegramAppAuth = helpers.isNotEmpty(telegramApp.initData)

        if (isTelegramAppAuth) {
          console.log("[WebApp] Telegram init with auth", telegramApp)
        } else {
          console.log("[WebApp] Telegram init", telegramApp)
        }

        store.commit(ContextMutationTypes.SET_TELEGRAM_APP_CONTEXT, {
          isTelegramApp: isTelegramApp,
          isTelegramAppAuth: isTelegramAppAuth,
          isTelegramAppThemeEnabled: isTelegramApp,
          isTelegramAppDarkTheme: telegramApp.colorScheme === "dark",
          telegramAppBgColorHex: telegramApp.backgroundColor
        })

        // expand to fullscreen
        telegramApp.expand();
      }
    }

    tryUpdateTelegramAppContext();

    const tryUpdateWindowSizeWithTimer = debounce(() => {
      tryUpdateWindowSize(false);
    }, 100);

    const updateWindowSizeResizeObserver = new ResizeObserver(() => tryUpdateWindowSizeWithTimer())
    const darkColorSchemeMedia = window.matchMedia('(prefers-color-scheme: dark)')

    // iframe mode
    const stopIframeMode = tryStartIframeMode()

    onMounted(() => {
      if (!helpers.isExists(window.Telegram)) {
        console.log("[TelegramApp] WebApp is not init, reload page")
        appUtils.reloadCurrentPage()
      }

      updateWindowSizeResizeObserver.observe(document.body)

      nextTick(() => {
        window.addEventListener('orientationchange', handleOrientationChange);

        darkColorSchemeMedia.addEventListener('change', handleDarkColorSchemeChange);
      });

      // init
      tryUpdateWindowSize(true);
      updateDarkColorScheme(darkColorSchemeMedia.matches)
      // required for mobile
      setBodyMobileClasses();
    });

    onBeforeUnmount(() => {
      // global event
      emitter.off(EmitterEvent.Logout, logoutEvent);

      updateWindowSizeResizeObserver.disconnect()

      window.removeEventListener('orientationchange', handleOrientationChange);

      darkColorSchemeMedia.removeEventListener('change', handleDarkColorSchemeChange)

      stopIframeMode()
    })

    // nextTick(() => {
    //   store.dispatch(AuthActionTypes.TRY_INIT_AUTH_USER, {
    //     caslAbility: caslAbility
    //   });
    // })

    document.body.className = classNames(document.body.className, {
      "hold-transition": true,
    });

    // global event
    emitter.on(EmitterEvent.Logout, logoutEvent);

    // Remove initial page loading
    store.commit(UiMutationTypes.UPDATE_GLOBAL_LOADING, false);

    return {
      helpers,
      iframeInjectStyleUrl
    }
  }
})
