



























import {
  ComponentPropsOptions,
  computed,
  Data,
  defineComponent,
  onMounted,
  PropType,
  ref,
  watch
} from "@vue/composition-api";
import Helpers from "@/utils/helpers";
import fileUtils from "@/utils/file-utils";
import {FileFullInfo} from "@/@models";

export type SupportedPreviewType = "image" | "audio" | "file"

let FilePreviewItemView_AudioPlayerComponentId = 1;

export default defineComponent({
  props: {
    fileInfo: Object as PropType<FileFullInfo | null>,
    previewType: String as PropType<SupportedPreviewType>,
    imgStyle: [Object, String],
    showEmpty: Boolean,
    smallPreview: Boolean
  },
  setup(props, context) {
    const audioPlayerComponentId = FilePreviewItemView_AudioPlayerComponentId;
    FilePreviewItemView_AudioPlayerComponentId += 1;

    const getAudioPlayerComponent = () => {
      return context.refs[audioPlayerComponentId] as InstanceType<typeof Audio>;
    }

    const imgStyleMerged = computed(()=> {
      return Object.assign({
        maxWidth: props.smallPreview ? '100px' : '300px',
        maxHeight: props.smallPreview ? '100px' : '300px'
      }, props.imgStyle);
    })

    const getFileUrl = (info: FileFullInfo | null | undefined) => {
      switch (props.previewType) {
        case 'image':
          return fileUtils.fileInfoImageUrl(info);
        default:
          return fileUtils.fileInfoUrl(info);
      }
    }

    const getFileName = (info: FileFullInfo | null | undefined) => {
      if (Helpers.isExists(info)) return info!.fileName

      return null
    }

    const getFileMimeType = (info: FileFullInfo | null | undefined) => {
      const fileExtensions = info?.fileExtension?.toLowerCase()

      if (Helpers.isNotEmpty(fileExtensions)) {
        if (fileExtensions === '.mp3') return 'audio/mpeg'
        if (fileExtensions === '.wav') return 'audio/wav'

        return 'application/octet-stream'
      } else {
        return ''
      }
    }

    watch(() => props.previewType === 'audio' ? props.fileInfo : null, (val) => {
      if (Helpers.isExists(val)) {
        getAudioPlayerComponent()?.load();
      }
    })

    return {
      helpers: Helpers,
      fileUtils: fileUtils,

      audioPlayerComponentId,

      imgStyleMerged,

      getFileUrl,
      getFileName,
      getFileMimeType,
    }
  }
})
