








































import {computed, defineComponent, nextTick, ref} from "@vue/composition-api";
import useStore from "@/store";
import useRouter from "@/router";
import helpers from "@/utils/helpers";

interface MenuItem {
  url: string
  title?: string
  icon?: string

  children?: MenuItem[]
}

export default defineComponent({
  setup(props, context) {
    const router = useRouter()

    const menuItems = ref<MenuItem[]>([]);

    const isActiveUrl = (path: string) => {
      return context.root.$route.fullPath.indexOf(path) !== -1;
    }

    const updateMenuItems = () => {
      if (!helpers.isExists(router.options.routes)) return

      const adminRoot = helpers.findFirstInArray(router.options.routes, x => x.path === "/admin")
      const adminRoutes = adminRoot?.children

      if (!helpers.isNotEmptyArray(adminRoutes)) return

      let rootPrefix = adminRoot!.path

      if (rootPrefix.slice(-1) !== "/") {
        rootPrefix += "/";
      }

      nextTick(() => {
        let items = <MenuItem[]>[];

        for (let i = 0; i < adminRoutes.length; i++) {
          const route = adminRoutes[i];

          if (!helpers.isExists(route.meta) || !helpers.isNotEmpty(route.meta.title)) continue;


          let children: MenuItem[] = [];

          if (helpers.isNotEmptyArray(route.children)) {
            let childRoutePrefix = route.path;

            if (childRoutePrefix.slice(-1) !== "/") {
              childRoutePrefix += "/";
            }

            for (let j = 0; j < route.children!.length; j++) {
              let childRoute = route.children![j];

              if (!helpers.isExists(childRoute.meta) || !helpers.isNotEmpty(childRoute.meta.title)) continue

              children.push({
                url: rootPrefix + childRoutePrefix + childRoute.path,
                title: childRoute.meta.title,
                icon: childRoute.meta.icon,
              });
            }
          }

          items.push({
            url: rootPrefix + route.path,
            title: route.meta.title,
            icon: route.meta.icon,
            children: children,
          });
        }

        menuItems.value = items
      })
    }

    updateMenuItems()

    return {
      helpers,

      menuItems,

      isActiveUrl,
    }
  },
})
