







import {computed, defineComponent} from "@vue/composition-api";

export default defineComponent({
  props: {
    active: Boolean
  },
  setup(props, context) {
  },
})
