
import helpers from "@/utils/helpers";
import useStore from "@/store";
import useRouter from "@/router";
import {isCurrentTelegramUserDev} from "@/utils/devUtils";

export default function () {
  const store = useStore()
  const router = useRouter()

  const closePage = () => {
    if (process.env.NODE_ENV === 'development' || isCurrentTelegramUserDev()) {
      console.log("=========== TELEGRAM APP CLOSE PAGE ============")
      return
    }

    if (store.state.context.isTelegramApp) {
      const telegramApp = window.Telegram.WebApp
      telegramApp.close();
    } else {
      // todo error
      router.push("/")
    }
  }

  return {
    closePage,
  }
}
