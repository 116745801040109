import {
  Guid,
  SystemAccount
} from '@/@models'
import PortalApiBase from "@/api/portal-api-base";
import appUtils from "@/utils/app-utils";
import {ApiEmptyErrorCallback, ApiEmptySuccessCallback, ApiErrorCallback, ApiSuccessCallback} from "@/api/api-base";

export default new class extends PortalApiBase {
  getAccountsForTable(successCb: ApiSuccessCallback<SystemAccount[]>,
                      errorCb: ApiErrorCallback<SystemAccount[]>) {
    return this._defaultPostResponse('/account/table', {
      telegramInitData: appUtils.getTelegramInitData()
    }, null, successCb, errorCb);
  }

  changeAllPremiumFeaturesAccess(accountId: Guid, isUnlocked: boolean,
                                 successCb: ApiEmptySuccessCallback,
                                 errorCb: ApiEmptyErrorCallback) {
    return this._defaultEmptyPostResponse('/account/change-all-premium-features-access', {
      accountId, isUnlocked, telegramInitData: appUtils.getTelegramInitData()
    }, null, successCb, errorCb);
  }
}
