import {SetupContext} from "@vue/composition-api";
import {BvToast} from "bootstrap-vue";

// todo rewrite to global instance
export default function useNotifications(context: SetupContext<any>) {
  const getBvToast = () => context.root.$bvToast;

  const showSuccessNotification = (message?: string | null, title?: string | null) => {
    getBvToast().toast(message ?? "👍", {
      title: title ?? "Success",
      variant: 'success',
    });
  };

  const showInfoNotification = (message?: string | null, title?: string | null) => {
    getBvToast().toast(message ?? "ℹ️", {
      title: title ?? "Info",
      variant: 'info',
    });
  };

  const showWarningNotification = (message?: string | null, title?: string | null) => {
    getBvToast().toast(message ?? "⚠️", {
      title: title ?? "Warning",
      variant: 'warning',
    });
  };

  const showErrorNotification = (message?: string | null, title?: string | null) => {
    getBvToast().toast(message ?? "Unknown server error", {
      title: title ?? "Error",
      variant: 'danger',
    });
  };

  return {
    showSuccessNotification,
    showInfoNotification,
    showWarningNotification,
    showErrorNotification,
  };
}
