import {
  Guid,
  PortalSubscriptionAdminTable,
  PortalSubscriptionAdminEdit
} from '@/@models'
import PortalApiBase from "@/api/portal-api-base";
import appUtils from "@/utils/app-utils";
import {ApiErrorCallback, ApiSuccessCallback} from "@/api/api-base";

export default new class extends PortalApiBase {
  getPortalSubscriptionsForTable(successCb: ApiSuccessCallback<PortalSubscriptionAdminTable[]>,
              errorCb: ApiErrorCallback<PortalSubscriptionAdminTable[]>) {
    return this._defaultPostResponse('/portal/portal-subscription-table', {
      telegramInitData: appUtils.getTelegramInitData()
    }, null, successCb, errorCb);
  }

  getPortalSubscriptionForEdit(id: Guid,
             successCb: ApiSuccessCallback<PortalSubscriptionAdminEdit>,
             errorCb: ApiErrorCallback<PortalSubscriptionAdminEdit>) {
    return this._defaultPostResponse('/portal/get-edit-portal-subscription', {
      id, telegramInitData: appUtils.getTelegramInitData()
    }, null, successCb, errorCb);
  }

  updatePortalSubscription(model: PortalSubscriptionAdminEdit,
         successCb: ApiSuccessCallback<Guid | null>,
         errorCb: ApiErrorCallback<Guid | null>) {
    return this._defaultPostResponse('/portal/edit-portal-subscription', {
      model, telegramInitData: appUtils.getTelegramInitData()
    }, null, successCb, errorCb);
  }
}
