
import axios, { AxiosInstance, AxiosRequestConfig } from 'axios'
import qs from 'qs'
import helpers from '@/utils/helpers'
import {emitter, EmitterEvent} from "@/components/emitter";
import useStore from "@/store";

class HttpClient {
  private _axiosInstance: AxiosInstance | null = null;
  private readonly _baseApiUrl: string | null | undefined = null;

  constructor(baseUrl?: string) {
    this._baseApiUrl = baseUrl;
  }

  private tryInit() {
    const store = useStore();

    if (!helpers.isExists(this._axiosInstance)) {
      const instance = axios.create({
        baseURL: this._baseApiUrl ?? '/api',
        withCredentials: true,
        validateStatus: (status) => status >= 200 && status < 600,
        paramsSerializer: p => {
          return qs.stringify(p)
        }
      });

      this._axiosInstance = instance;
    }
  }
  get<TResponse>(url: string, config?: AxiosRequestConfig) {
    this.tryInit();

    return this._axiosInstance!.get<TResponse>(url, config)
      .then((resp) => {
        return Promise.resolve(resp)
      })
      // @ts-ignore
      .catch((resp) => {
        if (resp.response !== undefined &&
          (resp.response.status == 401 ||
            resp.response.status == 403 ||
            resp.response.status == 405)) {
          this.logoutUser();
        } else {
          return Promise.reject(resp)
        }})
  }
  post<TResponse>(url: string, data?: any, config?: AxiosRequestConfig) {
    this.tryInit();

    return this._axiosInstance!.post<TResponse>(url, data, config)
      .then((resp) => {
        return Promise.resolve(resp)
      })
      // @ts-ignore
      .catch((resp) => {
        if (resp.response !== undefined &&
          (resp.response.status == 401 ||
            resp.response.status == 403 ||
            resp.response.status == 405)) {
          this.logoutUser();
        } else {
          return Promise.reject(resp)
        }})
  }

  private logoutUser() {
    // trigger global event
    emitter.emit(EmitterEvent.Logout);
  }
}

const httpClient = new HttpClient(MAIN_API_URL);

export function useHttpClient(): HttpClient {
  return httpClient;
}

export default httpClient;