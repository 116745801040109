
import {
  Guid, AiAgents, AiAgentLanguages, AiAgentRoles, AiAgentStyles, AiAgentType
} from '@/@models'
import ClientApiBase from "@/api/client-api-base";
import appUtils from "@/utils/app-utils";
import {ApiErrorCallback, ApiSuccessCallback} from "@/api/api-base";

export default new class extends ClientApiBase {
  aiAgents(type: AiAgentType,
           successCb: ApiSuccessCallback<AiAgents>,
           errorCb: ApiErrorCallback<AiAgents>,
           onlyIds: Guid[] | null = null) {
    return this._defaultPostResponse('/ai-agents/ai-agents', {
      type, onlyIds, telegramInitData: appUtils.getTelegramInitData()
    }, null, successCb, errorCb);
  }
  aiAgentLanguages(successCb: ApiSuccessCallback<AiAgentLanguages>,
                   errorCb: ApiErrorCallback<AiAgentLanguages>,
                   onlyIds: Guid[] | null = null) {
    return this._defaultPostResponse('/ai-agents/ai-agent-languages', {
      onlyIds, telegramInitData: appUtils.getTelegramInitData()
    }, null, successCb, errorCb);
  }
  aiAgentRoles(successCb: ApiSuccessCallback<AiAgentRoles>,
               errorCb: ApiErrorCallback<AiAgentRoles>,
               onlyIds: Guid[] | null = null) {
    return this._defaultPostResponse('/ai-agents/ai-agent-roles', {
      onlyIds, telegramInitData: appUtils.getTelegramInitData()
    }, null, successCb, errorCb);
  }
  aiAgentStyles(successCb: ApiSuccessCallback<AiAgentStyles>,
                errorCb: ApiErrorCallback<AiAgentStyles>,
                onlyIds: Guid[] | null = null) {
    return this._defaultPostResponse('/ai-agents/ai-agent-styles', {
      onlyIds, telegramInitData: appUtils.getTelegramInitData()
    }, null, successCb, errorCb);
  }
}