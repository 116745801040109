
import {
  Guid, Causes
} from '@/@models'
import ClientApiBase from "@/api/client-api-base";
import appUtils from "@/utils/app-utils";
import {ApiErrorCallback, ApiSuccessCallback} from "@/api/api-base";

export default new class extends ClientApiBase {
  list(successCb: ApiSuccessCallback<Causes>,
       errorCb: ApiErrorCallback<Causes>,
       onlyIds: Guid[] | null = null, treeView: boolean | null = null) {
    return this._defaultPostResponse('/causes/list', {
      onlyIds, treeView, telegramInitData: appUtils.getTelegramInitData()
    }, null, successCb, errorCb);
  }
}