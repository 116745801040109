














































import {computed, defineComponent, PropType, Ref, ref, watch, watchEffect} from "@vue/composition-api";
import {Cause, Guid} from "@/@models";
import helpers from "@/utils/helpers";
import BCheckboxFormGroup from "@/views/_controls/BCheckboxFormGroup.vue";

export default defineComponent({
  name: "CauseListGroupItemView",
  components: {BCheckboxFormGroup},
  props: {
    cause: { type: Object as PropType<Cause>, required: true },
    checkSelected: { type: Function as PropType<(causeId: Guid) => boolean>, required: true },
    childLevel: { type: Number, required: true },
    disabled: Boolean
  },
  emits: ['cause-selection-changed'],
  setup(props, context) {
    const isSelected = ref(props.checkSelected(props.cause.id))

    const isChildCausesOpen = ref(false)

    const isChildrenExists = computed(() => helpers.isNotEmptyArray(props.cause.children))
    const childLevelMarginPx = computed(() => (props.childLevel * 15) + 'px')

    const selectedChildrenCount = computed(() => {
      if (!helpers.isNotEmptyArray(props.cause.children)) return 0

      let count = 0
      for (const child of props.cause.children) {
        if (props.checkSelected(child.id)) {
          count++
        }
      }

      return count
    })

    const toggleChildCausesOpen = () => {
      isChildCausesOpen.value = !isChildCausesOpen.value
    }

    const changeCauseSelection = (causeId: Guid, isSelected: Ref<boolean>) => {
      context.emit("cause-selection-changed", causeId, isSelected)
    }

    watch(isSelected, () => {
      context.emit("cause-selection-changed", props.cause.id, isSelected)
    })

    return {
      helpers,

      isSelected,

      isChildCausesOpen,
      isChildrenExists,
      childLevelMarginPx,

      selectedChildrenCount,

      toggleChildCausesOpen,
      changeCauseSelection,
    }
  },
})
