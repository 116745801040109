

























































import {computed, defineComponent, onMounted, ref, watch} from "@vue/composition-api";
import useStore from "@/store";
import {
  PortalSubscriptionAdminTable
} from "@/@models";
import useConfig from "@/utils/useConfig";
import useNotifications from "@/components/useNotifications";
import portalPortalApi from "@/api/portal/portal-portal-api";
import helpers from "@/utils/helpers";
import ActiveIndicator from "@/views/_components/ActiveIndicator.vue";

export default defineComponent({
  components: {
    ActiveIndicator
  },
  setup(props, context) {
    const config = useConfig()
    const { showErrorNotification } = useNotifications(context)

    const isButtonsDisabled = ref(false)

    const table = ref({
      isLoading: false,
      currentPage: 1,
      totalCount: -1,

      fields: [
        {
          key: 'actions',
          label: ''
        },
        {
          key: 'active',
          label: 'Active',
        },
        {
          key: 'name',
          label: 'Name'
        },
        {
          key: 'trialPeriodDays',
          label: 'Trial',
          formatter: (value: number, key: string, item: PortalSubscriptionAdminTable) =>
            helpers.isNumeric(value) ? `${value} days` : 'No'
        },
        {
          key: 'contentSourceLimit',
          label: 'Content Source Limit',
          formatter: (value: number, key: string, item: PortalSubscriptionAdminTable) =>
            helpers.isNumeric(value) ? `${value}` : 'Unlimited'
        },
        {
          key: 'aiTokenLimit',
          label: 'AI Token Limit',
          formatter: (value: number, key: string, item: PortalSubscriptionAdminTable) =>
            helpers.isNumeric(value) ? `${value}` : 'Unlimited'
        },
      ]
    })

    const allItems = ref<PortalSubscriptionAdminTable[] | null>(null)

    const updateAllItems = () => {
      table.value.isLoading = true
      isButtonsDisabled.value = true

      portalPortalApi.getPortalSubscriptionsForTable(result => {
        allItems.value = result

        table.value.totalCount = allItems.value.length
        isButtonsDisabled.value = false;
        table.value.isLoading = false;
      }, errorMessage => {
        showErrorNotification(errorMessage)

        isButtonsDisabled.value = false;
        table.value.isLoading = false;
      });
    }

    const tableRowClass = (item: PortalSubscriptionAdminTable) => {
      if (item.isDisabled) return "table-danger";
      return '';
    }

    // init
    //
    updateAllItems()

    return {
      config,

      isButtonsDisabled,

      table,
      allItems,

      tableRowClass
    }
  }
})
