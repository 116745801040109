


































































import {computed, defineComponent, nextTick, onMounted, Ref, ref} from "@vue/composition-api";
import {
  FileMetatype,
  Guid, KnowledgeBaseArticleLocalizationEdit, LocalizationInfo, Localizations
} from "@/@models";
import helpers from "@/utils/helpers";
import useNotifications from "@/components/useNotifications";
import useRouter from "@/router";
import useStore from "@/store";
import {ValidationObserver} from "vee-validate";
import BCheckboxFormGroup from "@/views/_controls/BCheckboxFormGroup.vue";
import BTextInputFormGroup from "@/views/_controls/BTextInputFormGroup.vue";
import BRichTextFormGroup from "@/views/_controls/BRichTextFormGroup.vue";
import {
  KnowledgeBaseArticleEdit,
  KnowledgeBaseArticleType
} from "@/@models";
import knowledgeBasePortalApi from "@/api/portal/knowledge-base-portal-api";
import localizationPortalApi from "@/api/portal/localization-portal-api";
import AppLocalizationFields from "@/views/_components/AppLocalizationFields.vue";

export default defineComponent({
  components: {
    AppLocalizationFields,
    ValidationObserver, BCheckboxFormGroup, BTextInputFormGroup, BRichTextFormGroup,
  },
  setup(props, context) {
    const store = useStore();
    const { showSuccessNotification, showErrorNotification, showInfoNotification } = useNotifications(context);

    const isButtonsDisabled = ref(false);
    const isPopupVisible = ref(false);

    const isLoading = ref(true)

    const localizations = ref<Localizations | null>(null)
    const selectedLocalization = computed(() => {
      if (!helpers.isExists(localizations.value)) return null

      return helpers.findFirstInArray(localizations.value.localizations,
        x => x.id === localizations.value!.selectedId)
    })
    const model = ref<KnowledgeBaseArticleEdit | null>(null)
    const modelSelectedLocalization = computed(() => {
      if (!helpers.isExists(model.value) || !helpers.isExists(selectedLocalization.value)) return null

      return helpers.findFirstInArray(model.value.localizations,
        x => x.localizationId === selectedLocalization.value!.id)
    })

    let onCreateOrUpdated: ((isCreate: boolean, id: Guid, publicId: string) => void) | null | undefined = null
    const idParam = ref<Guid | null>(null)
    const publicIdParam = ref<string | null>(null)
    const isCreateParam = ref<boolean | null>(null)
    const createTypeParam = ref<KnowledgeBaseArticleType | null>(null)
    const createInitialContentParam = ref<string | null>(null)
    const openPopup = (id: Guid | null, publicId: string | null, isCreate: boolean,
                       createType: KnowledgeBaseArticleType | null, createInitialContent: string | null,
                       createOrUpdatedCb?: (isCreate: boolean, id: Guid, publicId: string) => void) => {
      idParam.value = id
      publicIdParam.value = publicId
      isCreateParam.value = isCreate
      createTypeParam.value = createType
      createInitialContentParam.value = createInitialContent
      onCreateOrUpdated = createOrUpdatedCb;

      initData()

      isPopupVisible.value = true;
    }
    const onPopupClosed = () => {
      // reset all
      model.value = null

      isLoading.value = true;
      isButtonsDisabled.value = false;
    }

    const close = () => {
      isPopupVisible.value = false;
    }

    const getFormValidation = () => context.refs.formValidation as InstanceType<typeof ValidationObserver> | null;
    const saveChanges = async () => {
      if (!helpers.isExists(model.value)) return

      isButtonsDisabled.value = true;

      let isValid = await getFormValidation()?.validate() ?? false;

      if (isValid) {
        knowledgeBasePortalApi.createOrUpdateArticle(model.value, (result, r) => {
          if (r.isAnyChanges) {
            if (helpers.isFunction(onCreateOrUpdated))
              onCreateOrUpdated(result.isCreate, result.id, result.publicId)

            showSuccessNotification("Changes successfully saved")
          } else {
            showInfoNotification("No update required, resources are up to date")
          }

          close()

          isButtonsDisabled.value = false;
        }, errorMessage => {
          isButtonsDisabled.value = false;

          showErrorNotification(errorMessage)
        });
      } else {
        isButtonsDisabled.value = false;

        showErrorNotification("Error, please check the fields on the page")
      }
    }

    const updateLocalizations = (cb?: (localizations: Localizations) => void) => {
      localizationPortalApi.getLocalizations(result => {
        localizations.value = result

        if (helpers.isFunction(cb))
          cb(localizations.value)
      }, errorMessage => {
        showErrorNotification(errorMessage);
      });
    }

    const updateEditModel = (id: Guid | null, publicId: string | null) => {
      knowledgeBasePortalApi.getArticle(id, publicId, result => {
        model.value = result

        isLoading.value = false;
      }, errorMessage => {
        showErrorNotification(errorMessage);

        isLoading.value = false;
      });
    }

    const initData = () => {
      // init
      //
      if (isCreateParam.value) {
        if (!helpers.isNumeric(createTypeParam.value)) throw new Error("createType is required")

        updateLocalizations((localizations) => {
          model.value = {
            id: null,

            type: createTypeParam.value,

            isPublished: false,
            localizations: localizations.localizations.map(x => {
              return {
                localizationId: x.id,

                title: null,
                contentHtml: createInitialContentParam.value
              }
            })
          }

          isLoading.value = false;
        })
      } else {
        if (!helpers.isNotEmpty(idParam.value) && !helpers.isNotEmpty(publicIdParam.value))
          throw new Error("id or publicId is required")

        updateLocalizations((localizations) => {
          updateEditModel(idParam.value, publicIdParam.value)
        })
      }
    }

    return {
      helpers,
      FileMetatype,

      isButtonsDisabled,
      isPopupVisible,
      isLoading,

      localizations: localizations as unknown as Localizations,
      selectedLocalization: selectedLocalization as unknown as LocalizationInfo,
      model: model as unknown as KnowledgeBaseArticleEdit,
      modelSelectedLocalization: modelSelectedLocalization as unknown as KnowledgeBaseArticleLocalizationEdit,

      openPopup,
      onPopupClosed,

      saveChanges,
      close
    }
  }
})
