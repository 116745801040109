

































































































































import {computed, defineComponent, PropType, ref} from "@vue/composition-api";
import helpers from "@/utils/helpers";
import {
  CharitySizeType,
  CharitySizeType_ALL,
  Guid,
  CharityInfo,
  CharityInfoAddress
} from "@/@models";
import appUtils from "@/utils/app-utils";
import BFileFormGroup from "@/views/_controls/BFileFormGroup.vue";

export default defineComponent({
  components: {BFileFormGroup},
  props: {
    model: { type: Object as PropType<CharityInfo>, required: true },
    infoOnlyMode: Boolean,
    disabled: Boolean,
    causeSelectedCheck: Function as PropType<(id: Guid) => boolean>,
    borderColorVariant: String
  },
  emits: ['cause-clicked'],
  setup(props, context) {
    const isOpen = ref(props.infoOnlyMode)

    const charitySizeTypeDisplayName = computed(() => props.model.sizeType !== CharitySizeType.Unknown
      ? helpers.findFirstInArray(CharitySizeType_ALL, x => x.id === props.model.sizeType)?.label
      : null)

    const locationDisplayName = computed(() => {
      if (helpers.isNotEmpty(props.model.locationStateName) && helpers.isNotEmpty(props.model.locationCountryName)) {
        return `${props.model.locationStateName}, ${props.model.locationCountryName}`.trim()
      } else {
        return `${props.model.locationStateName}${props.model.locationCountryName}`.trim()
      }
    })

    const einFormatted = computed(() => appUtils.formatEin(props.model.ein))
    const phoneFormatted = computed(() => appUtils.formatPhone(props.model.phone))

    const getCityStateZipLine = (address: CharityInfoAddress) => {
      return `${address.city} ${address.state} ${address.zip}`.trim()
    }


    const onCauseClicked = (id: Guid) => {
      if (helpers.isFunction(props.causeSelectedCheck) && props.causeSelectedCheck(id)) return

      context.emit('cause-clicked', id)
    }

    return {
      helpers,

      isOpen,
      charitySizeTypeDisplayName,
      locationDisplayName,
      einFormatted,
      phoneFormatted,

      getCityStateZipLine,
      onCauseClicked,
    }
  },
})
