































import {computed, defineComponent, onMounted, PropType, ref, watch} from "@vue/composition-api";
import Helpers from "@/utils/helpers";
import FilePreviewItemView, {SupportedPreviewType} from "@/views/_components/FilePreviewItemView.vue";
import {FileFullInfo} from "@/@models";

export default defineComponent({
  components: {
    FilePreviewItemView
  },
  props: {
    fileInfo: [Object, Array] as PropType<FileFullInfo | FileFullInfo[]>,
    previewType: String as PropType<SupportedPreviewType>,
    imgStyle: [Object, String],
    showEmpty: Boolean,
    smallPreview: Boolean
  },
  setup(props, context) {
    return {
      helpers: Helpers,
    }
  }
})
