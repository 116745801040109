
















import {computed, defineComponent, nextTick, ref, watch} from "@vue/composition-api";
import useRouter from "@/router";
import {UiMutationTypes} from "@/store/modules/ui/types";
import useStore from "@/store";
import helpers from "@/utils/helpers";
import paymentApi from "@/api/payment-api";
import appUtils from "@/utils/app-utils";
import useNotifications from "@/components/useNotifications";
import {Guid, KnowledgeBasePublicArticle} from "@/@models";
import knowledgeBasePortalApi from "@/api/portal/knowledge-base-portal-api";
import knowledgeBaseApi from "@/api/knowledge-base-api";
import sanitizeHtml from "sanitize-html";
import PageLoadingIndicator from "@/views/_components/PageLoadingIndicator.vue";
import useSharedUtils from "@/utils/useSharedUtils";

export default defineComponent({
  components: {PageLoadingIndicator},
  setup(props, context) {
    const store = useStore()
    const router = useRouter()
    const {showSuccessNotification, showErrorNotification} = useNotifications(context)
    const {closePage} = useSharedUtils()

    const publicId = router.currentRoute.params.publicId

    const isLoading = ref(true)

    const model = ref<KnowledgeBasePublicArticle | null>(null)
    const articleContentHtmlSafe = ref<string | null>(null)

    const updateArticle = () => {
      if (!helpers.isNotEmpty(publicId)) return;

      knowledgeBaseApi.getPublicArticle(publicId, result => {
        model.value = result

        articleContentHtmlSafe.value = sanitizeHtml(model.value!.contentHtml ?? '', {
          allowedTags: sanitizeHtml.defaults.allowedTags.concat([ 'img' ]),
          allowedAttributes: Object.assign({}, sanitizeHtml.defaults.allowedAttributes, {
            img: [ '*', 'src' ]
          })
        });

        isLoading.value = false;
      }, errorMessage => {
        showErrorNotification(errorMessage);

        closePage()
      });
    }

    if (helpers.isNotEmpty(publicId)) {
      updateArticle()
    } else {
      closePage()
    }

    return {
      helpers,

      isLoading,

      model: model as unknown as KnowledgeBasePublicArticle,
      articleContentHtmlSafe,
    }
  }
})
