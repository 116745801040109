import Helpers from '@/utils/helpers'
import helpers from '@/utils/helpers'
import {AxiosProgressEvent} from "axios";
import {Cloudinary} from "@cloudinary/url-gen";
import {FileFullInfo, FileType} from "@/@models";
import {FileStringObject, StringObjectBase} from "@/utils/useStringObjectUtils";
import appUtils from "@/utils/app-utils";


const cloudinaryInstance = new Cloudinary({
  cloud: {
    cloudName: CLOUDINARY_CLOUD_NAME
  }
});

export class FileUtilsModel {
  public static imagePlaceholderUrl = "/images/image-placeholder.jpg"
  public static avatarPlaceholderUrl = "/images/avatar-placeholder.png"

  getFileNameFromUrl(url?: string | null) {
    if (!Helpers.isNotEmpty(url)) return null

    const uriParts = url!.split('/')
    return uriParts[uriParts.length - 1]
  }

  private static generateUrl(type: FileType | null, id: string | null | undefined, postfix: string = "", defaultUrl: string = ""): string {
    if (Helpers.isNotEmpty(id)) {
      switch (type) {
        case FileType.Raw:
        case FileType.Audio:
          // https://github.com/cloudinary/js-url-gen/issues/529
          return cloudinaryInstance.image(id!).setAssetType("raw").toURL()
        case FileType.Image:
          return cloudinaryInstance.image(id!).toURL()
        case FileType.Video:
          return cloudinaryInstance.video(id!).toURL()
        default:
          return cloudinaryInstance.image(id!).setAssetType("auto").toURL()
      }
      //return `${MAIN_API_URL}/files/${id}${postfix}`;
    } else {
      return `${defaultUrl ?? ""}${postfix}`;
    }
  }

  url(type: FileType | null, id: string | null | undefined, defaultUrl: string = ""): string {
    return FileUtilsModel.generateUrl(type, id, "", defaultUrl);
  }

  autoUrl(id: string | null | undefined, defaultUrl: string = ""): string {
    return FileUtilsModel.generateUrl(null, id, "", defaultUrl);
  }

  imageUrl(id: string | null | undefined, defaultUrl = FileUtilsModel.imagePlaceholderUrl): string {
    return FileUtilsModel.generateUrl(FileType.Image, id, "", defaultUrl);
  }

  fileInfoUrl(fileInfo: FileFullInfo | null | undefined): string {
    if (!Helpers.isExists(fileInfo)) return this.url(null, null, "")

    return FileUtilsModel.generateUrl(fileInfo!.type, fileInfo!.id,
      `/${fileInfo!.fileName}${fileInfo!.fileExtension}`);
  }

  fileInfoImageUrl(fileinfo: FileFullInfo | null | undefined): string {
    if (!Helpers.isExists(fileinfo)) return this.imageUrl(null)

    return FileUtilsModel.generateUrl(fileinfo!.type, fileinfo!.id,
      `/${fileinfo!.fileName}${fileinfo!.fileExtension}`, FileUtilsModel.imagePlaceholderUrl);
  }

  stringObjectUrl(stringObject: FileStringObject | null | undefined, defaultUrl: string = "") {
    let fileType: FileType | null = null
    let fileId: string | null = null

    if (helpers.isExists(stringObject)) {
      fileType = appUtils.convertFileStringObjectTypeToFileType(stringObject.type)
      fileId = stringObject.fileId
    }

    return FileUtilsModel.generateUrl(fileType, fileId, "", defaultUrl);
  }

  generateFileUploadCallback(cb: ((progressPerc: number | null, loaded: number) => void) | null) {
    return Helpers.isFunction(cb) ? (event: AxiosProgressEvent) => {
      const loaded = event.loaded * 100
      const percentCompleted = helpers.isNumeric(event.total) ? Math.round(loaded / event.total) : null
      cb!(percentCompleted, loaded);
    } : undefined
  }

  openDownloadFileRequest(fileName: string, data: any) {
    // @ts-ignore
    const url = window.URL.createObjectURL(data);
    const link = document.createElement('a');

    link.href = url;
    link.style.display = 'none'
    link.setAttribute('download', fileName);
    document.body.appendChild(link);
    link.click();

    window.setTimeout(() => {
      document.body.removeChild(link)
      window.URL.revokeObjectURL(url)
    }, 0)
  }
  openDownloadUrlRequest(url: string) {
    const link = document.createElement('a');

    link.href = url;
    link.target = '_blank';
    link.style.display = 'none'
    link.download = this.getFileNameFromUrl(url) ?? '';
    document.body.appendChild(link);
    link.click();

    window.setTimeout(() => {
      document.body.removeChild(link)
    }, 0)
  }
}

const fileUtils = new FileUtilsModel();
export default fileUtils;
