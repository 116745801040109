
import {
  ChannelSupportPage,
  PaymentAccountLink,
  PaymentLinkPage,
  PaymentManagementPortalSession
} from "@/@models";
import ClientApiBase from "@/api/client-api-base";
import appUtils from "@/utils/app-utils";
import {ApiEmptyErrorCallback, ApiEmptySuccessCallback, ApiErrorCallback, ApiSuccessCallback} from "@/api/api-base";

export default new class extends ClientApiBase {
  paymentLinkPage(paymentLinkPublicId: string, successUrl: string, cancelUrl: string, useTrial: boolean,
                  successCb: ApiSuccessCallback<PaymentLinkPage>,
                  errorCb: ApiErrorCallback<PaymentLinkPage>) {
    return this._defaultPostResponse('/payment/payment-link-page', {
      paymentLinkPublicId, successUrl, cancelUrl, useTrial, telegramInitData: appUtils.getTelegramInitData()
    }, null, successCb, errorCb);
  }

  paymentAccountLink(onboardingRefreshUrl: string, onboardingReturnUrl: string,
                     successCb: ApiSuccessCallback<PaymentAccountLink>,
                     errorCb: ApiErrorCallback<PaymentAccountLink>) {
    return this._defaultPostResponse('/payment/payment-account-link', {
      onboardingRefreshUrl, onboardingReturnUrl, telegramInitData: appUtils.getTelegramInitData()
    }, null, successCb, errorCb);
  }

  paymentManagementPortalSession(channelPublicId: string, returnUrl: string | null,
                                 successCb: ApiSuccessCallback<PaymentManagementPortalSession>,
                                 errorCb: ApiErrorCallback<PaymentManagementPortalSession>) {
    return this._defaultPostResponse('/payment/management-portal-session', {
      channelPublicId, returnUrl, telegramInitData: appUtils.getTelegramInitData()
    }, null, successCb, errorCb);
  }

  paymentLinkCheckoutResult(paymentLinkPublicId: string, success: boolean, checkoutSessionId: string | null,
                            successCb: ApiEmptySuccessCallback,
                            errorCb: ApiEmptyErrorCallback) {
    return this._defaultEmptyGetResponse('/payment/payment-link-checkout-result', {
      params: { paymentLinkPublicId, success, checkoutSessionId }
    }, successCb, errorCb);
  }

  channelSupportPage(channelPublicId: string | null,
                     successCb: ApiSuccessCallback<ChannelSupportPage>,
                     errorCb: ApiErrorCallback<ChannelSupportPage>) {
    return this._defaultPostResponse('/payment/channel-support-page', {
      telegramInitData: appUtils.getTelegramInitData()
    }, {
      params: { channelPublicId }
    }, successCb, errorCb);
  }

  changeRecurringPayment(toPaymentLinkPublicId: string,
                         successCb: ApiEmptySuccessCallback,
                         errorCb: ApiEmptyErrorCallback) {
    return this._defaultEmptyPostResponse('/payment/change-recurring-payment', {
      toPaymentLinkPublicId, telegramInitData: appUtils.getTelegramInitData()
    }, null, successCb, errorCb);
  }
}
