








import {computed, defineComponent, onBeforeUnmount, onMounted, PropType, ref, watch} from "@vue/composition-api";
import helpers from "@/utils/helpers";
import {FileFullInfo, FileType} from "@/@models";
import videojs from "video.js";
import Player from "video.js/dist/types/player";

let VideoPlayer_ComponentId = 1;

interface VideoJsPlayerOptionsSource {
  src: string
  type: string
}

interface VideoJsPlayerOptions {
  autoplay: boolean | null
  controls: boolean | null
  fluid: boolean | null
  sources: VideoJsPlayerOptionsSource[]
}

export default defineComponent({
  inheritAttrs: false,
  props: {
    fileInfo: { type: Object as PropType<FileFullInfo>, required: true }
  },
  setup(props, context) {
    // validation
    if (props.fileInfo.type !== FileType.Video) {
      console.error('fileInfo.type is not video');
    }

    // before create
    const componentId = VideoPlayer_ComponentId;
    VideoPlayer_ComponentId += 1;

    const componentRef = computed(() => 'VideoPlayer_' + componentId);
    const componentPlayerRef = computed(() => componentRef + '_player');

    const player = ref<Player | null>(null)
    const playerOptions = ref<VideoJsPlayerOptions>({
      autoplay: true,
      controls: true,
      fluid: true,
      sources: [
        {
          src: props.fileInfo.absoluteUrl,
          type: props.fileInfo.fileMimeType
        }
      ]
    })

    watch(() => props.fileInfo, (val, oldValue) => {
      playerOptions.value.sources[0].src = props.fileInfo.absoluteUrl
      playerOptions.value.sources[0].type = props.fileInfo.fileMimeType

      player.value?.src(playerOptions.value.sources[0]);
    });

    onMounted(() => {
      player.value = videojs(context.refs[componentPlayerRef.value] as Element, playerOptions.value, () => {
        // loaded
      });
    })
    onBeforeUnmount(() => {
      if (helpers.isExists(player.value)) {
        player.value!.dispose();
      }
    })

    return {
      helpers,
      componentRef,
      componentPlayerRef,
    }
  },
})
