import { MutationTree } from 'vuex';
import {IframeSettings, State} from './state';
import { UiMutationTypes } from './types';

export interface UpdateWindowSizePayload {
  width: number | null
  height: number | null
  scrollWidth: number | null
  scrollHeight: number | null
}

export type Mutations<S = State> = {
  [UiMutationTypes.UPDATE_GLOBAL_LOADING](state: S, payload: boolean): void;
  [UiMutationTypes.UPDATE_DARK_COLOR_SCHEME](state: S, payload: boolean): void;
  [UiMutationTypes.UPDATE_WINDOW_SIZE](state: S, payload: UpdateWindowSizePayload): void;
  [UiMutationTypes.UPDATE_WITH_FOOTER](state: S, payload: boolean): void;
  [UiMutationTypes.UPDATE_IFRAME_MODE](state: S, payload: boolean): void;
  [UiMutationTypes.UPDATE_IFRAME_SETTINGS](state: S, payload: IframeSettings): void;
  [UiMutationTypes.UPDATE_PAGE_FULLSCREEN_MODE](state: S, payload: boolean): void;
}

export const mutations: MutationTree<State> & Mutations = {
  [UiMutationTypes.UPDATE_GLOBAL_LOADING](state, isLoading) {
    state.isGlobalLoading = isLoading;
  },
  [UiMutationTypes.UPDATE_DARK_COLOR_SCHEME](state, isDarkColorScheme) {
    state.isDarkColorScheme = isDarkColorScheme;
  },
  [UiMutationTypes.UPDATE_WINDOW_SIZE](state, size) {
    state.windowWidth = size.width;
    state.windowHeight = size.height;
    state.windowScrollWidth = size.scrollWidth;
    state.windowScrollHeight = size.scrollHeight;
  },
  [UiMutationTypes.UPDATE_IFRAME_MODE](state, isIframeMode) {
    state.isIframeMode = isIframeMode;
  },
  [UiMutationTypes.UPDATE_WITH_FOOTER](state, withFooter) {
    state.withFooter = withFooter;
  },
  [UiMutationTypes.UPDATE_IFRAME_SETTINGS](state, settings) {
    state.iframeSettings = settings
  },
  [UiMutationTypes.UPDATE_PAGE_FULLSCREEN_MODE](state, isEnabled) {
    state.isPageFullscreenMode = isEnabled
  }
};
