
export default function useConfig() {
  return {
    selectNoOptionsText: "No options available",
    searchTermMinLengthText: "The search string must have at least 3 characters",
    searchTermMinLengthTextTemplate: "Search string must be at least {LENGTH}",
    defaultSearchTermMinLength: 2,
    selectSearchTermMinLength: 3,
    tableMaxItemsPerPage: 50,
  }
}
