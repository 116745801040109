







































































































































































import {
  computed,
  defineComponent,
  nextTick,
  onBeforeUnmount,
  onMounted,
  onUnmounted,
  ref,
  watch
} from "@vue/composition-api";
import useRouter from "@/router";
import useStore from "@/store";
import helpers from "@/utils/helpers";
import paymentApi from "@/api/payment-api";
import appUtils from "@/utils/app-utils";
import sanitizeHtml from 'sanitize-html';
import {ChannelSupportPage, PremiumInfo} from "@/@models";
import PageLoadingIndicator from "@/views/_components/PageLoadingIndicator.vue";
import VideoPlayer from "@/views/_components/VideoPlayer.vue";
import useNotifications from "@/components/useNotifications";
import VueShowMoreText from "@/views/_components/VueShowMoreText.vue";
import CharityInfoView from "@/views/channel/_partials/CharityInfoView.vue";
import useSharedUtils from "@/utils/useSharedUtils";
import ChannelAutomatedAssistantAiAgentView from "@/views/ai-agent/ChannelAutomatedAssistantAiAgentView.vue";
import ChannelLiveAssistantAiAgentView from "@/views/ai-agent/ChannelLiveAssistantAiAgentView.vue";
import miscApi from "@/api/misc-api";
import {emitter, EmitterEvent} from "@/components/emitter";

export default defineComponent({
  components: {
    PageLoadingIndicator
  },
  setup(props, context) {
    const store = useStore()
    const router = useRouter()
    const {showErrorNotification} = useNotifications(context)
    const {closePage} = useSharedUtils()

    const model = ref<PremiumInfo | null>(null)

    const isAnyTrialAvailable = computed(() => helpers.isExists(model.value) &&
      helpers.anyInArray(model.value.premiumSubscriptions, x => helpers.isNumeric(x.trialPeriodDays)))

    let isPremiumItemSizesUpdating = false
    const updatePremiumItemSizes = () => {
      if (isPremiumItemSizesUpdating) return

      isPremiumItemSizesUpdating = true
      const footerElements = document.getElementsByClassName('premium-footer') as HTMLCollectionOf<HTMLElement>

      for (const footerElement of footerElements) {
        footerElement.style.visibility = 'hidden'
        footerElement.style.height = 'auto'
      }

      setTimeout(() => {
        let maxHeight = 0
        for (const footerElement of footerElements) {
          const currentHeight = helpers.domElementHeight(footerElement, "outer")

          if (currentHeight > maxHeight) {
            maxHeight = currentHeight
          }
        }

        for (const footerElement of footerElements) {
          footerElement.style.visibility = 'visible'
          footerElement.style.height = maxHeight + 'px'
        }

        isPremiumItemSizesUpdating = false
      })
    }

    onBeforeUnmount(() => {
      // global event
      emitter.off(EmitterEvent.AfterWindowResizeCompleted, updatePremiumItemSizes);
    })

    // global event
    emitter.on(EmitterEvent.AfterWindowResizeCompleted, updatePremiumItemSizes);

    // init
    //
    updatePremiumItemSizes()

    miscApi.getPremiumInfo(result => {
      if (helpers.isExists(result)) {
        model.value = result

        updatePremiumItemSizes()
      } else {
        closePage()
      }
    }, errorMessage => {
      showErrorNotification(errorMessage)

      closePage()
    })

    return {
      helpers,
      appUtils,

      model: model as unknown as PremiumInfo,

      isAnyTrialAvailable,
    }
  }
})
