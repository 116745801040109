import {Guid} from "@/@models/shared";

export enum CausesSpecialList {
  Charities = 0
}

export interface Cause {
  id: Guid
  name: string
  parentId: Guid | null
  children: Cause[]
}

export interface Causes {
  causes: Cause[]
}
