import { render, staticRenderFns } from "./AppLocalizationFields.vue?vue&type=template&id=0973a67d&scoped=true&"
import script from "./AppLocalizationFields.vue?vue&type=script&lang=ts&"
export * from "./AppLocalizationFields.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/.pnpm/vue-loader@15.10.2_css-loader@6.8.1_vue-template-compiler@2.6.14_webpack@5.88.2/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0973a67d",
  null
  
)

export default component.exports